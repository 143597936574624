import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../../environments/environment';

const url = environment.apiUrlEdigOrders + "/ediorders/";
@Injectable({
    providedIn: 'root'
})
export class ProcesarArchivosService {

    constructor(
        private http: HttpClient
    ){}

    getFiles() {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
            })
        };

        return this.http.get(
          url + `filemanager`,
            httpOptions
        );
    }

    postFiles(row) {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
            })
        };

        const values = {
            fileName: row.fileName,
            ext: row.ext
        }

        return this.http.post(
          url +  `FILEREAD`,
            JSON.stringify(values),
            httpOptions
        );
    }
}
