import { Component, OnInit, ViewChild } from '@angular/core';  
import { CargarArchivosService } from 'src/app/services/bi/operacion/cargar-archivos.service';
import { forkJoin } from 'rxjs';

@Component({
    selector: 'app-cargar-archivos',
    templateUrl: './cargar-archivos.component.html',
    styleUrls: [
        './cargar-archivos.component.scss'
    ],
    providers:[
        CargarArchivosService
    ]
}) 
export class CargarArchivosComponent implements OnInit {  
    
    @ViewChild('file') file;
    public files: Set<File> = new Set();

    TableList: any = []; 
    
    progress;
    uploading: boolean = false;
    uploadSuccessful: boolean = false;
    canBeClosed: boolean = true;
    primaryButtonText = 'Subir Archivos';
    showCancelButton = true;

    constructor(
        private uploadService: CargarArchivosService
    ) {}

    ngOnInit() {} 

    onFilesAdded() {
        const files: { [key: string]: File } = this.file.nativeElement.files;
        for (let key in files) {
            if (!isNaN(parseInt(key))) {
                console.log(files[key]);
                this.files.add(files[key]);
            }
        }
    }

    addFiles() {
        this.file.nativeElement.click();
    }

    closeDialog() {
        // if everything was uploaded already, just close the dialog
        if (this.uploadSuccessful) {
            console.log("todo bien");
            return;
        }

        // set the component state to "uploading"
        this.uploading = true;

        // start the upload and save the progress map
        this.progress = this.uploadService.upload(this.files);
        
        console.log(this.progress);

        for (const key in this.progress) {
            this.progress[key].progress.subscribe(val => console.log(val));
        }

        // convert the progress map into an array
        let allProgressObservables = [];
        for (let key in this.progress) {
            allProgressObservables.push(this.progress[key].progress);
        }

        // Adjust the state variables

        // The OK-button should have the text "Finish" now
        this.primaryButtonText = 'Finish';

        // The dialog should not be closed while uploading
        this.canBeClosed = false; 

        // Hide the cancel-button
        this.showCancelButton = false;

        // When all progress-observables are completed...
        forkJoin(allProgressObservables).subscribe(end => {
            // ... the dialog can be closed again...
            this.canBeClosed = true; 

            // ... the upload was successful...
            this.uploadSuccessful = true;

            // ... and the component is no longer uploading
            this.uploading = false;
        });
    }
}
