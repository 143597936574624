import { AuthService } from './../../../../services/self/seguridad/auth.service';
import { Component, OnInit, Input } from '@angular/core';
import { RfcsServiceService } from 'src/app/services/bi/comprobantes/rfcs-service.service';
import { ConsultaService } from 'src/app/services/bi/comprobantes/consulta.service';
import { CommontoastrService } from 'src/app/shared/toastr/commontoastr.service';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { ScreenActionsService } from 'src/app/services/self/seguridad/actions.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-principal',
  templateUrl: './principal.component.html',
  styleUrls: ['./principal.component.scss']
})
export class PrincipalComponent implements OnInit {
  public Page: any = {};
  public Screen: any = {};
  public ReportRFC: any = "";
  public ReportList : any = {};
  uuid : any = ""

  public solcs : any = "";
  public _rfc : any = "SDd";
  public _pass : any;
  public rfcs : any;
  public data : any;
  public edit : any;
  public actual : string = "tabla";

  public cantidad : any = ""
  public tiempo : any = ""
  public unidad : any = "Minutos"
  public isRunning : boolean = false;

  constructor(private service:RfcsServiceService, private consultaservice:ConsultaService,private router: Router,
    private toastr: CommontoastrService, private spinnerService: Ng4LoadingSpinnerService
    ,private ScreenActionsService: ScreenActionsService, private AuthService: AuthService,) {

      this.Page.Controller = this.router.routerState.snapshot.url.toString().substr(this.router.routerState.snapshot.url.lastIndexOf('/') + 1, this.router.routerState.snapshot.url.toString().length - 1);
      this.AuthService.IsAlive();
  }

  ngOnInit() {

      //Validacion de Acceso a la Pagina 
      this.ScreenActionsService.GetScreenActions(this.Page.Controller).then(data => {
        console.log(data)
        this.Screen.View = data.Get === undefined ? false : data.Get;
        this.Screen.AddRFC = data.AddRFC === undefined ? false : data.AddRFC;
        this.Screen.EditRFC = data.EditRFC === undefined ? false : data.EditRFC;
        this.Screen.ShowDownloads = data.ShowDownloads === undefined ? false : data.ShowDownloads;
        this.Screen.ShowReports = data.ShowReports === undefined ? false : data.ShowReports;
        this.ReportList.RNomina = data.RNomina === undefined ? false : data.RNomina;
        this.ReportList.RIngresosEgresos = data.RIngresosEgresos === undefined ? false : data.RIngresosEgresos;
        this.ReportList.RIngresosEgresosC = data.RIngresosEgresosC === undefined ? false : data.RIngresosEgresosC;
        this.ReportList.RReciboElec = data.RReciboElec === undefined ? false : data.RReciboElec;
  
        if (!this.Screen.View) {
          this.toastr.Error("Acceso denegado, no tiene permisos para ver el contenido.");
          setTimeout(() => {
            this.router.navigate(['default'])
          }, 3000);
        }
        else {
         
          this.fillTabla();
        }
  
      }).catch(error => {
        this.handleError(error);
      })

      

  }

  

  fillTabla(){
    this.spinnerService.show();
    this.service.get().subscribe(data => {
      this.spinnerService.hide();
      this.rfcs = data;
      
    },
      error => {
        this.spinnerService.hide();
        this.toastr.Error("Error con el servidor");
      });
  }

  editar(id){
    
    this.actual = "editar"
    
    this.data = id;
    this.data.verificacion_Inicio = this.data.verificacion_Inicio.split("T")[0];
    this.data.password = "";
    this.edit = id;
    
  }

  consultar(id){
    
    this.spinnerService.show();
    this.consultaservice.get(id.rfc).subscribe((data:any) => {
      this.spinnerService.hide();
      if(data.listax.length == 0){
        this.toastr.Warning("No se han registrado solicitudes con este RFC");
      }else{
        this.solcs = data;
        this.actual = "consultar"
      }
      
    },
      error => {
        this.spinnerService.hide();
        this.toastr.Error("Error con el servidor");
      });
  }
  
  onVoted(agreed: any) {
    
    if(agreed.show){
      this.toastr.Success(agreed.mensaje);
    }
    this.fillTabla()
    this.actual = "tabla";
  }

  agregar(){
    this.actual = 'nueva';
  }

  page(e){
    
  }
  clicks(){
  }

  reportes(row){
    this.ReportRFC = row;
    this.actual = 'reportes';
  }

  public async BuscarComprobante():Promise<void> {

  
      this.spinnerService.show();
      const data = await this.consultaservice.getXML(this.uuid);
      console.log(data)
      data.forEach(e => {
        const blob = new Blob([e], { type: 'application/xml' });  //excel  application/vnd.openxmlformats-officedocument.spreadsheetml.sheet
        const url = window.URL.createObjectURL(blob);
        this.spinnerService.hide();
        window.open(url);
      });
      
  
  
    
  }

  BuscarComprobante2(){
    this.actual = 'comprobante'
  }

  volver(){
    this.actual = "tabla"
  }

    //---------Funciones Manejo de Errores--------------------------
    private handleError(error: any) {

      this.spinnerService.hide();
      if (error.status == 401) {
        localStorage.removeItem('auth');
        this.router.navigate(['login'])
      }
      this.toastr.Error(error.error.Descripcion);
  
    };

    process(){

      this.spinnerService.show();
      this.consultaservice.CheckProcess().subscribe((data:any) => {
        this.spinnerService.hide();
        this.isRunning = data.isRunning;
        console.log(this.isRunning);
        this.actual = "process";
      },
        error => {
          console.log(error);
          this.spinnerService.hide();
          this.toastr.Error("Error con el servidor");
        });


    }

    numberOnly(event): boolean {
      const charCode = (event.which) ? event.which : event.keyCode;

      if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        return false;
      }
      return true;
  
    }

    run(){
      if(this.cantidad < 1 || this.tiempo < 1){
        this.toastr.Error("Ingrese una cantidad mayor a cero.");
        return;
      }
      var obj = {
        time:parseInt(this.cantidad),
        quantity:parseInt(this.cantidad),
        type:this.unidad,
        stop:false
      }

      this.spinnerService.show();
      this.consultaservice.Process(obj).subscribe((data:any) => {
        
        this.spinnerService.hide();
        this.toastr.Success("El proceso ha iniciado correctamente.");
        this.actual = "tabla"
        
      },
        error => {
          console.log(error);
          this.spinnerService.hide();
          this.toastr.Error("Error con el servidor");
        });
      }

    stop(){
      
      var obj = {
        time:1,
        quantity:1,
        type:this.unidad,
        stop:true
      }

      this.spinnerService.show();
      this.consultaservice.Process(obj).subscribe((data:any) => {
        this.spinnerService.hide();
        this.toastr.Success("El proceso se ha detenido correctamente.");
        this.actual = "tabla"
        
      },
        error => {
          console.log(error);
          this.spinnerService.hide();
          this.toastr.Error("Error con el servidor");
        });
      
    }

}
