import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import 'rxjs/add/operator/map';

@Injectable({
  providedIn: 'root'
})
export class PedidoServiceService {

  private httpOptions2 = {
    headers: new HttpHeaders({
      "Content-Type": "application/json"
    })
  };

  constructor(private http: HttpClient) { }

  public post(body) {
    return this.http.post(`${environment.apiUrl}/transactions/Pedidos/Nuevo`, body, this.httpOptions2);
  }

  public ParentParty(body) {
    return this.http.post(`${environment.apiUrl}/transactions/Pedidos/ParentParty`, body, this.httpOptions2);
  }

  public CustomerPartyMaster(body) {
    return this.http.post(`${environment.apiUrl}/transactions/Pedidos/CPM`, body, this.httpOptions2);
  }

  public Registrar(body) {
    return this.http.post(`http://localhost:5005/api/Pedidos/Registrar`, body, this.httpOptions2);
  }

  public Start(body) {
    var obj = {
      Email:body
    }
    return this.http.post(`${environment.apiUrl}/transactions/Pedidos/Start`, obj, this.httpOptions2);
  }

  public ObtenerDescuentos (body) {

    return this.http.post(`${environment.apiUrl}/transactions/Pedidos/ObtenerDescuentos`, body, this.httpOptions2);
  }
  public ObtenerPrecios (body) {

    return this.http.post(`http://localhost:5005/api/Pedidos/ObtenerPrecios`, body, this.httpOptions2);
  }

  public CrearDetalle(body) {

    return this.http.post(`${environment.apiUrl}/transactions/Pedidos/CrearDetalle`, body, this.httpOptions2);
  }
}
