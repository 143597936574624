import { Component, OnInit } from "@angular/core";
import { LayoutService } from "src/app/services/bi/layout/layout.service";
import { AuthService } from "src/app/services/self/seguridad/auth.service";
import { Ng4LoadingSpinnerService } from "ng4-loading-spinner";
import { NgbModal, NgbActiveModal } from "@ng-bootstrap/ng-bootstrap"

// import { DatePipe } from "@angular/common"
import { HttpClient } from '@angular/common/http';
import { DatePipe } from "@angular/common";
import { ModalService } from "../modal/modal.service"
import { CommontoastrService } from "src/app/shared/toastr/commontoastr.service";
import { interval, Subscription } from 'rxjs';
import { deepValueGetter } from "@swimlane/ngx-datatable/release/utils";


@Component({
  selector: "app-timbrado",
  templateUrl: "./timbrado.component.html",
  styleUrls: [
    "./timbrado.component.scss",
    "../../../../../assets/icon/icofont/css/icofont.scss",
    "../modal/modal.component.scss"
  ],
  providers: [DatePipe, NgbActiveModal]
})
export class TimbradoComponent implements OnInit {


  subscription: Subscription;
  intervalId: number;

  selectedRow: any = "";
  listaPermisosCampos: any = []
  habilitado: any = false
  listaIconos: any = []

  listaSegmentosHab: any = []

  listaECPM: any = [];
  selectedECPM: any = 0;

  SelectedTD: any = 0

  listaCPM: any = [];
  selectedCPM: any = 0;
  Invoice: any = "";

  PoNumber: any = "";
  descripcion: any = "Primero";
  lCadena: any = "";
  CTAB = 0;
  cTab = 1;
  cPestana = 1;

  listaGrid = [];
  listaBusquedaGrid = []

  openPopUp = 1;
  nombreSegmento = "Nada";

  cadena: any = {};

  selectedClientType: any = 1;
  selectedEstatus: any = 0;
  nombreArchivo: any = "";
  rutaArchivo: any = "";

  timefired: any = null;
  valorECPM: any = false;

  listaRG: any = [];
  selectedRG: any = 0;

  listaPP: any = [];
  xlistaPP: any = [];
  selectedPP: any = 0;

  selectedSP: any = 0;
  listaSP: any = [];
  listaSelectedSP: any = [];

  listaPT: any = []
  selectedPT: any = 0

  listaPS: any = []
  selectedPS: any = 0

  listaCC: any = []
  xlistaCC: any = []
  selectedCC: any = 0

  selectedSC: any = 0
  listaSC: any = []

  PDate1: any = ""
  PDate2: any = ""

  filtrado: any = 0;

  listaResumen: any = [];

  //Labels
  clienteEDIG: any = 0;
  clienteComercial: any = 0
  grupoCliente: any = 0
  cadenaCliente: any = 0
  sucursalCliente: any = 0
  canalCliente: any = 0
  tipoPagoCliente: any = 0
  tipoDocCliente: any = 0
  periodoCliente: any = 0

  ipAddress: any = "Sin IP"
  hostName: any = "No Identificado"
  itemSeleccionado: boolean = true;
  invoiceHeaderObjectId: any = ""
  segmentResponse: any = {}
  recargar: any = 0

  //Variables tab Comprobante Comercial
  bodyComprobanteComercial: any = {
    invoiceHeaderObjectId: 0,
    numeroOrdenDeCompra: 0,
    fechaOrdenDeCompra: "",
    fechaEntregaActual: "",
    fechaCancelacionOrdenDeCompra: "",
    importeConLetra: "",
    numeroDePedidoHerdez: 0,
    fechaPedidoHerdez: "",
    numAnticipadoDeEmbarque: 0,
    fechaAvisoAnticipadoDeEmbarque: "",
    efectoDelComprobante: "",
    referenciaBancaria1: "",
    referenciaBancaria2: "",
    referenciaBancaria3: "",
    referenciaBancaria4: "",
    referenciaBancaria5: "",
    referenciaBancaria6: "",
    vendedor: "",
    pesoNetoTotal: 0,
    pesoLogisticoTotal: 0,
    pdfLeyenda1: "",
    pdfLeyenda2: "",
    pdfLeyenda3: "",
    cabeceroPDF: "",
    reservado5: "",
    lstMdfDate: "",
    origenProcess: "",
    ipHostClient: "",
    hostNameClient: "",
    userNameClient: "",
  }
  bodyComprobanteFiscal: any = {
    invoiceHeaderObjectId: 0,
    soObjectId: 0,
    version: "",
    serie: "",
    folio: 0,
    fecha: "",
    formaPa: 0,
    condicionesDePa: 0,
    subTotal: 0,
    descuento: 0,
    moneda: "",
    tipoCambio: 0,
    total: 0,
    tipoDeComprobante: "",
    metodoPa: "",
    lugarExpedicion: "",
    confirmacion: "",
    reservado1: "",
    reservado2: "",
    reservado3: "",
    reservado4: "",
    reservado5: "",
    lstMdfDate: "",
    origenProcess: "",
    ipHostClient: "",
    hostNameClient: "",
    userNameClient: "",
    soNumber: ""
  }
  bodyCfdiRelacionados: any = {
    invoiceHeaderObjectId: 0,
    tipoRelacion: 0,
    uuid: "",
    reservado1: "",
    reservado2: "",
    reservado3: "",
    reservado4: "",
    reservado5: "",
    lstMdfDate: "",
    origenProcess: "",
    ipHostClient: "",
    hostNameClient: "",
    userNameClient: "",
  }
  bodyEmisorCCE: any = {
    invoiceHeaderObjectId: 0,
    curp: "",
    reservado1: "",
    reservado2: "",
    reservado3: "",
    reservado4: "",
    reservado5: "",
    lstMdfDate: "",
    origenProcess: "",
    ipHostClient: "",
    hostNameClient: "",
    userNameClient: "",
  }
  bodyEmisorComercial: any = {
    invoiceHeaderObjectId: 0,
    numCompaniaEmisor: "",
    glnEmisor: 0,
    numeroDeProveedor: "",
    numeroDeProveedorSAP: "",
    tipoDeProveedor: 0,
    calleEmisor: "",
    noExteriorEmisor: "",
    noInteriorEmisor: "",
    coloniaEmisor: "",
    localidadEmisor: "",
    referenciaEmisor: "",
    municipioEmisor: "",
    estadoEmisor: "",
    paisEmisor: "",
    codipostalEmisor: 0,
    reservado1: "",
    reservado2: "",
    reservado3: "",
    reservado4: "",
    reservado5: "",
    lstMdfDate: "",
    origenProcess: "",
    ipHostClient: "",
    hostNameClient: "",
    userNameClient: "",
  }
  bodyEmisorExpedicion: any = {
    invoiceHeaderObjectId: 0,
    glnEmisorExpedicion: 0,
    bodegaExpedicion: "",
    tipoConcentrado: "",
    concentrado: 0,
    ruta: "",
    numCedisExpedicion: "",
    calleExpedicion: "",
    noExteriorExpedicion: "",
    noInteriorExpedicion: "",
    coloniaExpedicion: "",
    localidadExpedicion: "",
    referenciaExpedicion: "",
    municipioExpedicion: "",
    estadoExpedicion: "",
    paisExpedicion: "",
    codipostalEmisor: 0,
    reservado1: "",
    reservado2: "",
    reservado3: "",
    reservado4: "",
    reservado5: "",
    lstMdfDate: "",
    origenProcess: "",
    ipHostClient: "",
    hostNameClient: "",
    userNameClient: "",
  }
  bodyEmisorFiscal: any = {
    invoiceHeaderObjectId: 0,
    rfcEmisor: "",
    nombreEmisor: "",
    regimenFiscalEmisor: "",
    reservado1: "",
    reservado2: "",
    reservado3: "",
    reservado4: "",
    reservado5: "",
    lstMdfDate: "",
    origenProcess: "",
    ipHostClient: "",
    hostNameClient: "",
    userNameClient: "",
  }
  bodyConceptoComercial: any = {
    invoiceHeaderObjectId: 0,
    idPartida: 0,
    ean13: 0,
    dun14: 0,
    skuUpcCliente: 0,
    numeroRecursoInternoHerdez: 0,
    numeroRecursoInternoCliente: 0,
    factorEmpaqueHerdez: 0,
    factorEmpaqueVendido: 0,
    descripcionAlternaHerdez: "",
    descripcionCliente: "",
    cantidadEmbarcadaOriginal: 0,
    unidadDeMedidaEmbarcada: "",
    cantidadTotalCajas: 0,
    cantidadTotalPiezas: 0,
    precioUnitarioCajas: 0,
    precioUnitarioPiezas: 0,
    importeCajas: 0,
    importePiezas: 0,
    descuentoUnitarioCajas: 0,
    descuentoUnitarioPiezas: 0,
    descuentoCajas: 0,
    descuentoPiezas: 0,
    precioNetoCajas: 0,
    precioNetoPiezas: 0,
    tasaIVA_1: 0,
    montoIVA_1: 0,
    tasaIEPS_1: 0,
    montoIEPS_1: 0,
    pesoNeto: 0,
    pesoLogistico: 0,
    porcentajeDescuentoIncluido: 0,
    motivoDescuentoIncluido: 0,
    montoDescuentoIncluido: 0,
    reservado4: 0,
    reservado5: 0,
    lstMdfDate: "",
    origenProcess: "",
    ipHostClient: "",
    hostNameClient: "",
    userNameClient: "",
  }
  bodyConceptoFiscal: any = {
    invoiceHeaderObjectId: 0,
    claveProdServ: 0,
    noIdentificacionFiscal: "",
    cantidad: 0,
    claveUnidad: "",
    unidad: "",
    descripcion: "",
    valorUnitario: 0,
    importe: 0,
    descuento: 0,
    reservado1: "",
    reservado2: "",
    reservado3: "",
    reservado4: "",
    reservado5: "",
    lstMdfDate: "",
    origenProcess: "",
    ipHostClient: "",
    hostNameClient: "",
    userNameClient: "",
  }
  bodyTrasladoConceptoComercial: any = {
    invoiceHeaderObjectId: 0,
    porcentajeImpuesto: 0,
    nombreImpuesto: "",
    reservado1: "",
    reservado2: "",
    reservado3: "",
    reservado4: "",
    reservado5: "",
    fechaUltimaModificacion: "",
    origenProcess: "",
    ipHostClient: "",
    hostNameClient: "",
    userNameClient: "",
  }
  bodyTrasladoConceptoFiscal: any = {
    invoiceHeaderObjectId: 0,
    baseConceptoFiscal: 0,
    impuestoConceptoFiscal: 0,
    tipoFactorConceptoFiscal: 0,
    tasaOCuotaConceptoFiscal: 0,
    importeConceptoFiscal: 0,
    reservado1: "",
    reservado2: "",
    reservado3: "",
    reservado4: "",
    reservado5: "",
    fechaUltimaModificacion: "",
    origenProcess: "",
    ipHostClient: "",
    hostNameClient: "",
    userNameClient: "",
  }
  bodyReceptorComercial: any = {
    invoiceHeaderObjectId: 0,
    glnReceptor: 0,
    numeroDeCuentaClienteReceptor: "",
    cadena: "",
    calleReceptor: "",
    noExteriorReceptor: "",
    noInteriorReceptor: "",
    coloniaReceptor: "",
    localidadReceptor: "",
    referenciaReceptor: "",
    municipioReceptor: "",
    estadoReceptor: "",
    paisReceptor: "",
    codipostalReceptor: 0,
    reservado1: "",
    reservado2: "",
    reservado3: "",
    reservado4: "",
    reservado5: "",
    lstMdfDate: "",
    origenProcess: "",
    ipHostClient: "",
    hostNameClient: "",
    userNameClient: "",
  }
  bodyReceptorEntrega: any = {
    invoiceHeaderObjectId: 0,
    glnEntrega: 0,
    numeroDeCuentaClienteEntrega: "",
    nombreReceptorEntrega: "",
    numeroDeOrdenCompraCliente: "",
    fechaDeOrdenCompraCliente: "",
    folioDeRecepcionDeMercancia: "",
    fechaDeRecepcionDeMercancia: "",
    numeroTiendaEntrega: "",
    claveSucursalEntrega: "",
    nombreDeTiendaEntrega: "",
    nombreSucursalEntrega: "",
    numPedidoCliente: 0,
    tipoPedido: "",
    remision: "",
    tipoLocalidadEntrega: "",
    calleEntrega: "",
    noExteriorEntrega: "",
    noInteriorEntrega: "",
    coloniaEntrega: "",
    localidadEntrega: "",
    referenciaEntrega: "",
    municipioEntrega: "",
    estadoEntrega: "",
    paisEntrega: "",
    codipostalEntrega: 0,
    reservado1: "",
    reservado2: "",
    reservado3: "",
    reservado4: "",
    reservado5: "",
    lstMdfDate: "",
    origenProcess: "",
    ipHostClient: "",
    hostNameClient: "",
    userNameClient: "",
  }
  bodyReceptorFiscal: any = {
    invoiceHeaderObjectId: 0,
    rfcReceptor: "",
    nombreReceptor: "",
    residenciaFiscalReceptor: "",
    numRegIdTribReceptor: "",
    usoCFDI: "",
    reservado1: "",
    reservado2: "",
    reservado3: "",
    reservado4: "",
    reservado5: "",
    lstMdfDate: "",
    origenProcess: "",
    ipHostClient: "",
    hostNameClient: "",
    userNameClient: "",
  }
  bodyInfAduaneraTercero: any = {
    invoiceHeaderObjectId: 0,
    numeroTercero: 0,
    fechaTercero: "",
    aduanaTercero: "",
    reservado1: "",
    reservado2: "",
    reservado3: "",
    reservado4: "",
    reservado5: "",
    lstMdfDate: "",
    origenProcess: "",
    ipHostClient: "",
    hostNameClient: "",
    userNameClient: "",
  }
  bodyInfFiscalTercero: any = {
    invoiceHeaderObjectId: 0,
    calleTercero: "",
    noExteriorTercero: "",
    noInteriorTercero: "",
    coloniaTercero: "",
    localidadTercero: "",
    referenciaTercero: "",
    municipioTercero: "",
    estadoTercero: "",
    paisTercero: "",
    codipostalTercero: 0,
    reservado1: "",
    reservado2: "",
    reservado3: "",
    reservado4: "",
    reservado5: "",
    lstMdfDate: "",
    origenProcess: "",
    ipHostClient: "",
    hostNameClient: "",
    userNameClient: "",
  }
  bodyParteTercero: any = {
    invoiceHeaderObjectId: 0,
    cantidadTerceroFiscal: 0,
    unidadTerceroFiscal: "",
    noIdentificacionTerceroFiscal: 0,
    fescripcionTerceroFiscal: "",
    balorUnitarioTerceroFiscal: 0,
    importeTerceroFiscal: "",
    reservado1: "",
    reservado2: "",
    reservado3: "",
    reservado4: "",
    reservado5: "",
    fechaUltimaModificacion: "",
    origenProcess: "",
    ipHostClient: "",
    hostNameClient: "",
    userNameClient: "",
  }
  bodyPorParteDeTercerosFiscal: any = {
    invoiceHeaderObjectId: 0,
    versionTercero: "",
    rfcTercero: "",
    nombreTercero: "",
    reservado1: "",
    reservado2: "",
    reservado3: "",
    reservado4: "",
    reservado5: "",
    fechaUltimaModificacion: "",
    origenProcess: "",
    ipHostClient: "",
    hostNameClient: "",
    userNameClient: "",
  }
  bodyImpuestosFiscal: any = {
    invoiceHeaderObjectId: 0,
    totalImpuestosTrasladados: 0,
    reservado1: "",
    reservado2: "",
    reservado3: "",
    reservado4: "",
    reservado5: "",
    gechaUltimaModificacion: "",
    origenProcess: "",
    ipHostClient: "",
    hostNameClient: "",
    userNameClient: "",
  }
  bodyImpuestosTrasladoTercero: any = {
    invoiceHeaderObjectId: 0,
    impuestoTerceroFiscal: 0,
    tasaTerceroFiscal: 0,
    importeTerceroFiscal: 0,
    reservado1: "",
    reservado2: "",
    reservado3: "",
    reservado4: "",
    reservado5: "",
    lstMdfDate: "",
    origenProcess: "",
    ipHostClient: "",
    hostNameClient: "",
    userNameClient: "",
  }
  bodyImpuestosTrasladoFiscal: any = {
    invoiceHeaderObjectId: 0,
    impuestoTrasladoFiscal: "",
    tipoFactorTrasladoFiscal: "",
    tasaOCuotaTrasladoFiscal: 0,
    importeTrasladoFiscal: 0,
    reservado1: "",
    reservado2: "",
    reservado3: "",
    reservado4: "",
    reservado5: "",
    lstMdfDate: "",
    origenProcess: "",
    ipHostClient: "",
    hostNameClient: "",
    userNameClient: "",
  }
  bodyComercioExterior: any = {
    invoiceHeaderObjectId: 0,
    versionCCE: 0,
    motivoTraslado: "",
    tipoOperacion: "",
    claveDePedimento: "",
    certificadoOrigen: 0,
    numCertificadoOrigen: "",
    numeroExportadorConfiable: "",
    incoterm: "",
    subdivision: 0,
    observaciones: "",
    tipoCambioUSD: 0,
    totalUSD: 0,
    reservado1: "",
    reservado2: "",
    reservado3: "",
    reservado4: "",
    reservado5: "",
    rstMdfDate: "",
    origenProcess: "",
    ipHostClient: "",
    hostNameClient: "",
    userNameClient: "",
  }
  bodyDestinatarioCCE: any = {
    invoiceHeaderObjectId: 0,
    numRegIdTribDCCE: "",
    nombreDCCE: "",
    reservado1: "",
    reservado2: "",
    reservado3: "",
    reservado4: "",
    reservado5: "",
    lstMdfDate: "",
    origenProcess: "",
    ipHostClient: "",
    hostNameClient: "",
    userNameClient: "",
  }
  bodyDomicilioDestinatarioCCE: any = {
    invoiceHeaderObjectId: 0,
    calleDCCE: "",
    noExteriorDCCE: "",
    noInteriorDCCE: "",
    coloniaDCCE: "",
    localidadDCCE: "",
    referenciaDCCE: "",
    municipioDCCE: "",
    estadoDCCE: "",
    paisDCCE: "",
    codipostalDCCE: "",
    reservado1: "",
    reservado2: "",
    reservado3: "",
    reservado4: "",
    reservado5: "",
    lstMdfDate: "",
    origenProcess: "",
    ipHostClient: "",
    hostNameClient: "",
    userNameClient: "",
  }
  bodyDomicilioEmisorCCE: any = {
    invoiceHeaderObjectId: 0,
    calleECCE: "",
    noExteriorECCE: "",
    noInteriorECCE: "",
    coloniaECCE: "",
    localidadECCE: "",
    referenciaECCE: "",
    municipioECCE: "",
    estadoECCE: "",
    paisECCE: "",
    codipostalECCE: "",
    reservado1: "",
    reservado2: "",
    reservado3: "",
    reservado4: "",
    reservado5: "",
    lstMdfDate: "",
    origenProcess: "",
    ipHostClient: "",
    hostNameClient: "",
    userNameClient: "",
  }
  bodyDomicilioReceptorCCE: any = {
    invoiceHeaderObjectId: 0,
    calleRCCE: "",
    noExteriorRCCE: "",
    noInteriorRCCE: "",
    coloniaRCCE: "",
    localidadRCCE: "",
    referenciaRCCE: "",
    municipioRCCE: "",
    estadoRCCE: "",
    paisRCCE: "",
    codipostalRCCE: "",
    reservado1: "",
    reservado2: "",
    reservado3: "",
    reservado4: "",
    reservado5: "",
    lstMdfDate: "",
    origenProcess: "",
    ipHostClient: "",
    hostNameClient: "",
    userNameClient: "",
  }
  bodyPropietarioCCE: any = {
    invoiceHeaderObjectId: 0,
    numRegIdTribPCCE: "",
    residenciaFiscalPCCE: "",
    reservado1: "",
    reservado2: "",
    reservado3: "",
    reservado4: "",
    reservado5: "",
    lstMdfDate: "",
    origenProcess: "",
    ipHostClient: "",
    hostNameClient: "",
    userNameClient: "",
  }
  bodyReceptorCCE: any = {
    invoiceHeaderObjectId: 0,
    numCuentaReceptorExtranjero: 0,
    reservado1: "",
    reservado2: "",
    reservado3: "",
    reservado4: "",
    reservado5: "",
    rstMdfDate: "",
    origenProcess: "",
    ipHostClient: "",
    hostNameClient: "",
    userNameClient: "",
  }
  bodyDescripcionESPCCE: any = {
    invoiceHeaderObjectId: 0,
    marcaCCE: "",
    modeloCCE: "",
    subModeloCCE: "",
    numeroSerieCCE: "",
    reservado1: "",
    reservado2: "",
    reservado3: "",
    reservado4: "",
    reservado5: "",
    lstMdfDate: "",
    origenProcess: "",
    ipHostClient: "",
    hostNameClient: "",
    userNameClient: "",
  }
  bodyLeyendasCCE: any = {
    invoiceHeaderObjectId: 0,
    leyendaEspanol1: "",
    leyendaEspanol2: "",
    leyendaIngles1: "",
    leyendaIngles2: "",
    reservado1: "",
    reservado2: "",
    reservado3: "",
    reservado4: "",
    reservado5: "",
    lstMdfDate: "",
    origenProcess: "",
    ipHostClient: "",
    hostNameClient: "",
    userNameClient: "",
  }
  bodyMercanciasCCE: any = {
    invoiceHeaderObjectId: 0,
    noIdentificacionMCCE: "",
    fraccionArancelaria: "",
    cantidadAduana: 0,
    unidadAduanaMCCE: "",
    valorUnitarioAduanaMCCE: 0,
    valorDolaresMCCE: 0,
    reservado1: "",
    reservado2: "",
    reservado3: "",
    reservado4: "",
    reservado5: "",
    lstMdfDate: "",
    origenProcess: "",
    ipHostClient: "",
    hostNameClient: "",
    userNameClient: "",
  }
  bodyLeyenda: any = {
    invoiceHeaderObjectId: 0,
    disposicionFiscal: "",
    norma: "",
    textoLeyenda: "",
    reservado1: "",
    reservado2: "",
    reservado3: "",
    reservado4: "",
    reservado5: "",
    lstMdfDate: "",
    origenProcess: "",
    ipHostClient: "",
    hostNameClient: "",
    userNameClient: "",
  }
  bodyLeyendasFiscales: any = {
    invoiceHeaderObjectId: 0,
    versionLF: "",
    reservado1: "",
    reservado2: "",
    reservado3: "",
    reservado4: "",
    reservado5: "",
    lstMdfDate: "",
    origenProcess: "",
    ipHostClient: "",
    hostNameClient: "",
    userNameClient: "",
  }
  bodyAddenda: any = {
    invoiceHeaderObjectId: 0,
    idAddenda: "",
    esquemaAddenda: "",
    funcion: "",
    tipoDocumento: "",
    tipoAddenda: "",
    numControl: "",
    numDocumento: "",
    estatusOC: "",
    plaza: "",
    nombrePlaza: "",
    numFolioma: 0,
    nombreDeQuienRecibe: "",
    nombreContactoCompra: "",
    emailNotificacion: "",
    folioInternoClienteDR: "",
    telefonoComprador: "",
    reservado1: "",
    reservado2: "",
    reservado3: "",
    reservado4: "",
    reservado5: "",
    lstMdfDate: "",
    origenProcess: "",
    ipHostClient: "",
    hostNameClient: "",
    userNameClient: "",
  }
  bodyServiciosDistribucion: any = {
    invoiceHeaderObjectId: 0,
    idAmbiente: "",
    vanalEntrega: "",
    tipoEntrega: "",
    reservado1: "",
    reservado2: "",
    reservado3: "",
    reservado4: "",
    reservado5: "",
    lstMdfDate: "",
    origenProcess: "",
    ipHostClient: "",
    hostNameClient: "",
    userNameClient: "",
  }
  bodyServiciosPAC: any = {
    invoiceHeaderObjectId: 0,
    rfcPAC: "",
    idAmbiente: "",
    tipoServicio: 0,
    idInsumo: "",
    generaPDF: 0,
    imprimeEnCedis: 0,
    impresora: "",
    numCopias: 0,
    reservado1: "",
    reservado2: "",
    reservado3: "",
    reservado4: "",
    reservado5: "",
    lstMdfDate: "",
    origenProcess: "",
    ipHostClient: "",
    hostNameClient: "",
    userNameClient: "",
  }

  closeResult: string;
  tabSeleccionado: string = ""

  listaModales: any = []
  listaBusquedaModales: any = []
  busquedaModales: any = ""

  listaDocumentoClave: any = [];

  codigoClienteModal: any = "";
  razonSocialModal: any = "";
  documentoModal: any = "";
  estadoDocumentoModal: any = "";
  razonesModal: any = ""
  invHeaderObjId: any = "";
  claveTipoDocumento: any = "";

  contRepeater: number = 0;
  listaContenido: any = []
  listaContenidoDF : any = []

  listaMaestroCatalago : any = []
  comparacion : any = []
  buscar : any = ""

  //DocumentosFiscales
  listaDF : any = []
  listaAE : any = []
  DFObjectId: any = 0
  clienteDigital: any = ""
  seccion: any = ""
  actual: any = ""
  UUID: any = ""
  serie: any = ""
  folio: any = 0


  constructor(
    private pservice: LayoutService,
    private spinnerService: Ng4LoadingSpinnerService,
    private datePipe: DatePipe,
    private http: HttpClient,
    private modalService: ModalService,
    private toastr:CommontoastrService
  ) {
    this.cargaInicial()
  }
  cargaInicial() {
    this.spinnerService.show()
    this.pservice.ObtenerTabSegmentosHab({}).subscribe(data => {
      this.listaSegmentosHab = data;
    })
    this.getIpAddress();
    this.hostName = this.pservice.getHostname();
    this.pservice.ObtenerClaveDocs({}).subscribe(data => {
      this.listaDocumentoClave = data;
    }, error => { console.log(error) })

    this.pservice.ObtenerClienteEDI({}).subscribe(data => {
      this.spinnerService.hide()
      this.listaECPM = data['ecpm']
      this.listaPT = data['pt']
      this.listaPS = data['ps']
    },
      error => {
        this.spinnerService.hide()
        console.log("Error");
      });

    this.pservice.ObtenerListaSP({}).subscribe(data => {
      this.listaSP = data;

      for (let x = 0; x < this.listaSP.length; x++) {
        this.listaSP[x].descriptionLarge = this.listaSP[x].code + " - " + this.listaSP[x].descriptionSmall
        //console.log(this.listaSP[x].descriptionLarge);
      }

    }, error => { console.log(error) })


  }



  /* toggleExpandGroup(group) {
    console.log('Toggled Expand Group!', group);
    this.table.groupHeader.toggleExpandGroup(group);
  } */

  onDetailToggle(event) {
    console.log('Detail Toggled', event);
  }

/*   toggleExpandRow(row) {
    console.log('Toggled Expand Row!', row);
    this.table.rowDetail.toggleExpandRow(row);
  } */

  ngOnInit() { }

  ngOnDestroy() {
    // For method 1
    this.subscription && this.subscription.unsubscribe();
  }

  // openVerticallyCentered(content) {
  //   this.modalService.open(content, { centered: true, size: 'lg' });
  // }

  openModal(id: string) {
    this.modalService.open(id)
  }

  openModalResumen(id: string, invObjectId: number) {
    console.log("invObjectId");
    console.log(invObjectId);
    this.spinnerService.show()
    this.pservice.ObtenerResumen(invObjectId).subscribe(data => {
      this.spinnerService.hide()
      console.log(data)
      this.listaResumen = data;
      this.modalService.open(id)
    }, error => { console.log(error) })

  }

  openModalRetimbrado(id: string, row: any) {
    this.codigoClienteModal = row['Código de Cliente']
    this.razonSocialModal = row['Nombre de Cliente']
    this.documentoModal = row['SONumber']
    this.estadoDocumentoModal = row['CodeStatusProcess'] + ' - ' + row['StatusProcess']
    this.razonesModal = row['StatusProcessMSG']
    this.invHeaderObjId = row['Inv.ObjectId']
    this.claveTipoDocumento = row['Clave Tipo Documento']
    console.log(row);
    this.modalService.open(id)
  }

  openDocumentosFiscales(id: string, row: any,seccion ) {
    this.listaDF = ""
    this.listaAE = ""
    this.serie = row['InvoiceSerie']
    this.folio = row['Invoice']
    var data = {
      Serie: row['InvoiceSerie'],
      Folio: row['Invoice'],
      ECPMObjectId: this.DFObjectId,
      InvInvoiceHeaderObjectId: row['Inv.ObjectId'],
      DocumentKey: row['Clave Tipo Documento']
    }

    this.UUID = row['UUID']
    console.log("data");
    console.log(data);
    this.seccion = seccion
    this.spinnerService.show()
    this.pservice.DocumentosFiscales(data).subscribe(data => {
      if (data != null){
        this.listaContenidoDF = data[0][0]
      }
      /* this.listaDF = data[0][0];
      this.listaAE = data[1][0] */
      this.spinnerService.hide()
      console.log(this.listaContenidoDF);
    },
    error => {
      this.spinnerService.hide()
      console.log(error);
      //this.handleError(error);
    });
    this.modalService.open(id)
  }

  reloadModalMaestro(){
    this.pservice.ObtenerMDM({}).subscribe(data => {
      this.listaMaestroCatalago = data
      this.spinnerService.hide()
      console.log("Catalogo Clietnes");
      console.log(data);
      //this.toastr.Success("Proceso terminado correctamente")
    },
      error => {
        this.spinnerService.hide()
        console.log("Error");
      });
  }
  openModalMaestrosClientes(id: string, row: any) {
    this.recargar = 1
    this.modalService.open(id)
    this.spinnerService.show()
    const source = interval(5000);
    this.subscription = source.subscribe(val => this.reloadModalMaestro());
    //MDMCatalogClientes
  }

  /*reenviar() {
    let body = {
      InvObjectId: this.invHeaderObjId,
      Segmento: 'lo que sea'
    }

    this.pservice.RetimbrarDoc(body).subscribe(data => {
      console.log("Exito")
    }, error => { console.log(error) })
  }*/

  reenviar(id: string) {
    var obj = {
      InvoiceDocumentObjectId: this.invHeaderObjId,
      DocumentKey: this.claveTipoDocumento
    }

    this.spinnerService.show()

    this.pservice.ReprocesoDocumento(obj).subscribe(data => {

      this.spinnerService.hide()
      this.toastr.Success("Proceso terminado correctamente")
      this.filtrar()
    },
      error => {
        this.spinnerService.hide()
        console.log("Error");
      });
      this.modalService.close(id)
  }



  closeModal(id: string) {
    this.modalService.close(id);
    this.subscription && this.subscription.unsubscribe();
  }

  closeModalConcepto(id: string){
    this.selectTab(9,'Concepto','Concepto Comercial','Conceptos');
    this.modalService.close(id);
  }


  getIpAddress() {
    this.http.get("http://api.ipify.org/?format=json").subscribe((res: any) => {
      this.ipAddress = res.ip;
    });
  }

  detalle(row: any) {
    this.itemSeleccionado = false
    this.invoiceHeaderObjectId = row["Inv.ObjectId"]
    console.log(this.invoiceHeaderObjectId);
    this.selectedRow = row;
    console.log("row seleccionada");
    console.log(this.selectedRow);
  }

seleccionada(row){
  this.selectedRow = row;
}

  test2(e: any) {
    //console.log(e);
    var z = this;
    z.lCadena = z.cadena.pp[0].code + "- " + z.cadena.pp[0].description;

    z.descripcion = e["description"];
  }


  //Guardar ediciones de los segmentos
  guardarCambiosComComercial() {

    if (JSON.stringify(this.bodyComprobanteComercial) != JSON.stringify(this.comparacion[0][0])){
      var hoy = new Date()
    var local = localStorage.getItem("auth").split(",")
    var user = local[1].split(":")
    this.bodyComprobanteComercial.invoiceHeaderObjectId = this.invoiceHeaderObjectId;
    this.bodyComprobanteComercial.lstMdfDate = this.datePipe.transform(hoy, "yyyy-MM-dd");
    this.bodyComprobanteComercial.origenProcess = "Layout Universal";
    this.bodyComprobanteComercial.ipHostClient = this.ipAddress;
    this.bodyComprobanteComercial.hostNameClient = this.hostName;
    this.bodyComprobanteComercial.userNameClient = user[1].substr(1, user[1].length - 2)
    this.spinnerService.show()
    this.pservice.GuardarComprobanteComercial(this.bodyComprobanteComercial).subscribe(data => {
      this.spinnerService.hide()
      this.toastr.Success("Guardando cambios")
      this.comparacion[0][0]=  JSON.parse(JSON.stringify(this.bodyComprobanteComercial))
    },
      error => {
        console.log(error)
        this.spinnerService.hide()
      })
    }else{
      this.toastr.Error("No se ha realizado ningun cambio")
    }
  }

  guardarCambiosComFiscal() {
    if (JSON.stringify(this.bodyComprobanteFiscal) != JSON.stringify(this.comparacion[1][0])){
      var hoy = new Date()
    var local = localStorage.getItem("auth").split(",")
    var user = local[1].split(":")
    this.bodyComprobanteFiscal.invoiceHeaderObjectId = this.invoiceHeaderObjectId;
    this.bodyComprobanteFiscal.lstMdfDate = this.datePipe.transform(hoy, "yyyy-MM-dd");
    this.bodyComprobanteFiscal.origenProcess = "Layout Universal";
    this.bodyComprobanteFiscal.ipHostClient = this.ipAddress;
    this.bodyComprobanteFiscal.hostNameClient = this.hostName;
    this.bodyComprobanteFiscal.userNameClient = user[1].substr(1, user[1].length - 2)

    this.spinnerService.show()
    this.pservice.GuardarComprobanteFiscal(this.bodyComprobanteFiscal).subscribe(data => {
      this.spinnerService.hide()
      console.log(data)
    },
      error => {
        console.log(error)
        this.spinnerService.hide()
      })
    }else{
      this.toastr.Error("No se ha realizado ningun cambio")
    }

  }

  guardarCambiosCfdiRelacionados() {
    console.log(JSON.stringify(this.comparacion[0][0]));
    console.log(JSON.stringify(this.bodyCfdiRelacionados));
    //if (this.bodyCfdiRelacionados == "" && this.comparacion)
    if ((JSON.stringify(this.bodyCfdiRelacionados) != JSON.stringify(this.comparacion[0][0]) && this.bodyCfdiRelacionados != "" )){
      var hoy = new Date()
      var local = localStorage.getItem("auth").split(",")
      var user = local[1].split(":")
      console.log(this.bodyCfdiRelacionados.invoiceHeaderObjectId);
      this.bodyCfdiRelacionados.invoiceHeaderObjectId = this.invoiceHeaderObjectId;
      this.bodyCfdiRelacionados.lstMdfDate = this.datePipe.transform(hoy, "yyyy-MM-dd");
      this.bodyCfdiRelacionados.origenProcess = "Layout Universal";
      this.bodyCfdiRelacionados.ipHostClient = this.ipAddress;
      this.bodyCfdiRelacionados.hostNameClient = this.hostName;
      this.bodyCfdiRelacionados.userNameClient = user[1].substr(1, user[1].length - 2)

      this.spinnerService.show()
      this.pservice.GuardarCfdiRelacionados(this.bodyCfdiRelacionados).subscribe(data => {
        this.spinnerService.hide()
        console.log(data)
      },
        error => {
          console.log(error)
          this.spinnerService.hide()
        })
    }else{
      this.toastr.Error("No se ha realizado ningun cambio")
    }

  }

  guardarCambiosEmisorCCE() {
    if (JSON.stringify(this.bodyEmisorCCE) != JSON.stringify(this.comparacion[0][0])){
      var hoy = new Date()
      var local = localStorage.getItem("auth").split(",")
      var user = local[1].split(":")
      this.bodyEmisorCCE.invoiceHeaderObjectId = this.invoiceHeaderObjectId;
      this.bodyEmisorCCE.lstMdfDate = this.datePipe.transform(hoy, "yyyy-MM-dd");
      this.bodyEmisorCCE.origenProcess = "Layout Universal";
      this.bodyEmisorCCE.ipHostClient = this.ipAddress;
      this.bodyEmisorCCE.hostNameClient = this.hostName;
      this.bodyEmisorCCE.userNameClient = user[1].substr(1, user[1].length - 2)

      this.spinnerService.show()
      this.pservice.GuardarEmisorCCE(this.bodyEmisorCCE).subscribe(data => {
        this.spinnerService.hide()
        console.log(data)
      },
        error => {
          console.log(error)
          this.spinnerService.hide()
        })
    }else{
      this.toastr.Error("No se ha realizado ningun cambio")
    }

  }

  guardarCambiosEmisorComercial() {

    if (JSON.stringify(this.bodyEmisorComercial) != JSON.stringify(this.comparacion[1][0])){
      var hoy = new Date()
    var local = localStorage.getItem("auth").split(",")
    var user = local[1].split(":")
    this.bodyEmisorComercial.invoiceHeaderObjectId = this.invoiceHeaderObjectId;
    this.bodyEmisorComercial.lstMdfDate = this.datePipe.transform(hoy, "yyyy-MM-dd");
    this.bodyEmisorComercial.origenProcess = "Layout Universal";
    this.bodyEmisorComercial.ipHostClient = this.ipAddress;
    this.bodyEmisorComercial.hostNameClient = this.hostName;
    this.bodyEmisorComercial.userNameClient = user[1].substr(1, user[1].length - 2)

    this.spinnerService.show()
    this.pservice.GuardarEmisorComercial(this.bodyEmisorComercial).subscribe(data => {
      this.spinnerService.hide()
      console.log(data)
    },
      error => {
        console.log(error)
        this.spinnerService.hide()
      })
    }else{
      this.toastr.Error("No se ha realizado ningun cambio")
    }

  }

  guardarCambiosEmisorExpedicion() {
    if (JSON.stringify(this.bodyEmisorExpedicion) != JSON.stringify(this.comparacion[2][0])){
      var hoy = new Date()
    var local = localStorage.getItem("auth").split(",")
    var user = local[1].split(":")
    this.bodyEmisorExpedicion.invoiceHeaderObjectId = this.invoiceHeaderObjectId;
    this.bodyEmisorExpedicion.lstMdfDate = this.datePipe.transform(hoy, "yyyy-MM-dd");
    this.bodyEmisorExpedicion.origenProcess = "Layout Universal";
    this.bodyEmisorExpedicion.ipHostClient = this.ipAddress;
    this.bodyEmisorExpedicion.hostNameClient = this.hostName;
    this.bodyEmisorExpedicion.userNameClient = user[1].substr(1, user[1].length - 2)

    this.spinnerService.show()
    this.pservice.GuardarEmisorExpedicion(this.bodyEmisorExpedicion).subscribe(data => {
      this.spinnerService.hide()
      console.log(data)
    },
      error => {
        console.log(error)
        this.spinnerService.hide()
      })
    }else{
      this.toastr.Error("No se ha realizado ningun cambio")
    }

  }

  guardarCambiosEmisorFiscal() {
    if (JSON.stringify(this.bodyEmisorFiscal) != JSON.stringify(this.comparacion[3][0])){
      var hoy = new Date()
      var local = localStorage.getItem("auth").split(",")
      var user = local[1].split(":")
      this.bodyEmisorFiscal.invoiceHeaderObjectId = this.invoiceHeaderObjectId;
      this.bodyEmisorFiscal.lstMdfDate = this.datePipe.transform(hoy, "yyyy-MM-dd");
      this.bodyEmisorFiscal.origenProcess = "Layout Universal";
      this.bodyEmisorFiscal.ipHostClient = this.ipAddress;
      this.bodyEmisorFiscal.hostNameClient = this.hostName;
      this.bodyEmisorFiscal.userNameClient = user[1].substr(1, user[1].length - 2)

      this.spinnerService.show()
      this.pservice.GuardarEmisorFiscal(this.bodyEmisorFiscal).subscribe(data => {
        this.spinnerService.hide()
        console.log(data)
      },
        error => {
          console.log(error)
          this.spinnerService.hide()
      })
    }else{
      this.toastr.Error("No se ha realizado ningun cambio")
    }

  }

  guardarCambiosConceptoComercial() {
    if (JSON.stringify(this.bodyConceptoComercial) != JSON.stringify(this.comparacion[0][0])){
      var hoy = new Date()
      var local = localStorage.getItem("auth").split(",")
      var user = local[1].split(":")
      this.bodyConceptoComercial.invoiceHeaderObjectId = this.invoiceHeaderObjectId;
      this.bodyConceptoComercial.lstMdfDate = this.datePipe.transform(hoy, "yyyy-MM-dd");
      this.bodyConceptoComercial.origenProcess = "Layout Universal";
      this.bodyConceptoComercial.ipHostClient = this.ipAddress;
      this.bodyConceptoComercial.hostNameClient = this.hostName;
      this.bodyConceptoComercial.userNameClient = user[1].substr(1, user[1].length - 2)
      this.spinnerService.show()
      this.pservice.GuardarConceptoComercial(this.bodyConceptoComercial).subscribe(data => {
        this.spinnerService.hide()
        console.log(data)
      },
        error => {
          console.log(error)
          this.spinnerService.hide()
      })
    }else{
      this.toastr.Error("No se ha realizado ningun cambio")
    }


  }

  guardarCambiosConceptoFiscal() {
    if (JSON.stringify(this.bodyConceptoFiscal) != JSON.stringify(this.comparacion[1][0])){
      var hoy = new Date()
      var local = localStorage.getItem("auth").split(",")
      var user = local[1].split(":")
      this.bodyConceptoFiscal.invoiceHeaderObjectId = this.invoiceHeaderObjectId;
      this.bodyConceptoFiscal.lstMdfDate = this.datePipe.transform(hoy, "yyyy-MM-dd");
      this.bodyConceptoFiscal.origenProcess = "Layout Universal";
      this.bodyConceptoFiscal.ipHostClient = this.ipAddress;
      this.bodyConceptoFiscal.hostNameClient = this.hostName;
      this.bodyConceptoFiscal.userNameClient = user[1].substr(1, user[1].length - 2)

      this.spinnerService.show()
      this.pservice.GuardarConceptoFiscal(this.bodyConceptoFiscal).subscribe(data => {
        this.spinnerService.hide()
        console.log(data)
      },
        error => {
          console.log(error)
          this.spinnerService.hide()
        })
    }else{
      this.toastr.Error("No se ha realizado ningun cambio")
    }

  }

  guardarCambiosTrasladoConceptoComercial() {
    if (JSON.stringify(this.bodyTrasladoConceptoComercial) != JSON.stringify(this.comparacion[2][0])){
      var hoy = new Date()
      var local = localStorage.getItem("auth").split(",")
      var user = local[1].split(":")
      this.bodyTrasladoConceptoComercial.invoiceHeaderObjectId = this.invoiceHeaderObjectId;
      this.bodyTrasladoConceptoComercial.FechaUltimaModificacion = this.datePipe.transform(hoy, "yyyy-MM-dd");
      this.bodyTrasladoConceptoComercial.origenProcess = "Layout Universal";
      this.bodyTrasladoConceptoComercial.ipHostClient = this.ipAddress;
      this.bodyTrasladoConceptoComercial.hostNameClient = this.hostName;
      this.bodyTrasladoConceptoComercial.userNameClient = user[1].substr(1, user[1].length - 2)

      this.spinnerService.show()
      this.pservice.GuardarConceptoTrasladoComercial(this.bodyTrasladoConceptoComercial).subscribe(data => {
        this.spinnerService.hide()
        console.log(data)
      },
        error => {
          console.log(error)
          this.spinnerService.hide()
        })
    }else{
      this.toastr.Error("No se ha realizado ningun cambio")
    }

  }

  guardarCambiosTrasladoConceptoFiscal() {
    if (JSON.stringify(this.bodyTrasladoConceptoFiscal) != JSON.stringify(this.comparacion[3][0])){
      var hoy = new Date()
      var local = localStorage.getItem("auth").split(",")
      var user = local[1].split(":")
      this.bodyTrasladoConceptoFiscal.invoiceHeaderObjectId = this.invoiceHeaderObjectId;
      this.bodyTrasladoConceptoFiscal.FechaUltimaModificacion = this.datePipe.transform(hoy, "yyyy-MM-dd");
      this.bodyTrasladoConceptoFiscal.origenProcess = "Layout Universal";
      this.bodyTrasladoConceptoFiscal.ipHostClient = this.ipAddress;
      this.bodyTrasladoConceptoFiscal.hostNameClient = this.hostName;
      this.bodyTrasladoConceptoFiscal.userNameClient = user[1].substr(1, user[1].length - 2)

      this.spinnerService.show()
      this.pservice.GuardarConceptoTrasladoFiscal(this.bodyTrasladoConceptoFiscal).subscribe(data => {
        this.spinnerService.hide()
        console.log(data)
      },
        error => {
          console.log(error)
          this.spinnerService.hide()
        })
    }else{
      this.toastr.Error("No se ha realizado ningun cambio")
    }

  }

  guardarCambiosReceptorComercial() {
    if (JSON.stringify(this.bodyReceptorComercial) != JSON.stringify(this.comparacion[0][0])){
      var hoy = new Date()
      var local = localStorage.getItem("auth").split(",")
      var user = local[1].split(":")
      this.bodyReceptorComercial.invoiceHeaderObjectId = this.invoiceHeaderObjectId;
      this.bodyReceptorComercial.lstMdfDate = this.datePipe.transform(hoy, "yyyy-MM-dd");
      this.bodyReceptorComercial.origenProcess = "Layout Universal";
      this.bodyReceptorComercial.ipHostClient = this.ipAddress;
      this.bodyReceptorComercial.hostNameClient = this.hostName;
      this.bodyReceptorComercial.userNameClient = user[1].substr(1, user[1].length - 2)
      // console.log(this.bodyComprobanteComercial)

      this.spinnerService.show()
      this.pservice.GuardarReceptorComercial(this.bodyReceptorComercial).subscribe(data => {
        this.spinnerService.hide()
        console.log(data)
      },
        error => {
          console.log(error)
          this.spinnerService.hide()
        })
    }else{
      this.toastr.Error("No se ha realizado ningun cambio")
    }

  }

  guardarCambiosReceptorEntrega() {
    if (JSON.stringify(this.bodyReceptorEntrega) != JSON.stringify(this.comparacion[1][0])){
      var hoy = new Date()
      var local = localStorage.getItem("auth").split(",")
      var user = local[1].split(":")
      this.bodyReceptorEntrega.invoiceHeaderObjectId = this.invoiceHeaderObjectId;
      this.bodyReceptorEntrega.lstMdfDate = this.datePipe.transform(hoy, "yyyy-MM-dd");
      this.bodyReceptorEntrega.origenProcess = "Layout Universal";
      this.bodyReceptorEntrega.ipHostClient = this.ipAddress;
      this.bodyReceptorEntrega.hostNameClient = this.hostName;
      this.bodyReceptorEntrega.userNameClient = user[1].substr(1, user[1].length - 2)

      this.spinnerService.show()
      this.pservice.GuardarReceptorEntrega(this.bodyReceptorEntrega).subscribe(data => {
        this.spinnerService.hide()
        console.log(data)
      },
        error => {
          console.log(error)
          this.spinnerService.hide()
        })
    }else{
      this.toastr.Error("No se ha realizado ningun cambio")
    }

  }

  guardarCambiosReceptorFiscal() {
    if (JSON.stringify(this.bodyReceptorFiscal) != JSON.stringify(this.comparacion[2][0])){
      var hoy = new Date()
      var local = localStorage.getItem("auth").split(",")
      var user = local[1].split(":")
      this.bodyReceptorFiscal.invoiceHeaderObjectId = this.invoiceHeaderObjectId;
      this.bodyReceptorFiscal.lstMdfDate = this.datePipe.transform(hoy, "yyyy-MM-dd");
      this.bodyReceptorFiscal.origenProcess = "Layout Universal";
      this.bodyReceptorFiscal.ipHostClient = this.ipAddress;
      this.bodyReceptorFiscal.hostNameClient = this.hostName;
      this.bodyReceptorFiscal.userNameClient = user[1].substr(1, user[1].length - 2)

      this.spinnerService.show()
      this.pservice.GuardarReceptorFiscal(this.bodyReceptorFiscal).subscribe(data => {
        this.spinnerService.hide()
        console.log(data)
      },
        error => {
          console.log(error)
          this.spinnerService.hide()
        })
    }else{
      this.toastr.Error("No se ha realizado ningun cambio")
    }

  }

  guardarCambiosInfAduaneraTercero() {
    if (JSON.stringify(this.bodyInfAduaneraTercero) != JSON.stringify(this.comparacion[0][0])){
      var hoy = new Date()
      var local = localStorage.getItem("auth").split(",")
      var user = local[1].split(":")
      this.bodyInfAduaneraTercero.invoiceHeaderObjectId = this.invoiceHeaderObjectId;
      this.bodyInfAduaneraTercero.lstMdfDate = this.datePipe.transform(hoy, "yyyy-MM-dd");
      this.bodyInfAduaneraTercero.origenProcess = "Layout Universal";
      this.bodyInfAduaneraTercero.ipHostClient = this.ipAddress;
      this.bodyInfAduaneraTercero.hostNameClient = this.hostName;
      this.bodyInfAduaneraTercero.userNameClient = user[1].substr(1, user[1].length - 2)

      this.spinnerService.show()
      this.pservice.GuardarInformacionAduaneraTercero(this.bodyInfAduaneraTercero).subscribe(data => {
        this.spinnerService.hide()
        console.log(data)
      },
        error => {
          console.log(error)
          this.spinnerService.hide()
        })
    }else{
      this.toastr.Error("No se ha realizado ningun cambio")
    }

  }

  guardarCambiosInfFiscalTercero() {
    if (JSON.stringify(this.bodyInfFiscalTercero) != JSON.stringify(this.comparacion[1][0])){
      var hoy = new Date()
      var local = localStorage.getItem("auth").split(",")
      var user = local[1].split(":")
      this.bodyInfFiscalTercero.invoiceHeaderObjectId = this.invoiceHeaderObjectId;
      this.bodyInfFiscalTercero.lstMdfDate = this.datePipe.transform(hoy, "yyyy-MM-dd");
      this.bodyInfFiscalTercero.origenProcess = "Layout Universal";
      this.bodyInfFiscalTercero.ipHostClient = this.ipAddress;
      this.bodyInfFiscalTercero.hostNameClient = this.hostName;
      this.bodyInfFiscalTercero.userNameClient = user[1].substr(1, user[1].length - 2)
      this.spinnerService.show()
      this.pservice.GuardarInformacionFiscalTercero(this.bodyInfFiscalTercero).subscribe(data => {
        this.spinnerService.hide()
        console.log(data)
      },
        error => {
          console.log(error)
          this.spinnerService.hide()
        })
    }else{
      this.toastr.Error("No se ha realizado ningun cambio")
    }

  }

  guardarCambiosParteTercero() {
    if (JSON.stringify(this.bodyParteTercero) != JSON.stringify(this.comparacion[2][0])){
      var hoy = new Date()
      var local = localStorage.getItem("auth").split(",")
      var user = local[1].split(":")
      this.bodyParteTercero.invoiceHeaderObjectId = this.invoiceHeaderObjectId;
      this.bodyParteTercero.FechaUltimaModificacion = this.datePipe.transform(hoy, "yyyy-MM-dd");
      this.bodyParteTercero.origenProcess = "Layout Universal";
      this.bodyParteTercero.ipHostClient = this.ipAddress;
      this.bodyParteTercero.hostNameClient = this.hostName;
      this.bodyParteTercero.userNameClient = user[1].substr(1, user[1].length - 2)
      // console.log(this.bodyComprobanteComercial)

      this.spinnerService.show()
      this.pservice.GuardarParteTercero(this.bodyParteTercero).subscribe(data => {
        this.spinnerService.hide()
        console.log(data)
      },
        error => {
          console.log(error)
          this.spinnerService.hide()
        })
    }else{
      this.toastr.Error("No se ha realizado ningun cambio")
    }

  }

  guardarCambiosPorParteDeTercerosFiscal() {
    if (JSON.stringify(this.bodyPorParteDeTercerosFiscal) != JSON.stringify(this.comparacion[3][0])){
      var hoy = new Date()
      var local = localStorage.getItem("auth").split(",")
      var user = local[1].split(":")
      this.bodyPorParteDeTercerosFiscal.invoiceHeaderObjectId = this.invoiceHeaderObjectId;
      this.bodyPorParteDeTercerosFiscal.FechaUltimaModificacion = this.datePipe.transform(hoy, "yyyy-MM-dd");
      this.bodyPorParteDeTercerosFiscal.origenProcess = "Layout Universal";
      this.bodyPorParteDeTercerosFiscal.ipHostClient = this.ipAddress;
      this.bodyPorParteDeTercerosFiscal.hostNameClient = this.hostName;
      this.bodyPorParteDeTercerosFiscal.userNameClient = user[1].substr(1, user[1].length - 2)
      // console.log(this.bodyComprobanteComercial)

      this.spinnerService.show()
      this.pservice.GuardarPorCuentaDeTercerosFiscal(this.bodyPorParteDeTercerosFiscal).subscribe(data => {
        this.spinnerService.hide()
        console.log(data)
      },
        error => {
          console.log(error)
          this.spinnerService.hide()
        })
    }else{
      this.toastr.Error("No se ha realizado ningun cambio")
    }

  }

  guardarCambiosImpuestosFiscal() {
    if (JSON.stringify(this.bodyImpuestosFiscal) != JSON.stringify(this.comparacion[0][0])){
      var hoy = new Date()
      var local = localStorage.getItem("auth").split(",")
      var user = local[1].split(":")
      this.bodyImpuestosFiscal.invoiceHeaderObjectId = this.invoiceHeaderObjectId;
      this.bodyImpuestosFiscal.FechaUltimaModificacion = this.datePipe.transform(hoy, "yyyy-MM-dd");
      this.bodyImpuestosFiscal.origenProcess = "Layout Universal";
      this.bodyImpuestosFiscal.ipHostClient = this.ipAddress;
      this.bodyImpuestosFiscal.hostNameClient = this.hostName;
      this.bodyImpuestosFiscal.userNameClient = user[1].substr(1, user[1].length - 2)
      // console.log(this.bodyComprobanteComercial)

      this.spinnerService.show()
      this.pservice.GuardarImpuestosFiscal(this.bodyImpuestosFiscal).subscribe(data => {
        this.spinnerService.hide()
        console.log(data)
      },
        error => {
          console.log(error)
          this.spinnerService.hide()
        })
    }else{
      this.toastr.Error("No se ha realizado ningun cambio")
    }

  }

  guardarCambiosImpuestosTrasladoTercero() {
    if (JSON.stringify(this.bodyImpuestosTrasladoTercero) != JSON.stringify(this.comparacion[4][0])){
      var hoy = new Date()
      var local = localStorage.getItem("auth").split(",")
      var user = local[1].split(":")
      this.bodyImpuestosTrasladoTercero.invoiceHeaderObjectId = this.invoiceHeaderObjectId;
      this.bodyImpuestosTrasladoTercero.lstMdfDate = this.datePipe.transform(hoy, "yyyy-MM-dd");
      this.bodyImpuestosTrasladoTercero.origenProcess = "Layout Universal";
      this.bodyImpuestosTrasladoTercero.ipHostClient = this.ipAddress;
      this.bodyImpuestosTrasladoTercero.hostNameClient = this.hostName;
      this.bodyImpuestosTrasladoTercero.userNameClient = user[1].substr(1, user[1].length - 2)
      // console.log(this.bodyComprobanteComercial)

      this.spinnerService.show()
      this.pservice.GuardarImpuestoTrasladoTercero(this.bodyImpuestosTrasladoTercero).subscribe(data => {
        this.spinnerService.hide()
        console.log(data)
      },
        error => {
          console.log(error)
          this.spinnerService.hide()
        })
    }else{
      this.toastr.Error("No se ha realizado ningun cambio")
    }

  }

  guardarCambiosImpuestosTrasladoFiscal() {
    if (JSON.stringify(this.bodyImpuestosTrasladoFiscal) != JSON.stringify(this.comparacion[1][0])){
      var hoy = new Date()
      var local = localStorage.getItem("auth").split(",")
      var user = local[1].split(":")
      this.bodyImpuestosTrasladoFiscal.invoiceHeaderObjectId = this.invoiceHeaderObjectId;
      this.bodyImpuestosTrasladoFiscal.lstMdfDate = this.datePipe.transform(hoy, "yyyy-MM-dd");
      this.bodyImpuestosTrasladoFiscal.origenProcess = "Layout Universal";
      this.bodyImpuestosTrasladoFiscal.ipHostClient = this.ipAddress;
      this.bodyImpuestosTrasladoFiscal.hostNameClient = this.hostName;
      this.bodyImpuestosTrasladoFiscal.userNameClient = user[1].substr(1, user[1].length - 2)
      // console.log(this.bodyComprobanteComercial)

      this.spinnerService.show()
      this.pservice.GuardarImpuestoTrasladoFiscal(this.bodyImpuestosTrasladoFiscal).subscribe(data => {
        this.spinnerService.hide()
        console.log(data)
      },
        error => {
          console.log(error)
          this.spinnerService.hide()
        })
    }else{
      this.toastr.Error("No se ha realizado ningun cambio")
    }
  }

  guardarCambiosComercioExterior() {
    if (JSON.stringify(this.bodyComercioExterior) != JSON.stringify(this.comparacion[0][0])){
      var hoy = new Date()
      var local = localStorage.getItem("auth").split(",")
      var user = local[1].split(":")
      this.bodyComercioExterior.invoiceHeaderObjectId = this.invoiceHeaderObjectId;
      this.bodyComercioExterior.lstMdfDate = this.datePipe.transform(hoy, "yyyy-MM-dd");
      this.bodyComercioExterior.origenProcess = "Layout Universal";
      this.bodyComercioExterior.ipHostClient = this.ipAddress;
      this.bodyComercioExterior.hostNameClient = this.hostName;
      this.bodyComercioExterior.userNameClient = user[1].substr(1, user[1].length - 2)
      // console.log(this.bodyComprobanteComercial)

      this.spinnerService.show()
      this.pservice.GuardarComercioExterior(this.bodyComercioExterior).subscribe(data => {
        this.spinnerService.hide()
        console.log(data)
      },
        error => {
          console.log(error)
          this.spinnerService.hide()
        })
    }else{
      this.toastr.Error("No se ha realizado ningun cambio")
    }
  }

  guardarCambiosDestinatarioCCE() {
    if (JSON.stringify(this.bodyDestinatarioCCE) != JSON.stringify(this.comparacion[1][0])){
      var hoy = new Date()
      var local = localStorage.getItem("auth").split(",")
      var user = local[1].split(":")
      this.bodyDestinatarioCCE.invoiceHeaderObjectId = this.invoiceHeaderObjectId;
      this.bodyDestinatarioCCE.lstMdfDate = this.datePipe.transform(hoy, "yyyy-MM-dd");
      this.bodyDestinatarioCCE.origenProcess = "Layout Universal";
      this.bodyDestinatarioCCE.ipHostClient = this.ipAddress;
      this.bodyDestinatarioCCE.hostNameClient = this.hostName;
      this.bodyDestinatarioCCE.userNameClient = user[1].substr(1, user[1].length - 2)
      // console.log(this.bodyComprobanteComercial)

      this.spinnerService.show()
      this.pservice.GuardarDestinatarioCCE(this.bodyDestinatarioCCE).subscribe(data => {
        this.spinnerService.hide()
        console.log(data)
      },
        error => {
          console.log(error)
          this.spinnerService.hide()
        })
    }else{
      this.toastr.Error("No se ha realizado ningun cambio")
    }

  }

  guardarCambiosDomicilioDestinatarioCCE() {
    if (JSON.stringify(this.bodyDomicilioDestinatarioCCE) != JSON.stringify(this.comparacion[2][0])){
      var hoy = new Date()
      var local = localStorage.getItem("auth").split(",")
      var user = local[1].split(":")
      this.bodyDomicilioDestinatarioCCE.invoiceHeaderObjectId = this.invoiceHeaderObjectId;
      this.bodyDomicilioDestinatarioCCE.lstMdfDate = this.datePipe.transform(hoy, "yyyy-MM-dd");
      this.bodyDomicilioDestinatarioCCE.origenProcess = "Layout Universal";
      this.bodyDomicilioDestinatarioCCE.ipHostClient = this.ipAddress;
      this.bodyDomicilioDestinatarioCCE.hostNameClient = this.hostName;
      this.bodyDomicilioDestinatarioCCE.userNameClient = user[1].substr(1, user[1].length - 2)
      // console.log(this.bodyComprobanteComercial)

      this.spinnerService.show()
      this.pservice.GuardarDomicilioDestinatarioCCE(this.bodyDomicilioDestinatarioCCE).subscribe(data => {
        this.spinnerService.hide()
        console.log(data)
      },
        error => {
          console.log(error)
          this.spinnerService.hide()
        })
    }else{
      this.toastr.Error("No se ha realizado ningun cambio")
    }
  }

  guardarCambiosDomicilioEmisorCCE() {
    if (JSON.stringify(this.bodyDomicilioEmisorCCE) != JSON.stringify(this.comparacion[3][0])){
      var hoy = new Date()
      var local = localStorage.getItem("auth").split(",")
      var user = local[1].split(":")
      this.bodyDomicilioEmisorCCE.invoiceHeaderObjectId = this.invoiceHeaderObjectId;
      this.bodyDomicilioEmisorCCE.lstMdfDate = this.datePipe.transform(hoy, "yyyy-MM-dd");
      this.bodyDomicilioEmisorCCE.origenProcess = "Layout Universal";
      this.bodyDomicilioEmisorCCE.ipHostClient = this.ipAddress;
      this.bodyDomicilioEmisorCCE.hostNameClient = this.hostName;
      this.bodyDomicilioEmisorCCE.userNameClient = user[1].substr(1, user[1].length - 2)
      // console.log(this.bodyComprobanteComercial)

      this.spinnerService.show()
      this.pservice.GuardarDomicilioEmisorCCE(this.bodyDomicilioEmisorCCE).subscribe(data => {
        this.spinnerService.hide()
        console.log(data)
      },
        error => {
          console.log(error)
          this.spinnerService.hide()
        })
    }else{
      this.toastr.Error("No se ha realizado ningun cambio")
    }
  }

  guardarCambiosDomicilioReceptorCCE() {
    if (JSON.stringify(this.bodyDomicilioReceptorCCE) != JSON.stringify(this.comparacion[4][0])){
      var hoy = new Date()
      var local = localStorage.getItem("auth").split(",")
      var user = local[1].split(":")
      this.bodyDomicilioReceptorCCE.invoiceHeaderObjectId = this.invoiceHeaderObjectId;
      this.bodyDomicilioReceptorCCE.lstMdfDate = this.datePipe.transform(hoy, "yyyy-MM-dd");
      this.bodyDomicilioReceptorCCE.origenProcess = "Layout Universal";
      this.bodyDomicilioReceptorCCE.ipHostClient = this.ipAddress;
      this.bodyDomicilioReceptorCCE.hostNameClient = this.hostName;
      this.bodyDomicilioReceptorCCE.userNameClient = user[1].substr(1, user[1].length - 2)
      // console.log(this.bodyComprobanteComercial)

      this.spinnerService.show()
      this.pservice.GuardarDomicilioReceptorCCE(this.bodyDomicilioReceptorCCE).subscribe(data => {
        this.spinnerService.hide()
        console.log(data)
      },
        error => {
          console.log(error)
          this.spinnerService.hide()
        })
    }else{
      this.toastr.Error("No se ha realizado ningun cambio")
    }
  }

  guardarCambiosPropietarioCCE() {
    if (JSON.stringify(this.bodyPropietarioCCE) != JSON.stringify(this.comparacion[5][0])){
      var hoy = new Date()
      var local = localStorage.getItem("auth").split(",")
      var user = local[1].split(":")
      this.bodyPropietarioCCE.invoiceHeaderObjectId = this.invoiceHeaderObjectId;
      this.bodyPropietarioCCE.lstMdfDate = this.datePipe.transform(hoy, "yyyy-MM-dd");
      this.bodyPropietarioCCE.origenProcess = "Layout Universal";
      this.bodyPropietarioCCE.ipHostClient = this.ipAddress;
      this.bodyPropietarioCCE.hostNameClient = this.hostName;
      this.bodyPropietarioCCE.userNameClient = user[1].substr(1, user[1].length - 2)
      // console.log(this.bodyComprobanteComercial)

      this.spinnerService.show()
      this.pservice.GuardarPropietarioCE(this.bodyPropietarioCCE).subscribe(data => {
        this.spinnerService.hide()
        console.log(data)
      },
        error => {
          console.log(error)
          this.spinnerService.hide()
        })
    }else{
      this.toastr.Error("No se ha realizado ningun cambio")
    }
  }

  guardarCambiosReceptorCCE() {
    if (JSON.stringify(this.bodyReceptorCCE) != JSON.stringify(this.comparacion[6][0])){
      var hoy = new Date()
      var local = localStorage.getItem("auth").split(",")
      var user = local[1].split(":")
      this.bodyReceptorCCE.invoiceHeaderObjectId = this.invoiceHeaderObjectId;
      this.bodyReceptorCCE.lstMdfDate = this.datePipe.transform(hoy, "yyyy-MM-dd");
      this.bodyReceptorCCE.origenProcess = "Layout Universal";
      this.bodyReceptorCCE.ipHostClient = this.ipAddress;
      this.bodyReceptorCCE.hostNameClient = this.hostName;
      this.bodyReceptorCCE.userNameClient = user[1].substr(1, user[1].length - 2)
      // console.log(this.bodyComprobanteComercial)

      this.spinnerService.show()
      this.pservice.GuardarReceptorCCE(this.bodyReceptorCCE).subscribe(data => {
        this.spinnerService.hide()
        console.log(data)
      },
        error => {
          console.log(error)
          this.spinnerService.hide()
        })
    }else{
      this.toastr.Error("No se ha realizado ningun cambio")
    }
  }

  guardarCambiosDescripcionESPCCE() {
    if (JSON.stringify(this.bodyDescripcionESPCCE) != JSON.stringify(this.comparacion[0][0])){
      var hoy = new Date()
      var local = localStorage.getItem("auth").split(",")
      var user = local[1].split(":")
      this.bodyDescripcionESPCCE.invoiceHeaderObjectId = this.invoiceHeaderObjectId;
      this.bodyDescripcionESPCCE.lstMdfDate = this.datePipe.transform(hoy, "yyyy-MM-dd");
      this.bodyDescripcionESPCCE.origenProcess = "Layout Universal";
      this.bodyDescripcionESPCCE.ipHostClient = this.ipAddress;
      this.bodyDescripcionESPCCE.hostNameClient = this.hostName;
      this.bodyDescripcionESPCCE.userNameClient = user[1].substr(1, user[1].length - 2)
      // console.log(this.bodyComprobanteComercial)

      this.spinnerService.show()
      this.pservice.GuardarDescripcionESCPCCE(this.bodyDescripcionESPCCE).subscribe(data => {
        this.spinnerService.hide()
        console.log(data)
      },
        error => {
          console.log(error)
          this.spinnerService.hide()
        })
    }else{
      this.toastr.Error("No se ha realizado ningun cambio")
    }
  }

  guardarCambiosLeyendasCCE() {
    if (JSON.stringify(this.bodyLeyendasCCE) != JSON.stringify(this.comparacion[1][0])){
      var hoy = new Date()
      var local = localStorage.getItem("auth").split(",")
      var user = local[1].split(":")
      this.bodyLeyendasCCE.invoiceHeaderObjectId = this.invoiceHeaderObjectId;
      this.bodyLeyendasCCE.lstMdfDate = this.datePipe.transform(hoy, "yyyy-MM-dd");
      this.bodyLeyendasCCE.origenProcess = "Layout Universal";
      this.bodyLeyendasCCE.ipHostClient = this.ipAddress;
      this.bodyLeyendasCCE.hostNameClient = this.hostName;
      this.bodyLeyendasCCE.userNameClient = user[1].substr(1, user[1].length - 2)
      // console.log(this.bodyComprobanteComercial)

      this.spinnerService.show()
      this.pservice.GuardarLeyendasCCE(this.bodyLeyendasCCE).subscribe(data => {
        this.spinnerService.hide()
        console.log(data)
      },
        error => {
          console.log(error)
          this.spinnerService.hide()
        })
    }else{
      this.toastr.Error("No se ha realizado ningun cambio")
    }
  }

  guardarCambiosMercanciasCCE() {
    if (JSON.stringify(this.bodyMercanciasCCE) != JSON.stringify(this.comparacion[2][0])){
      var hoy = new Date()
      var local = localStorage.getItem("auth").split(",")
      var user = local[1].split(":")
      this.bodyMercanciasCCE.invoiceHeaderObjectId = this.invoiceHeaderObjectId;
      this.bodyMercanciasCCE.lstMdfDate = this.datePipe.transform(hoy, "yyyy-MM-dd");
      this.bodyMercanciasCCE.origenProcess = "Layout Universal";
      this.bodyMercanciasCCE.ipHostClient = this.ipAddress;
      this.bodyMercanciasCCE.hostNameClient = this.hostName;
      this.bodyMercanciasCCE.userNameClient = user[1].substr(1, user[1].length - 2)
      // console.log(this.bodyComprobanteComercial)

      this.spinnerService.show()
      this.pservice.GuardarMercanciasCCE(this.bodyMercanciasCCE).subscribe(data => {
        this.spinnerService.hide()
        console.log(data)
      },
        error => {
          console.log(error)
          this.spinnerService.hide()
        })
    }else{
      this.toastr.Error("No se ha realizado ningun cambio")
    }
  }

  guardarCambiosLeyenda() {
    if (JSON.stringify(this.bodyLeyenda) != JSON.stringify(this.comparacion[0][0])){
      var hoy = new Date()
      var local = localStorage.getItem("auth").split(",")
      var user = local[1].split(":")
      this.bodyLeyenda.invoiceHeaderObjectId = this.invoiceHeaderObjectId;
      this.bodyLeyenda.lstMdfDate = this.datePipe.transform(hoy, "yyyy-MM-dd");
      this.bodyLeyenda.origenProcess = "Layout Universal";
      this.bodyLeyenda.ipHostClient = this.ipAddress;
      this.bodyLeyenda.hostNameClient = this.hostName;
      this.bodyLeyenda.userNameClient = user[1].substr(1, user[1].length - 2)
      // console.log(this.bodyComprobanteComercial)

      this.spinnerService.show()
      this.pservice.GuardarInvLeyenda(this.bodyLeyenda).subscribe(data => {
        this.spinnerService.hide()
        console.log(data)
      },
        error => {
          console.log(error)
          this.spinnerService.hide()
        })
    }else{
      this.toastr.Error("No se ha realizado ningun cambio")
    }
  }

  guardarCambiosLeyendasFiscales() {
    if (JSON.stringify(this.bodyLeyendasFiscales) != JSON.stringify(this.comparacion[1][0])){
      var hoy = new Date()
      var local = localStorage.getItem("auth").split(",")
      var user = local[1].split(":")
      this.bodyLeyendasFiscales.invoiceHeaderObjectId = this.invoiceHeaderObjectId;
      this.bodyLeyendasFiscales.lstMdfDate = this.datePipe.transform(hoy, "yyyy-MM-dd");
      this.bodyLeyendasFiscales.origenProcess = "Layout Universal";
      this.bodyLeyendasFiscales.ipHostClient = this.ipAddress;
      this.bodyLeyendasFiscales.hostNameClient = this.hostName;
      this.bodyLeyendasFiscales.userNameClient = user[1].substr(1, user[1].length - 2)
      // console.log(this.bodyComprobanteComercial)

      this.spinnerService.show()
      this.pservice.GuardarInvLeyendasFiscales(this.bodyLeyendasFiscales).subscribe(data => {
        this.spinnerService.hide()
        console.log(data)
      },
        error => {
          console.log(error)
          this.spinnerService.hide()
        })
    }else{
      this.toastr.Error("No se ha realizado ningun cambio")
    }
  }

  guardarCambiosAddenda() {
    if (JSON.stringify(this.bodyAddenda) != JSON.stringify(this.comparacion[0][0])){
      var hoy = new Date()
      var local = localStorage.getItem("auth").split(",")
      var user = local[1].split(":")
      this.bodyAddenda.invoiceHeaderObjectId = this.invoiceHeaderObjectId;
      this.bodyAddenda.lstMdfDate = this.datePipe.transform(hoy, "yyyy-MM-dd");
      this.bodyAddenda.origenProcess = "Layout Universal";
      this.bodyAddenda.ipHostClient = this.ipAddress;
      this.bodyAddenda.hostNameClient = this.hostName;
      this.bodyAddenda.userNameClient = user[1].substr(1, user[1].length - 2)
      // console.log(this.bodyComprobanteComercial)

      this.spinnerService.show()
      this.pservice.GuardarAddenda(this.bodyAddenda).subscribe(data => {
        this.spinnerService.hide()
        console.log(data)
      },
        error => {
          console.log(error)
          this.spinnerService.hide()
        })
    }else{
      this.toastr.Error("No se ha realizado ningun cambio")
    }
  }

  guardarCambiosServiciosDistribucion() {
    if (JSON.stringify(this.bodyServiciosDistribucion) != JSON.stringify(this.comparacion[0][0])){
      var hoy = new Date()
      var local = localStorage.getItem("auth").split(",")
      var user = local[1].split(":")
      this.bodyServiciosDistribucion.invoiceHeaderObjectId = this.invoiceHeaderObjectId;
      this.bodyServiciosDistribucion.lstMdfDate = this.datePipe.transform(hoy, "yyyy-MM-dd");
      this.bodyServiciosDistribucion.origenProcess = "Layout Universal";
      this.bodyServiciosDistribucion.ipHostClient = this.ipAddress;
      this.bodyServiciosDistribucion.hostNameClient = this.hostName;
      this.bodyServiciosDistribucion.userNameClient = user[1].substr(1, user[1].length - 2)
      // console.log(this.bodyComprobanteComercial)

      this.spinnerService.show()
      this.pservice.GuardarServiciosDistribucion(this.bodyServiciosDistribucion).subscribe(data => {
        this.spinnerService.hide()
        console.log(data)
      },
        error => {
          console.log(error)
          this.spinnerService.hide()
        })
    }else{
      this.toastr.Error("No se ha realizado ningun cambio")
    }
  }

  guardarCambiosServiciosPAC() {
    if (JSON.stringify(this.bodyServiciosPAC) != JSON.stringify(this.comparacion[1][0])){
      var hoy = new Date()
      var local = localStorage.getItem("auth").split(",")
      var user = local[1].split(":")
      this.bodyServiciosPAC.invoiceHeaderObjectId = this.invoiceHeaderObjectId;
      this.bodyServiciosPAC.lstMdfDate = this.datePipe.transform(hoy, "yyyy-MM-dd");
      this.bodyServiciosPAC.origenProcess = "Layout Universal";
      this.bodyServiciosPAC.ipHostClient = this.ipAddress;
      this.bodyServiciosPAC.hostNameClient = this.hostName;
      this.bodyServiciosPAC.userNameClient = user[1].substr(1, user[1].length - 2)
      // console.log(this.bodyComprobanteComercial)

      this.spinnerService.show()
      this.pservice.GuardarServiciosPAC(this.bodyServiciosPAC).subscribe(data => {
        this.spinnerService.hide()
        console.log(data)
      },
        error => {
          console.log(error)
          this.spinnerService.hide()
        })
    }else{
      this.toastr.Error("No se ha realizado ningun cambio")
    }
  }

  //termina sección

  capitalize(json: any, segmento: any) {


    if (segmento == "Comprobante") {
      this.bodyComprobanteComercial.invoiceHeaderObjectId = json.invoiceHeaderObjectId;
      this.bodyComprobanteComercial.NumeroOrdenDeCompra = json.numeroOrdenDeCompra;
      this.bodyComprobanteComercial.FechaOrdenDeCompra = json.fechaOrdenDeCompra;
      this.bodyComprobanteComercial.FechaEntregaActual = json.fechaEntregaActual;
      this.bodyComprobanteComercial.FechaCancelacionOrdenDeCompra = json.fechaCancelacionOrdenDeCompra;
      this.bodyComprobanteComercial.ImporteConLetra = json.importeConLetra;
      this.bodyComprobanteComercial.NumeroDePedidoHerdez = json.numeroDePedidoHerdez;
      this.bodyComprobanteComercial.FechaPedidoHerdez = json.fechaPedidoHerdez;
      this.bodyComprobanteComercial.FechaAvisoAnticipadoDeEmbarque = json.fechaAvisoAnticipadoDeEmbarque;
      this.bodyComprobanteComercial.EfectoDelComprobante = json.efectoDelComprobante;
      this.bodyComprobanteComercial.ReferenciaBancaria1 = json.referenciaBancaria1;
      this.bodyComprobanteComercial.ReferenciaBancaria2 = json.referenciaBancaria2;
      this.bodyComprobanteComercial.ReferenciaBancaria3 = json.referenciaBancaria3;
      this.bodyComprobanteComercial.ReferenciaBancaria4 = json.referenciaBancaria4;
      this.bodyComprobanteComercial.ReferenciaBancaria5 = json.referenciaBancaria5;
      this.bodyComprobanteComercial.ReferenciaBancaria6 = json.referenciaBancaria6;
      this.bodyComprobanteComercial.Vendedor = json.vendedor;
      this.bodyComprobanteComercial.PesoNetoTotal = json.pesoNetoTotal;
      this.bodyComprobanteComercial.PesoLogisticoTotal = json.pesoLogisticoTotal;
      this.bodyComprobanteComercial.PDFLeyenda1 = json.pDFLeyenda1;
      this.bodyComprobanteComercial.PDFLeyenda2 = json.pDFLeyenda2;
      this.bodyComprobanteComercial.PDFLeyenda3 = json.pDFLeyenda3;
      this.bodyComprobanteComercial.CabeceroPDF = json.cabeceroPDF;
      this.bodyComprobanteComercial.Reservado5 = json.reservado5;
      this.bodyComprobanteComercial.lstMdfDate = json.lstMdfDate;
      this.bodyComprobanteComercial.origenProcess = json.origenProcess;
      this.bodyComprobanteComercial.ipHostClient = json.ipHostClient;
      this.bodyComprobanteComercial.hostNameClient = json.hostNameClient;
      this.bodyComprobanteComercial.userNameClient = json.userNameClient;


    }

  }
  getDataForms(segmento: any) {
    //invoiceHeaderObjectId es null en Estado documento (en espera, )
    var body = {
      Segmento: segmento,
      InvObjectId: this.invoiceHeaderObjectId,
      ConceptoComercialObjectId: this.bodyConceptoComercial.objectId
    }

    this.pservice.ObtenerConsultaModales(body).subscribe(data => {

      this.listaModales = data['result'];
      this.listaBusquedaModales = JSON.parse(JSON.stringify(data['result']))
    },
      error => {
        console.log(error)
      })

  }


  getPermits(segmento) {
    var claveDocs = this.listaDocumentoClave[0].contenidoDelCampo.split(',');
    //JSON.parse(JSON.stringify(data))
    var local = localStorage.getItem("auth").split(",")
    var userObjectId = local[0].split(":")

    //clave docs esta almacenando mal lo que se debe enviar, tal vez se necesia un foreach
    var body = {
      UserObjectId: parseInt(userObjectId[1]),
      GroupObjectId: 0,
      NombreSegmento: segmento,
      ClaveDocumento: claveDocs
    }

    this.pservice.ObtenerPermisosCampos(body).subscribe(data => {
      this.listaPermisosCampos = data;
    }, error => {
      console.log(error)

    })

  }



  showTabs(pestana) {
    this.buscar = ""
    this.cPestana = pestana;
    if (pestana == 1) {
      if ((this.selectedRow['Clave Tipo Documento'] == 'F' && this.listaSegmentosHab[0].aplicaFactura == true) || (this.selectedRow['Clave Tipo Documento'] == 'D' && this.listaSegmentosHab[0].aplicaDevolucion == true) || (this.selectedRow['Clave Tipo Documento'] == 'A' && this.listaSegmentosHab[0].aplicaNotaCredito == true) || (this.selectedRow['Clave Tipo Documento'] == 'C' && this.listaSegmentosHab[0].aplicaNotaCargo == true) || (this.selectedRow['Clave Tipo Documento'] == 'P' && this.listaSegmentosHab[0].aplicaPago == true)){
        this.selectTab(1, 'Comprobante', 'Comprobante Comercial', "Comprobante");
        this.nombreSegmento = "Comprobante";
        //if(this.listaSegmentosHab[0].aplicafactura )
        this.getDataForms('Comprobante')
        this.tabSeleccionado = "Comprobante Comercial"
      }else if ((this.selectedRow['Clave Tipo Documento'] == 'F' && this.listaSegmentosHab[1].aplicaFactura == true) || (this.selectedRow['Clave Tipo Documento'] == 'D' && this.listaSegmentosHab[1].aplicaDevolucion == true) || (this.selectedRow['Clave Tipo Documento'] == 'A' && this.listaSegmentosHab[1].aplicaNotaCredito == true) || (this.selectedRow['Clave Tipo Documento'] == 'C' && this.listaSegmentosHab[1].aplicaNotaCargo == true) || (this.selectedRow['Clave Tipo Documento'] == 'P' && this.listaSegmentosHab[1].aplicaPago == true)){
        this.selectTab(2, 'Comprobante', 'Comprobante Fiscal', "Comprobante");
        this.nombreSegmento = "Comprobante";
        //if(this.listaSegmentosHab[0].aplicafactura )
        this.getDataForms('Comprobante')
        this.tabSeleccionado = "Comprobante Fiscal"
      }else{
        this.toastr.Error("Opcion Inhabilitada")
      }

      //this.getPermits("Comprobante")
    } else if (pestana == 2) {
      if ((this.selectedRow['Clave Tipo Documento'] == 'F' && this.listaSegmentosHab[2].aplicaFactura == true) || (this.selectedRow['Clave Tipo Documento'] == 'D' && this.listaSegmentosHab[2].aplicaDevolucion == true) || (this.selectedRow['Clave Tipo Documento'] == 'A' && this.listaSegmentosHab[2].aplicaNotaCredito == true) || (this.selectedRow['Clave Tipo Documento'] == 'C' && this.listaSegmentosHab[2].aplicaNotaCargo == true) || (this.selectedRow['Clave Tipo Documento'] == 'P' && this.listaSegmentosHab[2].aplicaPago == true)){
        this.selectTab(3, 'CFDI Relacionado', 'Cfdi Relacionados', "Relacionados");
        this.nombreSegmento = "Cfdi Relacionados";
        this.getDataForms('Relacionados')
        this.tabSeleccionado = "Cfdi Relacionados"
        // this.getPermits("Relacionados")
      }else{
        this.toastr.Error("Opcion Inhabilitada")
      }

    } else if (pestana == 3) {
      if((this.selectedRow['Clave Tipo Documento'] == 'F' && this.listaSegmentosHab[3].aplicaFactura == true) || (this.selectedRow['Clave Tipo Documento'] == 'D' && this.listaSegmentosHab[3].aplicaDevolucion == true) || (this.selectedRow['Clave Tipo Documento'] == 'A' && this.listaSegmentosHab[3].aplicaNotaCredito == true) || (this.selectedRow['Clave Tipo Documento'] == 'C' && this.listaSegmentosHab[3].aplicaNotaCargo == true) || (this.selectedRow['Clave Tipo Documento'] == 'P' && this.listaSegmentosHab[3].aplicaPago == true)){
        this.selectTab(5, 'Emisor', 'Emisor CCE', "Emisor");
        this.nombreSegmento = "Emisor";
        this.getDataForms('Emisor')
        this.tabSeleccionado = "Emisor CCE"
        // this.getPermits("Emisor")
      }else if ((this.selectedRow['Clave Tipo Documento'] == 'F' && this.listaSegmentosHab[4].aplicaFactura == true) || (this.selectedRow['Clave Tipo Documento'] == 'D' && this.listaSegmentosHab[4].aplicaDevolucion == true) || (this.selectedRow['Clave Tipo Documento'] == 'A' && this.listaSegmentosHab[4].aplicaNotaCredito == true) || (this.selectedRow['Clave Tipo Documento'] == 'C' && this.listaSegmentosHab[4].aplicaNotaCargo == true) || (this.selectedRow['Clave Tipo Documento'] == 'P' && this.listaSegmentosHab[4].aplicaPago == true)){
        this.selectTab(6, 'Emisor', 'Emisor Comercial', "Emisor");
        this.nombreSegmento = "Emisor";
        this.getDataForms('Emisor')
        this.tabSeleccionado = "Emisor Comercial"
        // this.getPermits("Emisor")
      }else if ((this.selectedRow['Clave Tipo Documento'] == 'F' && this.listaSegmentosHab[5].aplicaFactura == true) || (this.selectedRow['Clave Tipo Documento'] == 'D' && this.listaSegmentosHab[5].aplicaDevolucion == true) || (this.selectedRow['Clave Tipo Documento'] == 'A' && this.listaSegmentosHab[5].aplicaNotaCredito == true) || (this.selectedRow['Clave Tipo Documento'] == 'C' && this.listaSegmentosHab[5].aplicaNotaCargo == true) || (this.selectedRow['Clave Tipo Documento'] == 'P' && this.listaSegmentosHab[5].aplicaPago == true)){
        this.selectTab(7, 'Emisor', 'Emisor Expedicion', "Emisor");
        this.nombreSegmento = "Emisor";
        this.getDataForms('Emisor')
        this.tabSeleccionado = "Emisor Expedicion"
        // this.getPermits("Emisor")
      }else if ((this.selectedRow['Clave Tipo Documento'] == 'F' && this.listaSegmentosHab[6].aplicaFactura == true) || (this.selectedRow['Clave Tipo Documento'] == 'D' && this.listaSegmentosHab[6].aplicaDevolucion == true) || (this.selectedRow['Clave Tipo Documento'] == 'A' && this.listaSegmentosHab[6].aplicaNotaCredito == true) || (this.selectedRow['Clave Tipo Documento'] == 'C' && this.listaSegmentosHab[6].aplicaNotaCargo == true) || (this.selectedRow['Clave Tipo Documento'] == 'P' && this.listaSegmentosHab[6].aplicaPago == true)){
        this.selectTab(8, 'Emisor', 'Emisor Fiscal', "Emisor");
        this.nombreSegmento = "Emisor";
        this.getDataForms('Emisor')
        this.tabSeleccionado = "Emisor Fiscal"
        // this.getPermits("Emisor")
      }else{
        this.toastr.Error("Opcion Inhabilitada")
      }

    } else if (pestana == 4) {
      if ((this.selectedRow['Clave Tipo Documento'] == 'F' && this.listaSegmentosHab[7].aplicaFactura == true) || (this.selectedRow['Clave Tipo Documento'] == 'D' && this.listaSegmentosHab[7].aplicaDevolucion == true) || (this.selectedRow['Clave Tipo Documento'] == 'A' && this.listaSegmentosHab[7].aplicaNotaCredito == true) || (this.selectedRow['Clave Tipo Documento'] == 'C' && this.listaSegmentosHab[7].aplicaNotaCargo == true) || (this.selectedRow['Clave Tipo Documento'] == 'P' && this.listaSegmentosHab[7].aplicaPago == true)){
        this.selectTab(9, 'Conceptos', 'Concepto Comercial', "Conceptos");
        this.nombreSegmento = "Concepto";
        this.getDataForms('Concepto')
        this.tabSeleccionado = "Concepto Comercial"
        // this.getPermits("Concepto")
      }else if((this.selectedRow['Clave Tipo Documento'] == 'F' && this.listaSegmentosHab[8].aplicaFactura == true) || (this.selectedRow['Clave Tipo Documento'] == 'D' && this.listaSegmentosHab[8].aplicaDevolucion == true) || (this.selectedRow['Clave Tipo Documento'] == 'A' && this.listaSegmentosHab[8].aplicaNotaCredito == true) || (this.selectedRow['Clave Tipo Documento'] == 'C' && this.listaSegmentosHab[8].aplicaNotaCargo == true) || (this.selectedRow['Clave Tipo Documento'] == 'P' && this.listaSegmentosHab[8].aplicaPago == true)){
        this.selectTab(10, 'Conceptos', 'Concepto Fiscal', "Conceptos");
        this.nombreSegmento = "Concepto";
        this.getDataForms('Concepto')
        this.tabSeleccionado = "Concepto Fiscal"
        // this.getPermits("Concepto")
      }else if((this.selectedRow['Clave Tipo Documento'] == 'F' && this.listaSegmentosHab[8].aplicaFactura == true) || (this.selectedRow['Clave Tipo Documento'] == 'D' && this.listaSegmentosHab[8].aplicaDevolucion == true) || (this.selectedRow['Clave Tipo Documento'] == 'A' && this.listaSegmentosHab[8].aplicaNotaCredito == true) || (this.selectedRow['Clave Tipo Documento'] == 'C' && this.listaSegmentosHab[8].aplicaNotaCargo == true) || (this.selectedRow['Clave Tipo Documento'] == 'P' && this.listaSegmentosHab[8].aplicaPago == true)){
        this.selectTab(12, 'Conceptos', 'Traslado Concepto Comercial', "Conceptos");
        this.nombreSegmento = "Concepto";
        this.getDataForms('Concepto')
        this.tabSeleccionado = "Traslado Concepto Comercial"
        // this.getPermits("Concepto")
      }else if((this.selectedRow['Clave Tipo Documento'] == 'F' && this.listaSegmentosHab[8].aplicaFactura == true) || (this.selectedRow['Clave Tipo Documento'] == 'D' && this.listaSegmentosHab[8].aplicaDevolucion == true) || (this.selectedRow['Clave Tipo Documento'] == 'A' && this.listaSegmentosHab[8].aplicaNotaCredito == true) || (this.selectedRow['Clave Tipo Documento'] == 'C' && this.listaSegmentosHab[8].aplicaNotaCargo == true) || (this.selectedRow['Clave Tipo Documento'] == 'P' && this.listaSegmentosHab[8].aplicaPago == true)){
        this.selectTab(13, 'Conceptos', 'Traslado Concepto Fiscal', "Conceptos");
        this.nombreSegmento = "Concepto";
        this.getDataForms('Concepto')
        this.tabSeleccionado = "Traslado Concepto Fiscal"
        // this.getPermits("Concepto")
      }else if((this.selectedRow['Clave Tipo Documento'] == 'F' && this.listaSegmentosHab[19].aplicaFactura == true) || (this.selectedRow['Clave Tipo Documento'] == 'D' && this.listaSegmentosHab[19].aplicaDevolucion == true) || (this.selectedRow['Clave Tipo Documento'] == 'A' && this.listaSegmentosHab[19].aplicaNotaCredito == true) || (this.selectedRow['Clave Tipo Documento'] == 'C' && this.listaSegmentosHab[18].aplicaNotaCargo == true) || (this.selectedRow['Clave Tipo Documento'] == 'P' && this.listaSegmentosHab[19].aplicaPago == true)){
        this.selectTab(22, 'Conceptos', 'Impuesto Trasladado Tercero', "Conceptos");
        this.nombreSegmento = "Concepto";
        this.getDataForms('Concepto')
        this.tabSeleccionado = "Impuesto Trasladado Tercero"
        // this.getPermits("Impuesto")
      }else{
        this.toastr.Error("Opcion Inhabilitada")
      }

    } else if (pestana == 5) {
      if ((this.selectedRow['Clave Tipo Documento'] == 'F' && this.listaSegmentosHab[11].aplicaFactura == true) || (this.selectedRow['Clave Tipo Documento'] == 'D' && this.listaSegmentosHab[11].aplicaDevolucion == true) || (this.selectedRow['Clave Tipo Documento'] == 'A' && this.listaSegmentosHab[11].aplicaNotaCredito == true) || (this.selectedRow['Clave Tipo Documento'] == 'C' && this.listaSegmentosHab[11].aplicaNotaCargo == true) || (this.selectedRow['Clave Tipo Documento'] == 'P' && this.listaSegmentosHab[11].aplicaPago == true)){
        this.selectTab(14, 'Receptor', 'Receptor Comercial', "Receptor");
        this.nombreSegmento = "Receptor";
        this.getDataForms('Receptor')
        this.tabSeleccionado = "Receptor Comercial"
        // this.getPermits("Receptor")
      }else if((this.selectedRow['Clave Tipo Documento'] == 'F' && this.listaSegmentosHab[12].aplicaFactura == true) || (this.selectedRow['Clave Tipo Documento'] == 'D' && this.listaSegmentosHab[12].aplicaDevolucion == true) || (this.selectedRow['Clave Tipo Documento'] == 'A' && this.listaSegmentosHab[12].aplicaNotaCredito == true) || (this.selectedRow['Clave Tipo Documento'] == 'C' && this.listaSegmentosHab[12].aplicaNotaCargo == true) || (this.selectedRow['Clave Tipo Documento'] == 'P' && this.listaSegmentosHab[12].aplicaPago == true)){
        this.selectTab(15, 'Receptor', 'Receptor Entrega', "Receptor");
        this.nombreSegmento = "Receptor";
        this.getDataForms('Receptor')
        this.tabSeleccionado = "Receptor Entrega"
        // this.getPermits("Receptor")
      }else if((this.selectedRow['Clave Tipo Documento'] == 'F' && this.listaSegmentosHab[13].aplicaFactura == true) || (this.selectedRow['Clave Tipo Documento'] == 'D' && this.listaSegmentosHab[13].aplicaDevolucion == true) || (this.selectedRow['Clave Tipo Documento'] == 'A' && this.listaSegmentosHab[13].aplicaNotaCredito == true) || (this.selectedRow['Clave Tipo Documento'] == 'C' && this.listaSegmentosHab[13].aplicaNotaCargo == true) || (this.selectedRow['Clave Tipo Documento'] == 'P' && this.listaSegmentosHab[13].aplicaPago == true)){
        this.selectTab(16, 'Receptor', 'Receptor Fiscal', "Receptor");
        this.nombreSegmento = "Receptor";
        this.getDataForms('Receptor')
        this.tabSeleccionado = "Receptor Fiscal"
        // this.getPermits("Receptor")
      }else{
        this.toastr.Error("Opcion Inhabilitada")
      }

    } else if (pestana == 6) {
      if ((this.selectedRow['Clave Tipo Documento'] == 'F' && this.listaSegmentosHab[14].aplicaFactura == true) || (this.selectedRow['Clave Tipo Documento'] == 'D' && this.listaSegmentosHab[14].aplicaDevolucion == true) || (this.selectedRow['Clave Tipo Documento'] == 'A' && this.listaSegmentosHab[14].aplicaNotaCredito == true) || (this.selectedRow['Clave Tipo Documento'] == 'C' && this.listaSegmentosHab[14].aplicaNotaCargo == true) || (this.selectedRow['Clave Tipo Documento'] == 'P' && this.listaSegmentosHab[14].aplicaPago == true)){
        this.selectTab(17, 'Complemento Conceptos', 'Informacion Aduanera Tercero', "CCH");
        this.nombreSegmento = "Complemento Concepto (CCH)";
        this.getDataForms('CCH')
        this.tabSeleccionado = "Informacion Aduanera Tercero"
        // this.getPermits("CCH")
      }else if ((this.selectedRow['Clave Tipo Documento'] == 'F' && this.listaSegmentosHab[15].aplicaFactura == true) || (this.selectedRow['Clave Tipo Documento'] == 'D' && this.listaSegmentosHab[15].aplicaDevolucion == true) || (this.selectedRow['Clave Tipo Documento'] == 'A' && this.listaSegmentosHab[15].aplicaNotaCredito == true) || (this.selectedRow['Clave Tipo Documento'] == 'C' && this.listaSegmentosHab[15].aplicaNotaCargo == true) || (this.selectedRow['Clave Tipo Documento'] == 'P' && this.listaSegmentosHab[15].aplicaPago == true)){
        this.selectTab(18, 'Complemento Conceptos', 'Informacion Fiscal Tercero', "CCH");
        this.nombreSegmento = "Complemento Concepto (CCH)";
        this.getDataForms('CCH')
        this.tabSeleccionado = "Informacion Fiscal Tercero"
        // this.getPermits("CCH")
      }else if ((this.selectedRow['Clave Tipo Documento'] == 'F' && this.listaSegmentosHab[16].aplicaFactura == true) || (this.selectedRow['Clave Tipo Documento'] == 'D' && this.listaSegmentosHab[16].aplicaDevolucion == true) || (this.selectedRow['Clave Tipo Documento'] == 'A' && this.listaSegmentosHab[16].aplicaNotaCredito == true) || (this.selectedRow['Clave Tipo Documento'] == 'C' && this.listaSegmentosHab[16].aplicaNotaCargo == true) || (this.selectedRow['Clave Tipo Documento'] == 'P' && this.listaSegmentosHab[16].aplicaPago == true)){
        this.selectTab(19, 'Complemento Conceptos', 'Parte Tercero', "CCH");
        this.nombreSegmento = "Complemento Concepto (CCH)";
        this.getDataForms('CCH')
        this.tabSeleccionado = "Parte Tercero"
        // this.getPermits("CCH")
      }else if ((this.selectedRow['Clave Tipo Documento'] == 'F' && this.listaSegmentosHab[17].aplicaFactura == true) || (this.selectedRow['Clave Tipo Documento'] == 'D' && this.listaSegmentosHab[17].aplicaDevolucion == true) || (this.selectedRow['Clave Tipo Documento'] == 'A' && this.listaSegmentosHab[17].aplicaNotaCredito == true) || (this.selectedRow['Clave Tipo Documento'] == 'C' && this.listaSegmentosHab[17].aplicaNotaCargo == true) || (this.selectedRow['Clave Tipo Documento'] == 'P' && this.listaSegmentosHab[17].aplicaPago == true)){
        this.selectTab(20, 'Complemento Conceptos', 'Por Cuenta de Terceros Fiscal', "CCH");
        this.nombreSegmento = "Complemento Concepto (CCH)";
        this.getDataForms('CCH')
        this.tabSeleccionado = "Por Cuenta de Terceros Fiscal"
        // this.getPermits("CCH")
      }else{
        this.toastr.Error("Opcion Inhabilitada")
      }

    } else if (pestana == 7) {
      if((this.selectedRow['Clave Tipo Documento'] == 'F' && this.listaSegmentosHab[18].aplicaFactura == true) || (this.selectedRow['Clave Tipo Documento'] == 'D' && this.listaSegmentosHab[18].aplicaDevolucion == true) || (this.selectedRow['Clave Tipo Documento'] == 'A' && this.listaSegmentosHab[18].aplicaNotaCredito == true) || (this.selectedRow['Clave Tipo Documento'] == 'C' && this.listaSegmentosHab[18].aplicaNotaCargo == true) || (this.selectedRow['Clave Tipo Documento'] == 'P' && this.listaSegmentosHab[18].aplicaPago == true)){
        this.selectTab(21, 'Impuestos', 'Impuesto Fiscal', "Impuesto");
        this.nombreSegmento = "Impuesto";
        this.getDataForms('Impuesto')
        this.tabSeleccionado = "Impuesto Fiscal"
        // this.getPermits("Impuesto")
      }else if((this.selectedRow['Clave Tipo Documento'] == 'F' && this.listaSegmentosHab[35].aplicaFactura == true) || (this.selectedRow['Clave Tipo Documento'] == 'D' && this.listaSegmentosHab[35].aplicaDevolucion == true) || (this.selectedRow['Clave Tipo Documento'] == 'A' && this.listaSegmentosHab[35].aplicaNotaCredito == true) || (this.selectedRow['Clave Tipo Documento'] == 'C' && this.listaSegmentosHab[35].aplicaNotaCargo == true) || (this.selectedRow['Clave Tipo Documento'] == 'P' && this.listaSegmentosHab[35].aplicaPago == true)){
        this.selectTab(23, 'Impuestos', 'Impuesto Trasladado Fiscal', "Impuesto");
        this.nombreSegmento = "Impuesto";
        this.getDataForms('Impuesto')
        this.tabSeleccionado = "Impuesto Trasladado Fiscal"
        // this.getPermits("Impuesto")
      }else{
        this.toastr.Error("Opcion Inhabilitada")
      }

    } else if (pestana == 8) {
      if ((this.selectedRow['Clave Tipo Documento'] == 'F' && this.listaSegmentosHab[20].aplicaFactura == true) || (this.selectedRow['Clave Tipo Documento'] == 'D' && this.listaSegmentosHab[20].aplicaDevolucion == true) || (this.selectedRow['Clave Tipo Documento'] == 'A' && this.listaSegmentosHab[20].aplicaNotaCredito == true) || (this.selectedRow['Clave Tipo Documento'] == 'C' && this.listaSegmentosHab[20].aplicaNotaCargo == true) || (this.selectedRow['Clave Tipo Documento'] == 'P' && this.listaSegmentosHab[20].aplicaPago == true)){
        this.selectTab(24, 'Complemento Exportacion', 'Comercio Exterior', "Exportacion");
        this.nombreSegmento = "Complemento CCE Exportacion";
        this.getDataForms('Exportacion')
        this.tabSeleccionado = "Comercio Exterior"
        // this.getPermits("Exportacion")
      }else if ((this.selectedRow['Clave Tipo Documento'] == 'F' && this.listaSegmentosHab[21].aplicaFactura == true) || (this.selectedRow['Clave Tipo Documento'] == 'D' && this.listaSegmentosHab[21].aplicaDevolucion == true) || (this.selectedRow['Clave Tipo Documento'] == 'A' && this.listaSegmentosHab[21].aplicaNotaCredito == true) || (this.selectedRow['Clave Tipo Documento'] == 'C' && this.listaSegmentosHab[21].aplicaNotaCargo == true) || (this.selectedRow['Clave Tipo Documento'] == 'P' && this.listaSegmentosHab[21].aplicaPago == true)){
        this.selectTab(25, 'Complemento Exportacion', 'Destinatario CCE', "Exportacion");
        this.nombreSegmento = "Complemento CCE Exportacion";
        this.getDataForms('Exportacion')
        this.tabSeleccionado = "Destinatario CCE"
        // this.getPermits("Exportacion")
      }else if ((this.selectedRow['Clave Tipo Documento'] == 'F' && this.listaSegmentosHab[22].aplicaFactura == true) || (this.selectedRow['Clave Tipo Documento'] == 'D' && this.listaSegmentosHab[22].aplicaDevolucion == true) || (this.selectedRow['Clave Tipo Documento'] == 'A' && this.listaSegmentosHab[22].aplicaNotaCredito == true) || (this.selectedRow['Clave Tipo Documento'] == 'C' && this.listaSegmentosHab[22].aplicaNotaCargo == true) || (this.selectedRow['Clave Tipo Documento'] == 'P' && this.listaSegmentosHab[22].aplicaPago == true)){
        this.selectTab(26, 'Complemento Exportacion', 'Domicilio Destinatario CCE', "Exportacion");
        this.nombreSegmento = "Complemento CCE Exportacion";
        this.getDataForms('Exportacion')
        this.tabSeleccionado = "Domicilio Destinatario CCE"
        // this.getPermits("Exportacion")
      }else if ((this.selectedRow['Clave Tipo Documento'] == 'F' && this.listaSegmentosHab[23].aplicaFactura == true) || (this.selectedRow['Clave Tipo Documento'] == 'D' && this.listaSegmentosHab[23].aplicaDevolucion == true) || (this.selectedRow['Clave Tipo Documento'] == 'A' && this.listaSegmentosHab[23].aplicaNotaCredito == true) || (this.selectedRow['Clave Tipo Documento'] == 'C' && this.listaSegmentosHab[23].aplicaNotaCargo == true) || (this.selectedRow['Clave Tipo Documento'] == 'P' && this.listaSegmentosHab[23].aplicaPago == true)){
        this.selectTab(27, 'Complemento Exportacion', 'Domicilio Emisor CCE', "Exportacion");
        this.nombreSegmento = "Complemento CCE Exportacion";
        this.getDataForms('Exportacion')
        this.tabSeleccionado = "Domicilio Emisor CCE"
        // this.getPermits("Exportacion")
      }else if ((this.selectedRow['Clave Tipo Documento'] == 'F' && this.listaSegmentosHab[24].aplicaFactura == true) || (this.selectedRow['Clave Tipo Documento'] == 'D' && this.listaSegmentosHab[24].aplicaDevolucion == true) || (this.selectedRow['Clave Tipo Documento'] == 'A' && this.listaSegmentosHab[24].aplicaNotaCredito == true) || (this.selectedRow['Clave Tipo Documento'] == 'C' && this.listaSegmentosHab[24].aplicaNotaCargo == true) || (this.selectedRow['Clave Tipo Documento'] == 'P' && this.listaSegmentosHab[24].aplicaPago == true)){
        this.selectTab(28, 'Complemento Exportacion', 'Domicilio Receptor CCE', "Exportacion");
        this.nombreSegmento = "Complemento CCE Exportacion";
        this.getDataForms('Exportacion')
        this.tabSeleccionado = "Domicilio Receptor CCE"
        // this.getPermits("Exportacion")
      }else if ((this.selectedRow['Clave Tipo Documento'] == 'F' && this.listaSegmentosHab[25].aplicaFactura == true) || (this.selectedRow['Clave Tipo Documento'] == 'D' && this.listaSegmentosHab[25].aplicaDevolucion == true) || (this.selectedRow['Clave Tipo Documento'] == 'A' && this.listaSegmentosHab[25].aplicaNotaCredito == true) || (this.selectedRow['Clave Tipo Documento'] == 'C' && this.listaSegmentosHab[25].aplicaNotaCargo == true) || (this.selectedRow['Clave Tipo Documento'] == 'P' && this.listaSegmentosHab[25].aplicaPago == true)){
        this.selectTab(29, 'Complemento Exportacion', 'Propietario CCE', "Exportacion");
        this.nombreSegmento = "Complemento CCE Exportacion";
        this.getDataForms('Exportacion')
        this.tabSeleccionado = "Propietario CCE"
        // this.getPermits("Exportacion")
      }else if ((this.selectedRow['Clave Tipo Documento'] == 'F' && this.listaSegmentosHab[26].aplicaFactura == true) || (this.selectedRow['Clave Tipo Documento'] == 'D' && this.listaSegmentosHab[26].aplicaDevolucion == true) || (this.selectedRow['Clave Tipo Documento'] == 'A' && this.listaSegmentosHab[26].aplicaNotaCredito == true) || (this.selectedRow['Clave Tipo Documento'] == 'C' && this.listaSegmentosHab[26].aplicaNotaCargo == true) || (this.selectedRow['Clave Tipo Documento'] == 'P' && this.listaSegmentosHab[26].aplicaPago == true)){
        this.selectTab(30, 'Complemento Exportacion', 'Receptor CCE', "Exportacion");
        this.nombreSegmento = "Complemento CCE Exportacion";
        this.getDataForms('Exportacion')
        this.tabSeleccionado = "Receptor CCE"
        // this.getPermits("Exportacion")
      }else{
        this.toastr.Error("Opcion Inhabilitada")
      }

    } else if (pestana == 9) {
      if ((this.selectedRow['Clave Tipo Documento'] == 'F' && this.listaSegmentosHab[27].aplicaFactura == true) || (this.selectedRow['Clave Tipo Documento'] == 'D' && this.listaSegmentosHab[27].aplicaDevolucion == true) || (this.selectedRow['Clave Tipo Documento'] == 'A' && this.listaSegmentosHab[27].aplicaNotaCredito == true) || (this.selectedRow['Clave Tipo Documento'] == 'C' && this.listaSegmentosHab[27].aplicaNotaCargo == true) || (this.selectedRow['Clave Tipo Documento'] == 'P' && this.listaSegmentosHab[27].aplicaPago == true)){
        this.selectTab(31, 'Mercancía', 'Descripcion ESP CCE', "Mercancía");
        this.nombreSegmento = "Mercancía";
        this.getDataForms('Mercancía')
        this.tabSeleccionado = "Descripcion ESP CCE"
        // this.getPermits("Mercancía")
      }else if ((this.selectedRow['Clave Tipo Documento'] == 'F' && this.listaSegmentosHab[28].aplicaFactura == true) || (this.selectedRow['Clave Tipo Documento'] == 'D' && this.listaSegmentosHab[28].aplicaDevolucion == true) || (this.selectedRow['Clave Tipo Documento'] == 'A' && this.listaSegmentosHab[28].aplicaNotaCredito == true) || (this.selectedRow['Clave Tipo Documento'] == 'C' && this.listaSegmentosHab[28].aplicaNotaCargo == true) || (this.selectedRow['Clave Tipo Documento'] == 'P' && this.listaSegmentosHab[28].aplicaPago == true)){
        this.selectTab(32, 'Mercancía', 'Leyendas CCE', "Mercancía");
        this.nombreSegmento = "Mercancía";
        this.getDataForms('Mercancía')
        this.tabSeleccionado = "Leyendas CCE"
        // this.getPermits("Mercancía")
      }else if ((this.selectedRow['Clave Tipo Documento'] == 'F' && this.listaSegmentosHab[29].aplicaFactura == true) || (this.selectedRow['Clave Tipo Documento'] == 'D' && this.listaSegmentosHab[29].aplicaDevolucion == true) || (this.selectedRow['Clave Tipo Documento'] == 'A' && this.listaSegmentosHab[29].aplicaNotaCredito == true) || (this.selectedRow['Clave Tipo Documento'] == 'C' && this.listaSegmentosHab[29].aplicaNotaCargo == true) || (this.selectedRow['Clave Tipo Documento'] == 'P' && this.listaSegmentosHab[29].aplicaPago == true)){
        this.selectTab(33, 'Mercancía', 'Mercancia CCE', "Mercancía");
        this.nombreSegmento = "Mercancía";
        this.getDataForms('Mercancía')
        this.tabSeleccionado = "Mercancia CCE"
        // this.getPermits("Mercancía")
      }else{
        this.toastr.Error("Opcion Inhabilitada")
      }

    } else if (pestana == 10) {
      if ((this.selectedRow['Clave Tipo Documento'] == 'F' && this.listaSegmentosHab[30].aplicaFactura == true) || (this.selectedRow['Clave Tipo Documento'] == 'D' && this.listaSegmentosHab[30].aplicaDevolucion == true) || (this.selectedRow['Clave Tipo Documento'] == 'A' && this.listaSegmentosHab[30].aplicaNotaCredito == true) || (this.selectedRow['Clave Tipo Documento'] == 'C' && this.listaSegmentosHab[30].aplicaNotaCargo == true) || (this.selectedRow['Clave Tipo Documento'] == 'P' && this.listaSegmentosHab[30].aplicaPago == true)){
        this.selectTab(34, 'Complemento Leyendas Fiscales', 'Leyenda', "Fiscales");
        this.nombreSegmento = "Complemento Leyendas Fiscales";
        this.getDataForms('Fiscales')
        this.tabSeleccionado = "Leyenda"
        // this.getPermits("Fiscales")
      }else if ((this.selectedRow['Clave Tipo Documento'] == 'F' && this.listaSegmentosHab[31].aplicaFactura == true) || (this.selectedRow['Clave Tipo Documento'] == 'D' && this.listaSegmentosHab[31].aplicaDevolucion == true) || (this.selectedRow['Clave Tipo Documento'] == 'A' && this.listaSegmentosHab[31].aplicaNotaCredito == true) || (this.selectedRow['Clave Tipo Documento'] == 'C' && this.listaSegmentosHab[31].aplicaNotaCargo == true) || (this.selectedRow['Clave Tipo Documento'] == 'P' && this.listaSegmentosHab[31].aplicaPago == true)){
        this.selectTab(35, 'Complemento Leyendas Fiscales', 'Leyendas Fiscales', "Fiscales");
        this.nombreSegmento = "Complemento Leyendas Fiscales";
        this.getDataForms('Fiscales')
        this.tabSeleccionado = "Leyendas Fiscales"
        // this.getPermits("Fiscales")
      }else{
        this.toastr.Error("Opcion Inhabilitada")
      }

    } else if (pestana == 11) {
      if ((this.selectedRow['Clave Tipo Documento'] == 'F' && this.listaSegmentosHab[32].aplicaFactura == true) || (this.selectedRow['Clave Tipo Documento'] == 'D' && this.listaSegmentosHab[32].aplicaDevolucion == true) || (this.selectedRow['Clave Tipo Documento'] == 'A' && this.listaSegmentosHab[32].aplicaNotaCredito == true) || (this.selectedRow['Clave Tipo Documento'] == 'C' && this.listaSegmentosHab[32].aplicaNotaCargo == true) || (this.selectedRow['Clave Tipo Documento'] == 'P' && this.listaSegmentosHab[32].aplicaPago == true)){
        this.selectTab(36, 'Complemento Addenda', 'Addenda', "Addenda");
        this.nombreSegmento = "Complemento Addenda";
        this.getDataForms('Addenda')
        this.tabSeleccionado = "Addenda"
        // this.getPermits("Addenda")
      }else{
        this.toastr.Error("Opcion Inhabilitada")
      }

    } else if (pestana == 12) {
      if ((this.selectedRow['Clave Tipo Documento'] == 'F' && this.listaSegmentosHab[33].aplicaFactura == true) || (this.selectedRow['Clave Tipo Documento'] == 'D' && this.listaSegmentosHab[33].aplicaDevolucion == true) || (this.selectedRow['Clave Tipo Documento'] == 'A' && this.listaSegmentosHab[33].aplicaNotaCredito == true) || (this.selectedRow['Clave Tipo Documento'] == 'C' && this.listaSegmentosHab[33].aplicaNotaCargo == true) || (this.selectedRow['Clave Tipo Documento'] == 'P' && this.listaSegmentosHab[33].aplicaPago == true)){
        this.selectTab(37, 'Condiciones de Procesamiento', 'Servicios Distribucion', "Procesamiento");
        this.nombreSegmento = "Condiciones de Procesamiento";
        this.getDataForms('Procesamiento')
        this.tabSeleccionado = "Servicios Distribucion"
        // this.getPermits("Procesamiento")
      }else if ((this.selectedRow['Clave Tipo Documento'] == 'F' && this.listaSegmentosHab[34].aplicaFactura == true) || (this.selectedRow['Clave Tipo Documento'] == 'D' && this.listaSegmentosHab[34].aplicaDevolucion == true) || (this.selectedRow['Clave Tipo Documento'] == 'A' && this.listaSegmentosHab[34].aplicaNotaCredito == true) || (this.selectedRow['Clave Tipo Documento'] == 'C' && this.listaSegmentosHab[34].aplicaNotaCargo == true) || (this.selectedRow['Clave Tipo Documento'] == 'P' && this.listaSegmentosHab[34].aplicaPago == true)){
        this.selectTab(38, 'Condiciones de Procesamiento', 'Servicios PAC', "Procesamiento");
        this.nombreSegmento = "Condiciones de Procesamiento";
        this.getDataForms('Procesamiento')
        this.tabSeleccionado = "Servicios PAC"
        // this.getPermits("Procesamiento")
      }else{
        this.toastr.Error("Opcion Inhabilitada")
      }

    }

  }


  repeater(direc, tab) {
    if (direc == "Derecha") this.contRepeater++;
    else this.contRepeater--;

    this.listaDF = this.listaContenidoDF[0][this.contRepeater]
    if (tab == "CompComercial") this.bodyComprobanteComercial = this.listaContenido[0][this.contRepeater];
    else if (tab == "CompFiscal") this.bodyComprobanteFiscal = this.listaContenido[1][this.contRepeater];
    else if (tab == "CfdiRel") this.bodyCfdiRelacionados = this.listaContenido[0][this.contRepeater]
    else if (tab == "EmisorCCE") this.bodyEmisorCCE = this.listaContenido[0][this.contRepeater]
    else if (tab == "EmisorComer") this.bodyEmisorComercial = this.listaContenido[1][this.contRepeater]
    else if (tab == "EmisorExpe") this.bodyEmisorExpedicion = this.listaContenido[2][this.contRepeater]
    else if (tab == "EmisorFiscal") this.bodyEmisorFiscal = this.listaContenido[3][this.contRepeater]
    else if (tab == "ConcComercial") this.bodyConceptoComercial = this.listaContenido[0][this.contRepeater]
    else if (tab == "ConcFiscal") this.bodyConceptoFiscal = this.listaContenido[1][this.contRepeater]
    else if (tab == "TrasConcComercial") this.bodyTrasladoConceptoComercial = this.listaContenido[2][this.contRepeater]
    else if (tab == "TrasConcFiscal") this.bodyTrasladoConceptoFiscal = this.listaContenido[3][this.contRepeater]
    else if (tab == "ReceComercial") this.bodyReceptorComercial = this.listaContenido[0][this.contRepeater]
    else if (tab == "ReceEntrega") this.bodyReceptorEntrega = this.listaContenido[1][this.contRepeater]
    else if (tab == "ReceFiscal") this.bodyReceptorFiscal = this.listaContenido[2][this.contRepeater]
    else if (tab == "InfAduTercero") this.bodyInfAduaneraTercero = this.listaContenido[0][this.contRepeater]
    else if (tab == "InfFiscTercero") this.bodyInfFiscalTercero = this.listaContenido[1][this.contRepeater]
    else if (tab == "ParteTercero") this.bodyParteTercero = this.listaContenido[2][this.contRepeater]
    else if (tab == "PParteTercero") this.bodyPorParteDeTercerosFiscal = this.listaContenido[3][this.contRepeater]
    else if (tab == "ImpFiscal") this.bodyImpuestosFiscal = this.listaContenido[0][this.contRepeater]
    else if (tab == "ImpTrasTercero") this.bodyImpuestosTrasladoTercero = this.listaContenido[4][this.contRepeater]
    else if (tab == "ImpTrasFiscal") this.bodyImpuestosFiscal = this.listaContenido[2][this.contRepeater]
    else if (tab == "ComerExterior") this.bodyComercioExterior = this.listaContenido[0][this.contRepeater]
    else if (tab == "DestiCCE") this.bodyDestinatarioCCE = this.listaContenido[1][this.contRepeater]
    else if (tab == "DomDestiCCE") this.bodyDomicilioDestinatarioCCE = this.listaContenido[2][this.contRepeater]
    else if (tab == "DomEmiCCE") this.bodyDomicilioEmisorCCE = this.listaContenido[3][this.contRepeater]
    else if (tab == "DomReceCCE") this.bodyDomicilioReceptorCCE = this.listaContenido[4][this.contRepeater]
    else if (tab == "PropiCCE") this.bodyPropietarioCCE = this.listaContenido[5][this.contRepeater]
    else if (tab == "ReceCCE") this.bodyReceptorCCE = this.listaContenido[6][this.contRepeater]
    else if (tab == "DespESPCCE") this.bodyDescripcionESPCCE = this.listaContenido[0][this.contRepeater]
    else if (tab == "LeyeCCE") this.bodyLeyendasCCE = this.listaContenido[1][this.contRepeater]
    else if (tab == "MercaCCE") this.bodyMercanciasCCE = this.listaContenido[2][this.contRepeater]
    else if (tab == "Leyenda") this.bodyLeyenda = this.listaContenido[0][this.contRepeater]
    else if (tab == "LeyFiscales") this.bodyLeyendasFiscales = this.listaContenido[1][this.contRepeater]
    else if (tab == "Addenda") this.bodyAddenda = this.listaContenido[0][this.contRepeater]
    else if (tab == "ServDistribu") this.bodyServiciosDistribucion = this.listaContenido[2][this.contRepeater]
    else if (tab == "ServPAC") this.bodyServiciosPAC = this.listaContenido[2][this.contRepeater]
    this.busquedaModales = tab

  }

  selectTab(num, segmento, nombreTab, nombreSegmento) {


    var numReal = num;

    this.contRepeater = 0

    this.tabSeleccionado = nombreTab
    var claveDocs = this.listaDocumentoClave[0].contenidoDelCampo.split(',');
    var resultDOC;
    var body1 = {
      ObjectId: this.invoiceHeaderObjectId,
      ClaveDocumento: claveDocs
    }
    console.log("ClaveDocs");
    console.log(claveDocs);

    var local = localStorage.getItem("auth").split(",")
    var userObjectId = local[0].split(":")

    let bodyForms = {
      InvObjectId: this.invoiceHeaderObjectId,
      Segmento: nombreSegmento,
      ConceptoComercialObjectId: this.bodyConceptoComercial.objectId
    }


    this.spinnerService.show();
    this.pservice.ObtenerConsultaForms(bodyForms).subscribe(data => {
      this.listaContenido = data;
      this.comparacion =  JSON.parse(JSON.stringify(data))

      if (data == undefined){
        //data[0][0]['invoiceHeaderObjectId'] = this.invoiceHeaderObjectId
        //console.log(data[0][0]['invoiceHeaderObjectId']);
      }
      if (nombreSegmento == "Comprobante") {

        this.bodyComprobanteComercial = data[0][0] ? data[0][0] : ""
        this.bodyComprobanteFiscal = data[1][0] ? data[1][0] : ""
      }
      else if (nombreSegmento == "Relacionados") {
        this.bodyCfdiRelacionados = data[0][0] ? data[0][0] : ""
      }
      else if (nombreSegmento == "Emisor") {
        this.bodyEmisorCCE = data[0][0] ? data[0][0] : ""
        this.bodyEmisorComercial = data[1][0] ? data[1][0] : ""
        this.bodyEmisorExpedicion = data[2][0] ? data[2][0] : ""
        this.bodyEmisorFiscal = data[3][0] ? data[3][0] : ""
      }
      else if (nombreSegmento == "Conceptos") {
        this.bodyConceptoComercial = data[0][0] ? data[0][0] : ""
        this.bodyConceptoFiscal = data[1][0] ? data[1][0] : ""
        this.bodyTrasladoConceptoComercial = data[2][0] ? data[2][0] : ""
        this.bodyTrasladoConceptoFiscal = data[3][0] ? data[3][0] : ""
        this.bodyImpuestosTrasladoTercero = data[4][0] ? data[4][0] : ""
      }
      else if (nombreSegmento == "Receptor") {
        this.bodyReceptorComercial = data[0][0] ? data[0][0] : ""
        this.bodyReceptorEntrega = data[1][0] ? data[1][0] : ""
        this.bodyReceptorFiscal = data[2][0] ? data[2][0] : ""
      }
      else if (nombreSegmento == "CCH") {
        this.bodyInfAduaneraTercero = data[0][0] ? data[0][0] : ""
        this.bodyInfFiscalTercero = data[1][0] ? data[1][0] : ""
        this.bodyParteTercero = data[2][0] ? data[2][0] : ""
        this.bodyPorParteDeTercerosFiscal = data[3][0] ? data[3][0] : ""
      }
      else if (nombreSegmento == "Impuesto") {
        this.bodyImpuestosFiscal = data[0][0] ? data[0][0] : ""
        this.bodyImpuestosTrasladoFiscal = data[1][0] ? data[1][0] : ""
      }
      else if (nombreSegmento == "Exportacion") {
        this.bodyComercioExterior = data[0][0] ? data[0][0] : ""
        this.bodyDestinatarioCCE = data[1][0] ? data[1][0] : ""
        this.bodyDomicilioDestinatarioCCE = data[2][0] ? data[2][0] : ""
        this.bodyDomicilioEmisorCCE = data[3][0] ? data[3][0] : ""
        this.bodyDomicilioReceptorCCE = data[4][0] ? data[4][0] : ""
        this.bodyPropietarioCCE = data[5][0] ? data[5][0] : ""
        this.bodyReceptorCCE = data[6][0] ? data[6][0] : ""
      }
      else if (nombreSegmento == "Mercancía") {
        this.bodyDescripcionESPCCE = data[0][0] ? data[0][0] : ""
        this.bodyLeyendasCCE = data[1][0] ? data[1][0] : ""
        this.bodyMercanciasCCE = data[2][0] ? data[2][0] : ""
      }
      else if (nombreSegmento == "Fiscales") {
        this.bodyLeyenda = data[0][0] ? data[0][0] : ""
        this.bodyLeyendasFiscales = data[1][0] ? data[1][0] : ""
      }
      else if (nombreSegmento == "Addenda") {
        this.bodyAddenda = data[0][0] ? data[0][0] : ""
      }
      else if (nombreSegmento == "Procesamiento") {
        this.bodyServiciosDistribucion = data[0][0] ? data[0][0] : ""
        this.bodyServiciosPAC = data[1][0] ? data[1][0] : ""
      }
      this.pservice.ObtenerHabilitado(body1).subscribe(data => {
        resultDOC = data;
        this.habilitado = data
        console.log("Habilitado");
        console.log(this.habilitado);
        var body = {
          UserObjectId: parseInt(userObjectId[1]),
          GroupObjectId: 0,
          NombreSegmento: segmento,
          NombreTab: nombreTab,
          ClaveDocumento: resultDOC
        }

        this.pservice.ObtenerPermisosCampos(body).subscribe(data => {
          console.log("listapermisoscampos");
          console.log(this.listaPermisosCampos);
          console.log(this.listaPermisosCampos.length);
          this.listaPermisosCampos = data;
          this.pservice.ObtenerIconos(nombreSegmento).subscribe(data => {
            this.listaIconos = data;
            this.cTab = num;
            this.CTAB = num;
            this.openPopUp = 2;
            this.spinnerService.hide()
          })


        }, error => {
          console.log(error)
          this.spinnerService.hide()
        })
      })

    }, error => {
      console.log(error)
      this.spinnerService.hide()
    })

    if (num == 13){
      this.modalService.open('trasladoConceptoFiscalSegmento')
    }else if(num == 12){
      this.modalService.open('trasladoConceptoComercialSegmento')
    }else if(num == 22){
      this.modalService.open('impuestosTrasladosTerceroSegmento')
    }
    console.log("cfdi");
    if (this.comparacion.length == 0){
      console.log("sin datos");
    }else if (this.comparacion == null){
      console.log("null");
    }

    console.log(this.comparacion );


  }

  test(e: any) {
    clearTimeout(this.timefired);
    var x = this.spinnerService;
    var y = this.pservice;
    var z = this;
    var zd = this.selectedCPM;
    var obj = {
      EDI: this.selectedECPM,
      RG: this.selectedRG,
      PP: parseInt(this.selectedPP),
      texto: e.term
    };
    this.timefired = setTimeout(function () {
      x.show();
      y.ObtenerClienteCPM(obj).subscribe(
        (data) => {
          x.hide();
          z.listaCPM = data;
        },
        (error) => {
          x.hide();
          console.log("Error");

        }
      );
    }, 2000);
  }

  selectECPM(e: any) {
    var z = this;
    this.clienteEDIG = e.description
    this.spinnerService.show();

    this.pservice.ObtenerCombosRGyPP(this.selectedECPM).subscribe(
      (data) => {
        this.spinnerService.hide();
        z.cadena = data;
        this.listaPP = data['pp']
        this.xlistaPP = this.listaPP
        this.listaRG = data['rg']
        this.listaCC = data['cc']
        this.xlistaCC = this.listaCC

      },
      (error) => {
        this.spinnerService.hide();
        console.log("Error");
      }
    );
  }

  selectCPM(e: any) {

    // console.log(e)
    this.clienteComercial = e.description
    var z = this;
    this.pservice.ObtenerComboSucursal(this.selectedCPM).subscribe(
      (data) => {
        //console.log(data[0])
        this.listaSC = data[0];
      },
      (error) => {
        console.log("Error")
      }
    )
  }

  /*selectS(e: any) {

    // console.log(e)
    this.InvoiceSerie = e.description
    var z = this;
    this.pservice.ObtenerComboSucursal(this.InvoiceSerie).subscribe(
      (data) => {
        console.log(data[0])
        this.listaSC = data[0];
      },
      (error) => {
        console.log("Error")
      }
    )
  }*/

  changeRG(e: any) {
    if (e != undefined) this.grupoCliente = e.target.options[e.target.options.selectedIndex].text
    if (this.selectedRG == 0) {
      this.xlistaPP = this.listaPP;
      return;
    }
    this.xlistaPP = [];
    this.listaPP.forEach((e) => {
      if (e.referenceGroupObjectId == this.selectedRG) this.xlistaPP.push(e);
    });
  }


  changePP(e: any) {
    this.cadenaCliente = e.target.options[e.target.options.selectedIndex].text
    if (this.selectedPP == 0) {
      this.xlistaCC = this.listaCC;
      return;
    }
    this.xlistaCC = [];
    var selPP;
    var PP = {};
    for (let i = 0; i < this.listaPP.length; i++) {
      if (this.listaPP[i].objectId == this.selectedPP) {
        PP = this.listaPP[i];
        selPP = this.listaPP[i].channelCodeObjectId;

      }
    }

    var anterior = 0
    this.listaCC.forEach((e) => {
      if (e.objectID == selPP && e.objectID != anterior) {

        this.xlistaCC.push(e)
      }
      anterior = e.objectID
    })
    anterior = 0;

    this.selectedRG = PP["referenceGroupObjectId"];
    this.changeRG(undefined);
  }

  changeSC(e: any) {
    this.sucursalCliente = e.target.options[e.target.options.selectedIndex].text
  }

  changeCC(e: any) {
    this.canalCliente = e.target.options[e.target.options.selectedIndex].text
  }

  changeTD(e: any) {
    this.tipoDocCliente = e.target.options[e.target.options.selectedIndex].text
  }

  filtrar() {
    console.log(this.selectedECPM);
    this.DFObjectId = this.selectedECPM
    if (this.selectedECPM == NaN)
      this.selectedECPM = 0

    if (this.selectedCPM == NaN)
      this.selectedCPM = 0
    var ppCode;
    var ecpmCode;
    var rgCode;
    var ccCode;
    this.listaECPM.forEach((e) => {
      if (e.objectId == this.selectedECPM) ecpmCode = e.code
    })
    this.listaPP.forEach((e) => {
      if (e.objectId == this.selectedPP) ppCode = e.code
    })
    this.listaRG.forEach((e) => {
      if (e.objectId == this.selectedRG) rgCode = e.code
    })
    this.listaCC.forEach((e) => {
      if (e.objectID == this.selectedCC) ccCode = e.code
    })
    console.log("status process");
    console.log(this.listaSelectedSP);
    var body = {
      EDI: ecpmCode || "nada",
      CPM: this.selectedCPM || "nada",
      RG: rgCode || "nada",
      PP: ppCode || "nada",
      CC: ccCode || "nada",
      TD: parseInt(this.SelectedTD),
      BPN: this.selectedSC == 0 ? '0' : this.selectedSC,
      PDate1: this.PDate1 == "" ? '2000-01-01' : this.datePipe.transform(this.PDate1, "yyyy-MM-dd"),
      PDate2: this.PDate2 == "" ? '2022-01-01' : this.datePipe.transform(this.PDate2, "yyyy-MM-dd"),
      LSP: this.listaSelectedSP,
      IVS: this.Invoice || "nada",
      PN: this.PoNumber || "nada"

    }

    this.spinnerService.show()

    this.pservice.ObtenerListaGrid(body).subscribe(data => {
      console.log(data);
      this.listaGrid = data[0]
      this.listaBusquedaGrid = data[0]
      this.spinnerService.hide()
      this.filtrado = 1;
      this.periodoCliente = this.PDate1 + " - " + this.PDate2;
      if (data[0][0] != null){
        this.clienteDigital = data[0][0]['Código Cliente EDIG'] + " " + data[0][0]['Nombre Cliente EDIG']
      }
    },
      error => {
        this.spinnerService.hide()
        console.log("Error");
        //this.handleError(error);
      });
  }
  Buscar(){

    this.listaBusquedaGrid = []
    for (let i = 0; i < this.listaGrid.length; i++) {
      console.log(this.listaGrid[0]);
      for (let x = 0; x < Object.keys(this.listaGrid[0]).length; x++) {
        var propiedades = Object.keys(this.listaGrid[i])
        var propiedad = propiedades[x]
        var valor = String(this.listaGrid[i][propiedad]).toLocaleUpperCase()

        if(valor.indexOf(this.buscar.toLocaleUpperCase()) != -1){
          this.listaBusquedaGrid.push(this.listaGrid[i])
          break
        }

      }
    }

  }

  BuscarModal(tab){
    var n = 0
    if (tab == "ComprobanteComercial") n = 0
    else if (tab == "comprobanteFiscal") n =1
    else if (tab == "cfdiRelacionados") n = 0
    else if (tab == "emisorCCE") n = 0
    else if (tab == "emisorComercial") n = 1
    else if (tab == "emisorExpedicion") n = 2
    else if (tab == "emisorFiscal") n = 3
    else if (tab == "conceptoComercial") n = 0
    else if (tab == "conceptoFiscal") n = 1
    else if (tab == "trasladoConceptoComercial") n = 2
    else if (tab == "trasladoConceptoFiscal") n = 3
    else if (tab == "receptorComercial") n = 0
    else if (tab == "receptorEntrega") n = 1
    else if (tab == "receptorFiscal") n = 2
    else if (tab == "infAduaneraTercero") n = 0
    else if (tab == "infFiscalTercero") n = 1
    else if (tab == "parteTercero") n = 2
    else if (tab == "porParteDeTercerosFiscal") n = 3
    else if (tab == "impuestosFiscal") n = 0
    else if (tab == "impuestosTrasladoTercero") n = 4 //1 o 3?
    else if (tab == "impuestosTrasladoFiscal") n = 2
    else if (tab == "comercioExterior") n = 0
    else if (tab == "destinatarioCCE") n = 1
    else if (tab == "domicilioDestinatarioCCE") n = 2
    else if (tab == "domicilioEmisorCCE") n = 3
    else if (tab == "domicilioReceptorCCE") n = 4
    else if (tab == "propietarioCCE") n = 5
    else if (tab == "receptorCCE") n = 6
    else if (tab == "descripcionESPCCE") n = 0
    else if (tab == "leyendasCCE") n = 1
    else if (tab == "mercanciasCCE") n - 2
    else if (tab == "leyenda") n = 0
    else if (tab == "leyendasFiscales") n = 1
    else if (tab == "addenda") n = 0
    else if (tab == "serviciosDistribucion") n = 2 //0
    else if (tab == "serviciosPAC") n = 2 //1

    //falta asignar los numeros dependiendo del valor de busquedamodales
    this.listaBusquedaModales[n] = []

    for (let i = 0; i < this.listaModales[n].length; i++) {

      for (let x = 0; x < Object.keys(this.listaModales[n][0]).length; x++) {
        var propiedades = Object.keys(this.listaModales[n][i])
        var propiedad = propiedades[x]
        var valor = String(this.listaModales[n][i][propiedad]).toLocaleUpperCase()

        if(valor.indexOf(this.buscar.toLocaleUpperCase()) != -1){
          this.listaBusquedaModales[n].push(this.listaModales[n][i])
          break
        }

      }
    }
  }

  getCfdi(uuid){
    console.log(uuid);
    this.actual = "SatCfdiDocumento"
  }

  showpadre(e:any){
    this.actual = ""
    this.filtrar()
  }
}


