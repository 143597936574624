import { Component, OnInit } from "@angular/core";
import { PagosService } from "src/app/services/bi/pagos/pagos.service";
// import {} from "src/app/services/self/seguridad/auth.service";
import { Ng4LoadingSpinnerService } from "ng4-loading-spinner";
// import { error } from "console";

@Component({
  selector: "app-pod",
  templateUrl: "./pod.component.html",
  styleUrls: [
    "./pod.component.scss",
    "../../../../../../assets/icon/icofont/css/icofont.scss",
  ],
})
export class PodComponent implements OnInit {
  listaECPM: any = [];
  selectedECPM: any = 0;

  listaCPM: any = [];
  selectedCPM: any = 0;
  descripcion: any = "Primero";
  lCadena: any = "";

  cadena: any = {};

  selectedClientType: any = 1;
  selectedEstatus: any = 0;
  nombreArchivo: any = "";
  rutaArchivo: any = "";

  timefired: any = null;
  valorECPM: any = false;

  constructor(
    private pservice: PagosService,
    private spinnerService: Ng4LoadingSpinnerService
  ) {
    this.spinnerService.show();
    this.pservice.ObtenerCombos({}).subscribe(
      (data) => {
        this.spinnerService.hide();
        console.log(data);
        this.listaECPM = data;
      },
      (error) => {
        this.spinnerService.hide();
        console.log("Error");
      }
    );
  }

  ngOnInit() {}

  test2(e: any) {
    console.log(e);
    var z = this;
    z.lCadena = z.cadena.pp[0].code + "- " + z.cadena.pp[0].description;

    z.descripcion = e["description"];
  }
  test(e: any) {
    clearTimeout(this.timefired);
    var x = this.spinnerService;
    var y = this.pservice;
    var z = this;
    var zd = this.selectedCPM;
    var obj = {
      EDI: this.selectedECPM,
      texto: e.term,
    };
    this.timefired = setTimeout(function () {
      console.log(obj);
      x.show();
      y.ObtenerClienteCPM(obj).subscribe(
        (data) => {
          x.hide();
          // console.log(data);
          z.listaCPM = data;
        },
        (error) => {
          x.hide();
          console.log("Error");

          //this.handleError(error);
        }
      );
    }, 2000);
  }
  selectECPM() {
    var z = this;
    this.spinnerService.show();

    this.pservice.ObtenerCombosRGyPP(this.selectedECPM).subscribe(
      (data) => {
        this.spinnerService.hide();
        console.log(data);
        z.cadena = data;
        // this.listaPP = data['pp']
        // this.xlistaPP = this.listaPP
        // this.listaRG = data['rg']
        // console.log(this.listaRG);
      },
      (error) => {
        this.spinnerService.hide();
        console.log("Error");
        //this.handleError(error);
      }
    );
  }
}
