import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-status',
  templateUrl: './status.component.html',
  styleUrls: ['./status.component.scss']
})
export class StatusComponent implements OnInit {
  StatusMsg :string = "Componente Status On Line";
  constructor() { }

  ngOnInit() {
  }

}
