import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { CommonModule } from '@angular/common';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations'; 
 
import {SharedModule} from '../../../../shared/shared.module';
import {NgxDatatableModule} from '@swimlane/ngx-datatable';
import {ToastyModule} from 'ng2-toasty'; 

import { FormsModule } from '@angular/forms'; 
import { ReactiveFormsModule } from '@angular/forms';
import { DataTablesModule } from 'angular-datatables';

import { AuthGuardService } from '../../../../shared/auth-guard/auth-guard.service';

import { NgSelectModule } from '@ng-select/ng-select';
import { Ng4LoadingSpinnerModule } from 'ng4-loading-spinner'; 

import { ConsultaComponent } from './../companias/consulta/consulta.component';
import { PrincipalComponent } from './../principal/principal.component';
import { RfcsComponent } from '../companias/rfcs/rfcs.component';
import { NuevacompaniaComponent } from '../companias/nuevacompania/nuevacompania.component';

import { EstadosPipe } from 'src/app/pipes/estados/estados.pipe';
import { MenureportesComponent } from '../companias/menureportes/menureportes.component';
import { GridreportesComponent } from '../companias/gridreportes/gridreportes.component';
import { RecuperarxmlComponent } from '../companias/recuperarxml/recuperarxml.component';


@NgModule({
  imports: [
    BrowserModule, 
    BrowserAnimationsModule,
    CommonModule,
    SharedModule,
    NgSelectModule,
    FormsModule,
    ReactiveFormsModule,
    DataTablesModule,
    NgxDatatableModule,
    ToastyModule.forRoot(),
    Ng4LoadingSpinnerModule.forRoot()
  ],
  declarations: [
    PrincipalComponent,
    ConsultaComponent,
    RfcsComponent,
    EstadosPipe,
    NuevacompaniaComponent,
    MenureportesComponent,
    GridreportesComponent,
    RecuperarxmlComponent
    
  ],
  exports: [ 
    PrincipalComponent,
    ConsultaComponent,
    RfcsComponent,
    NuevacompaniaComponent,
    MenureportesComponent,
    GridreportesComponent,
    RecuperarxmlComponent
  ],
  providers: [
    AuthGuardService
  ]
})
export class ComprobantesModule { }
