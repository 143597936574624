import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { CommonModule } from '@angular/common';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations'; 
 
import {SharedModule} from '../../../../shared/shared.module';
import {NgxDatatableModule} from '@swimlane/ngx-datatable';
import {ToastyModule} from 'ng2-toasty'; 

import { FormsModule } from '@angular/forms'; 
import { ReactiveFormsModule } from '@angular/forms';
import { DataTablesModule } from 'angular-datatables';

import { AuthGuardService } from '../../../../shared/auth-guard/auth-guard.service';
import { NotificacionesComponent } from '../notificaciones/notificaciones.component';
import { ProcesarArchivosComponent } from '../procesar-archivos/procesar-archivos.component';
import { ReglasAprobadasComponent } from '../reglas-aprobadas/reglas-aprobadas.component'

import { NgSelectModule } from '@ng-select/ng-select';
import { Ng4LoadingSpinnerModule } from 'ng4-loading-spinner'; 
import { CargarArchivosComponent } from '../cargar-archivos/cargar-archivos.component';
import { KeysPipe } from 'src/app/pipes/keypipe/keys.pipe';
import { SanitizeHtmlPipe } from 'src/app/pipes/htmlsafe/htmlsafe.pipe';


@NgModule({
  imports: [
    BrowserModule, 
    BrowserAnimationsModule,
    CommonModule,
    SharedModule,
    NgSelectModule,
    FormsModule,
    ReactiveFormsModule,
    DataTablesModule,
    NgxDatatableModule,
    ToastyModule.forRoot(),
    Ng4LoadingSpinnerModule.forRoot()
  ],
  declarations: [
    NotificacionesComponent, 
    ProcesarArchivosComponent,
    CargarArchivosComponent,
    ReglasAprobadasComponent,
    KeysPipe,
    SanitizeHtmlPipe
  ],
  exports: [ 
    NotificacionesComponent, 
    ProcesarArchivosComponent 
  ],
  providers: [
    AuthGuardService
  ]
})
export class OperacionModule { }
