import { Component, OnInit } from '@angular/core';
import { LoginService } from '../../../../services/self/seguridad/login.service';
import { GoogleLoginProvider, AuthService } from 'angular-6-social-login';
import { Ng4LoadingSpinnerService } from "ng4-loading-spinner";
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  providers: [LoginService]
})
export class LoginComponent implements OnInit {
  Data: any = {};
  public loading: boolean = false;

  constructor(
    private loginService: LoginService, public OAuth: AuthService,
    private spinnerService: Ng4LoadingSpinnerService
  ) { }

  ngOnInit() {
    this.loginService.logout();
  }

  login(email : string) {
    try {
      let json = {
        email: email
      }

      this.loginService.login(json)
        .subscribe(
          data => {
            this.loginService.setSession(data);
          },
          error => {
          }
        );
    }
    catch (errores) {
      console.log(errores);
    };
  }

  public authCuentaGoogle() {
    // this.spinnerService.show();
    window["authCuentaGoogle"]().then((result) => {
      /** @type {firebase.auth.OAuthCredential} */
      var credential = result.credential;
      // This gives you a Google Access Token. You can use it to access the Google API.
      var token = credential.accessToken;
      // The signed-in user info.
      var user = result.user;

      this.login(result.additionalUserInfo.profile.email);
      // ...
    }).catch((error) => {
      // Handle Errors here.
      var errorCode = error.code;
      var errorMessage = error.message;
      // The email of the user's account used.
      var email = error.email;
      // The firebase.auth.AuthCredential type that was used.
      var credential = error.credential;

    })
    .finally(() => {
      this.spinnerService.hide();
    });
  }

  Savesresponse(socialusers: any) {

    this.loginService.Savesresponse(socialusers).subscribe((res: any) => {
      debugger;
      //this.socialusers=res;
      //this.response = res.userDetail;
      //localStorage.setItem('socialusers', JSON.stringify( this.socialusers));
      //console.log(localStorage.setItem('socialusers', JSON.stringify(this.socialusers)));
      // ethis.router.navigate([`/Dashboard`]);
    })
  }


}
