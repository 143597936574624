import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ResourceService {
  private httpOptions: any;

  constructor(private http: HttpClient) {
  }


  public get(url, parameters) {
    this.httpOptions = this.getToken();
    const params = new HttpParams(parameters);
    return this.http.get(`${environment.apiUrl}/${url}`, { ...this.httpOptions, ...params });

  }
  public getById(url, id:any) {
    this.httpOptions = this.getToken();
    return this.http.get(`${environment.apiUrl}/${url}/${id}`, { ...this.httpOptions });

  }
  public post(url, body) {
    return this.http.post(`${environment.apiUrl}/${url}`, body, { ...this.httpOptions });
  }
  public put(url, body) {
    return this.http.put(`${environment.apiUrl}/${url}`, body, { ...this.httpOptions });
  }
  public delete(url, id) {
    return this.http.delete(`${environment.apiUrl}/${url}/${id}`,  { ...this.httpOptions });
  }
  //Acceso a Apis de Seguridad

  public checktoken(url, body) {
    this.httpOptions = this.getToken();
    return this.http.post(`${environment.apiUrlSecurity}/${url}`, body, { ...this.httpOptions });
  }

  public getByIdToPromise(url, id:any): Promise<any> {
    this.httpOptions = this.getToken();
    return this.http.get(`${environment.apiUrlSecurity}/${url}/${id}`, { ...this.httpOptions }).toPromise();

  }

  public AccessMenu(url, parameters) {
    this.httpOptions = this.getToken();
    const params = new HttpParams(parameters);
    return this.http.get(`${environment.apiUrlSecurity}/${url}`, { ...this.httpOptions, ...params });

  }

  getToken() {
    let httpOptions2 = {};

    var data = localStorage.getItem('auth');
    let auth = JSON.parse(data);

    return httpOptions2 = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        "Authorization": `Bearer ${auth.token}`
      })
    };
  }



}
