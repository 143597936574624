import { AuthService } from './../../../../services/self/seguridad/auth.service';
import { Component, OnInit, ViewEncapsulation, ViewChild, ElementRef } from '@angular/core';
import { NotificacionService } from './../../../../services/bi/operacion/notificaciones.service';
import { Router } from '@angular/router';
import { CommontoastrService } from 'src/app/shared/toastr/commontoastr.service';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { _ } from 'underscore'
import { ifError } from 'assert';
import { ScreenActionsService } from 'src/app/services/self/seguridad/actions.service';
import * as $ from 'jquery';
import { LayoutService } from 'src/app/services/bi/layout/layout.service';

@Component({
  selector: 'app-reglas-aprobadas',
  templateUrl: './reglas-aprobadas.component.html',
  styleUrls: ['./reglas-aprobadas.component.scss',"../../../../../assets/icon/icofont/css/icofont.scss"]
})
export class ReglasAprobadasComponent implements OnInit {
  public Page: any = {};
  public Screen: any = {};

  //Filtros de grid
  Filter: any = {};
  ListaReglasAprobadas: any = [];
  listaECPM: any = [];
  xlistaCNA: any = [];

  selectedECPM: any = 0;
  LevelApproveObjectId: any = 0;
  IFF: any = 0;
  selectedNR : any ="";
  selectedNC : any = 0;
  selectedCNA : any = 0;
  listaCNA: any = [];
  listaNC: any = [];

  InstanciaNotificacion: any = {};
  ListaNotificaciones: any = [];
  ListaFiltrosNotificaciones: any = [];
  TrackAcciones: any = [];
  //Manejo Notificacion y Pop UP
  Headers: any = [];
  EmailTipoLista: any = [];
  ListaDataEmail: any = [];
  SingleRow: any = [];
  ListaAcciones: any = [];
  ListaClientes: any = [];
  ListaClientesFilter: any = [];
  ListaClientesAcceso: any = [];
  //Otros 
  General: any = {};
  Auth: any = {};
  Task: any = {};
  timeouts: any;
  Opciones: any = [];
  Filtro: any = [];
  ///Cambio 
  ListaDetalleCorreo: any = [];
  EmailDetail: any = {};
  EmailDetailFiltered: any = {};
  //html
  htmlString: any = "";
  html: any = "";

  constructor(private NotificacionService: NotificacionService,private pservice: LayoutService, private router: Router,
    private toastr: CommontoastrService, private AuthService: AuthService,
    private spinnerService: Ng4LoadingSpinnerService, private ScreenActionsService: ScreenActionsService) { 
      ///debugger;
      this.Page.AllowView = false;
      this.Page.AllowAdd = false;
      this.Page.AllowEdit = false;
      this.Page.Controller = this.router.routerState.snapshot.url.toString().substr(this.router.routerState.snapshot.url.lastIndexOf('/') + 1, this.router.routerState.snapshot.url.toString().length - 1);
  
      this.AuthService.IsAlive();
  
      this.Filter.NotificationTypeObjectId = [];
      this.General.Accion = 0;
  
      this.General.ValidaComentario = true;
      this.General.Comentario = true;
      this.General.DetalleComentario = "";
      this.General.SolicitaComentario = false;
      this.General.Data = {};
      this.General.Data.IdStatusTo = 0;
      this.General.Data.PositionColumnKey = 0;
      this.General.Track = {};
      this.Task.ShowList = true;
      this.Task.ShowTrack = false;
      this.Task.ShowEmailDetail = false;
      //Adicionales
      this.General.AllowSelect = false;
      this.InstanciaNotificacion.IsList = false;
      this.InstanciaNotificacion.IsTable = false;
      this.InstanciaNotificacion.CssClass = "";
      this.General.SelectAll = false;
      this.General.ShowAlert = false;
    }

  ngOnInit() {
    var data = localStorage.getItem('auth');
    if (data != undefined && data != null) {
      this.Auth = JSON.parse(data);
      this.General.IdUser = this.Auth.objectId;
    }

    this.CargarClientes(this.Auth.email);
    this.CargarNotificationActions();
    this.CargarNotificationsConfiguration()

  }

  CargarClientes(Id:any) {
    this.pservice.ObtenerClienteEDI({}).subscribe(data => {
      this.spinnerService.hide()
      this.listaECPM = data['ecpm']
    },
      error => {
        this.spinnerService.hide()
        console.log("Error");
      });
  }

  CargarNotificationActions(){
    //GetCatNotificationActions
    this.NotificacionService.GetCatNotificationActions({}).subscribe(data => {
      this.spinnerService.hide()
      this.listaCNA = data[0]
      console.log("listaCNA");
      console.log(data);
    },
      error => {
        this.spinnerService.hide()
        console.log("Error");
      });
  }

  CargarNotificationsConfiguration(){
    //GetNotificationsConfiguration
    this.NotificacionService.GetNotificationsConfiguration({}).subscribe(data => {
      this.spinnerService.hide()
      this.listaNC = data[0]
      console.log("notificationsConfiguration");
      console.log(this.listaNC);
    },
      error => {
        this.spinnerService.hide()
        console.log("Error");
      });
  }




  Aplicar() {
    var iff = ""
    this.spinnerService.show();
    if (this.IFF == "1"){
      iff = "ACTIVO"
    }else if (this.IFF == "2"){
      iff = "INACTIVO"
    }
    var body = {
      LevelApproveObjectId : parseInt(this.LevelApproveObjectId) || 0,
      ECPM : parseInt(this.selectedECPM) || 0,
      NR : parseInt(this.selectedNR) || 0,
      NC: parseInt(this.selectedNC) || 0,
      CNA: parseInt(this.selectedCNA) || 0,
      IFF: iff || "nada"
    }
    console.log(body);

    //this.Filter.Email = this.Auth.email;
    this.NotificacionService.GetReglasAprobadas(body).subscribe(data => {
      this.ListaReglasAprobadas = data;
      this.spinnerService.hide();
      console.log(data);
    },
      error => {
        this.handleError(error);
      });

  }



  changeNC(){
    if(this.selectedNC == 0){
      this.xlistaCNA = this.listaCNA
      this.selectedCNA = 0;
      return
    }
    this.xlistaCNA = []
    this.listaCNA.forEach(e => {
      if(e.NotificationsConfigurationObjectId == this.selectedNC)
      this.xlistaCNA.push(e)
    });

    if(this.xlistaCNA[this.xlistaCNA.findIndex(x => x.objectId == this.selectedCNA)] == undefined){
      this.selectedCNA = 0;
    }
    


  }


  //---------Funciones Manejo de Errores--------------------------
  private handleError(error: any) {

    this.spinnerService.hide();
    if (error.status == 401) {
      localStorage.removeItem('auth');
      this.router.navigate(['login'])
    }
    this.toastr.Error(error.error.Descripcion);

  };

}
