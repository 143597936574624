import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";

import { AppRoutingModule } from "./app-routing.module";
import { FormsModule } from "@angular/forms";
import { ReactiveFormsModule } from "@angular/forms";
import { CommonModule } from "@angular/common";
import { SharedModule } from "./shared/shared.module";
import { CatalogosModule } from "./components/self/catalogos/catalogos.component";
import { OperacionModule } from "./components/bi/operacion/module/operacion.module";
import { ComprobantesModule } from "./components/bi/comprobantes/module/comprobantes.module";
import { PedidosModule } from "./components/bi/pedidos/pedidos.module";
import { ConciliacionesModule } from "./components/bi/conciliaciones/module/conciliaciones.module";
import { PagosModule } from "./components/bi/pagos/pagosmodule/pagosmodule.module";
import { TimbradoModule } from "./components/bi/timbrado/timbradomodule/timbradomodule.module";

import { NgIdleKeepaliveModule } from "@ng-idle/keepalive";
import { MomentModule } from "angular2-moment";

import { AppComponent } from "./app.component";
import { AdminComponent } from "./layout/admin/admin.component";
import { AuthComponent } from "./layout/auth/auth.component";
import { BreadcrumbsComponent } from "./layout/admin/breadcrumbs/breadcrumbs.component";
import { HomeComponent } from "./components/self/seguridad/home/home.component";
import { HttpClientModule } from "@angular/common/http"
import { WINDOW_PROVIDERS } from "../app/components/bi/timbrado/windows.providers"
import { Ng4LoadingSpinnerModule } from 'ng4-loading-spinner';


import { MenuItems } from "./shared/menu-items/menu-items";
import { AuthGuardService } from "./shared/auth-guard/auth-guard.service";
import {
  SocialLoginModule,
  AuthService,
  AuthServiceConfig,
  GoogleLoginProvider,
} from "angular-6-social-login";

export function socialConfigs() {
  const config = new AuthServiceConfig([
    {
      id: GoogleLoginProvider.PROVIDER_ID,
      provider: new GoogleLoginProvider(
        "582302570515-ula583nhfeh76ndcui01rprvbb3986d2.apps.googleusercontent.com"
      ),
    },
  ]);
  return config;
}

@NgModule({
  declarations: [
    AppComponent,
    AdminComponent,
    AuthComponent,
    BreadcrumbsComponent,
    HomeComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    CommonModule,
    SharedModule,
    CatalogosModule,
    OperacionModule,
    ComprobantesModule,
    PedidosModule,
    ConciliacionesModule,
    PagosModule,
    TimbradoModule,
    FormsModule,
    ReactiveFormsModule,
    MomentModule,
    NgIdleKeepaliveModule.forRoot(),
    HttpClientModule,
    Ng4LoadingSpinnerModule.forRoot()
    //NgxDatatableModule,
    //ToastyModule.forRoot()
  ],
  providers: [
    MenuItems,
    AuthGuardService,
    WINDOW_PROVIDERS,
    AuthService,
    {
      provide: AuthServiceConfig,
      useFactory: socialConfigs,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
