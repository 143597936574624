import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import 'rxjs/add/operator/map';

@Injectable({
  providedIn: 'root'
})
export class ConsultapedidoService {

  private httpOptions2 = {
    headers: new HttpHeaders({
      "Content-Type": "application/json"
    })
  };

  constructor(private http: HttpClient) { }

  public GetPedidos(body) {

    return this.http.post(`http://localhost:5005/api/Pedidos/ObtenerPedidos`, body, this.httpOptions2);
  }
  public PortalQueries(body) {

    return this.http.post(`http://localhost:5005/api/Pedidos/PortalQueries`, body, this.httpOptions2);
  }

  public PP_NuevoPedido_Queries(body) {

    return this.http.post(`http://localhost:5005/api/Pedidos/PPNuevoPedidoQueries`, body, this.httpOptions2);
  }

  public PP_CONSULTAPEDIDO_FILTROS(body) {

    return this.http.post(`http://localhost:5005/api/Pedidos/PPConsultaPedidosFiltros`, body, this.httpOptions2);
  }
  public GetMedidas() {
    return this.http.get(`${environment.apiUrl}/transactions/Pedidos/ObtenerMedidas`, this.httpOptions2);
  }
  public PPConsultaRecursosFiltros(body) {
    return this.http.post(`http://localhost:5005/api/Pedidos/PPConsultaRecursosFiltros`, body, this.httpOptions2);
  }
  public PPNUEVORECURSO(body) {
    return this.http.post(`http://localhost:5005/api/Pedidos/PPNUEVORECURSO`, body, this.httpOptions2);
  }



}
