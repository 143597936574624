import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';
import { ReportesService } from 'src/app/services/bi/comprobantes/reportes.service';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { CommontoastrService } from 'src/app/shared/toastr/commontoastr.service';

@Component({
  selector: 'app-menureportes',
  templateUrl: './menureportes.component.html',
  styleUrls: ['./menureportes.component.scss']
})
export class MenureportesComponent implements OnInit {
  @Output() voted = new EventEmitter<boolean>();
  @Input() ReportRFC: any ="";
  @Input() ReportList: any = {};
  actual:string = "start";
  actual2:string = "start";
  ListaFiltrosReportes:any = [];
  Filter: any = "Generación de Reportes";
  ObjectId : any = "";

  REP_FechaI:any = "";
  REP_FechaF:any = "";

  REP_RFC_Receptor:any = "";

  REP_MontoI:any ="";
  REP_MontoF:any ="";

  REP_Moneda:any = "";

  REP_Folio:any = "";

  REP_Tipo:any = "";


  IE_FechaI:any = "";
  IE_FechaF:any = "";

  IE_RFC_Receptor:any = "";

  IE_MontoI:any = "";
  IE_MontoF:any = "";

  IE_Moneda:any = "";

  IE_Folio:any = "";

  IE_Tipo:any = "";


  NO_FechaI:any = "";
  NO_FechaF:any = "";

  NO_RFC_Receptor:any = "";

  NO_MontoI:any = "";
  NO_MontoF:any = "";

  NO_NOEMPLEADO:any = "";

  NO_Folio:any = "";

  NO_NOMBRE:any = "";


  IEC_FechaI:any = "";
  IEC_FechaF:any = "";

  IEC_RFC_Receptor:any = "";

  IEC_MontoI:any = "";
  IEC_MontoF:any = "";

  IEC_Moneda:any = "";

  IEC_Folio:any = "";

  GridData:any = [];
  
  constructor(private service: ReportesService, private spinnerService: Ng4LoadingSpinnerService,
    private toastr: CommontoastrService) { 
    var today = new Date();
    var dd = String(today.getDate()).padStart(2, '0');
    var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
    var yyyy = today.getFullYear();
    this.REP_FechaI = yyyy + '-' + mm + '-' + dd;
    this.REP_FechaF = yyyy + '-' + mm + '-' + dd;
    this.IE_FechaI = yyyy + '-' + mm + '-' + dd;
    this.IE_FechaF = yyyy + '-' + mm + '-' + dd;
    this.IEC_FechaI = yyyy + '-' + mm + '-' + dd;
    this.IEC_FechaF = yyyy + '-' + mm + '-' + dd;
    this.NO_FechaI = yyyy + '-' + mm + '-' + dd;
    this.NO_FechaF = yyyy + '-' + mm + '-' + dd;
  }

  ngOnInit() {
    if(this.ReportList.RNomina){
      this.ListaFiltrosReportes.push("Nómina");
    }
    if(this.ReportList.RIngresosEgresos){
      this.ListaFiltrosReportes.push("Ingresos Egresos");
    }
    if(this.ReportList.RIngresosEgresosC){
      this.ListaFiltrosReportes.push("Ingresos Egresos Concepto");
    }
    if(this.ReportList.RReciboElec){
      this.ListaFiltrosReportes.push("Recibo Electrónico de Pago");
    }
  }

  test(event:any){
    if(event == "Recibo Electrónico de Pago"){
      this.actual = "REPp";
      this.GridData = []
    }else if(event == "Ingresos Egresos"){
      this.actual = "IE";
      this.GridData = []
    }else if(event == "Ingresos Egresos Concepto"){
      this.actual = "IEC";
      this.GridData = []
    }else if(event == "Nómina"){
      this.actual = "NO";
      this.GridData = []
    }else{
      this.Filter = "Generación de Reportes"
      this.actual = "start";
      this.actual2 = "start"
      this.GridData = []
    }
  }

  volver() {
    if(this.actual == "gridREP"){
      this.actual = "REP";
    }else if(this.actual == "gridIE"){
      this.actual = "IE";
    }else if(this.actual == "gridNO"){
      this.actual = "NO";
    }else if(this.actual == "gridIEC"){
      this.actual = "IEC";
    }else if(this.actual == "REP" || this.actual == "IE" || this.actual == "NO" || this.actual == "IEC"){
      this.GridData = [];
      this.actual = "start";
    }
    else{
      this.voted.emit(false);
    }
      
  }


 consultaIEC(){

   if((this.IEC_MontoF.length > 0 && this.IEC_MontoI.length == 0) || (this.IEC_MontoI.length > 0 && this.IEC_MontoF.length == 0) || ((isNaN(this.IEC_MontoF) || isNaN(this.IEC_MontoI)) || !(this.IEC_MontoF >= 0) || !(this.IEC_MontoI >= 0))){
    this.toastr.Error("Ingrese un valor numérico al monto inicial y final.");
     return;
   }

   if(this.IEC_RFC_Receptor.length > 0 && this.IEC_RFC_Receptor.length != 12 && this.IEC_RFC_Receptor.length != 13){
    this.toastr.Error("Ingrese un RFC Receptor válido.");
     return;
   }

    var date1 = new Date(this.IEC_FechaI);
    var date2 = new Date(this.IEC_FechaF);
    var time = new Date(this.IEC_FechaF).getTime() - new Date(this.IEC_FechaI).getTime();
    var diffDays = Math.ceil(time / (1000 * 60 * 60 * 24));
    if(diffDays > 30){
      this.toastr.Error("El período de consulta no puede ser mayor a 30 días.");
      return;
    }


    var obj = {
      rfcEmisor : this.ReportRFC.rfc,
      rfcReceptor : this.IEC_RFC_Receptor.length > 0 ? this.IEC_RFC_Receptor : null,
      fechainicial : this.IEC_FechaI,
      fechafinal : this.IEC_FechaF,
      montoI : this.IEC_MontoI.length > 0 ? this.IEC_MontoI : -1,
      montoF : this.IEC_MontoF.length > 0 ? this.IEC_MontoF : -1,
      folio : this.IEC_Folio.length > 0 ? this.IEC_Folio : null,
      monedaP : this.IEC_Moneda.length > 0 ? this.IEC_Moneda : null
    };
    
    this.spinnerService.show()
        this.service.post("IEC",obj).subscribe((data:any) => {
          this.spinnerService.hide();
          this.GridData = data;
        },
          error => {
            this.spinnerService.hide();
            this.toastr.Error("Error al generar reporte Ingresos Egresos Concepto");
          });
 }

 

 consultaIE(){

   if((this.IE_MontoF.length > 0 && this.IE_MontoI.length == 0) || (this.IE_MontoI.length > 0 && this.IE_MontoF.length == 0) || ((isNaN(this.IE_MontoF) || isNaN(this.IE_MontoI)) || !(this.IE_MontoF >= 0) || !(this.IE_MontoI >= 0))){
    this.toastr.Error("Ingrese un valor numérico al monto inicial y final.");
     return;
   }

   if(this.IE_RFC_Receptor.length > 0 && this.IE_RFC_Receptor.length != 12 && this.IE_RFC_Receptor.length != 13){
    this.toastr.Error("Ingrese un RFC Receptor válido.");
     return;
   }

    var date1 = new Date(this.IE_FechaI);
    var date2 = new Date(this.IE_FechaF);
    var time = new Date(this.IE_FechaF).getTime() - new Date(this.IE_FechaI).getTime();
    var diffDays = Math.ceil(time / (1000 * 60 * 60 * 24));
    if(diffDays > 30){
      this.toastr.Error("El período de consulta no puede ser mayor a 30 días.");
      return;
    }


    var obj = {
      rfcEmisor : this.ReportRFC.rfc,
      rfcReceptor : this.IE_RFC_Receptor.length > 0 ? this.IE_RFC_Receptor : null,
      fechainicial : this.IE_FechaI,
      fechafinal : this.IE_FechaF,
      montoI : this.IE_MontoI.length > 0 ? this.IE_MontoI : -1,
      montoF : this.IE_MontoF.length > 0 ? this.IE_MontoF : -1,
      folio : this.IE_Folio.length > 0 ? this.IE_Folio : null,
      monedaP : this.IE_Moneda.length > 0 ? this.IE_Moneda : null,
      tipo: this.IE_Tipo.length > 0 ? this.IE_Tipo : null
    };
    
    this.spinnerService.show()
        this.service.post("IE",obj).subscribe((data:any) => {
          this.spinnerService.hide();
          this.GridData = data;
          //this.actual = "gridREP";
        },
          error => {
            this.spinnerService.hide();
            this.toastr.Error("Error al generar reporte Ingresos Egresos");
          });
 }

 
 consultaNO(){

   if((this.NO_MontoF.length > 0 && this.NO_MontoI.length == 0) || (this.NO_MontoI.length > 0 && this.NO_MontoF.length == 0) || ((isNaN(this.NO_MontoF) || isNaN(this.NO_MontoI)) || !(this.NO_MontoF >= 0) || !(this.NO_MontoI >= 0))){
    this.toastr.Error("Ingrese un valor numérico al monto inicial y final.");
     return;
   }

   if(this.NO_RFC_Receptor.length > 0 && this.NO_RFC_Receptor.length != 12 && this.NO_RFC_Receptor.length != 13){
    this.toastr.Error("Ingrese un RFC Receptor válido.");
     return;
   }

    var date1 = new Date(this.NO_FechaI);
    var date2 = new Date(this.NO_FechaF);
    var time = new Date(this.NO_FechaF).getTime() - new Date(this.NO_FechaI).getTime();
    var diffDays = Math.ceil(time / (1000 * 60 * 60 * 24));
    if(diffDays > 30){
      this.toastr.Error("El período de consulta no puede ser mayor a 30 días.");
      return;
    }


    var obj = {
      rfcEmisor : this.ReportRFC.rfc,
      rfcReceptor : this.NO_RFC_Receptor.length > 0 ? this.NO_RFC_Receptor : null,
      fechainicial : this.NO_FechaI,
      fechafinal : this.NO_FechaF,
      montoI : this.NO_MontoI.length > 0 ? this.NO_MontoI : -1,
      montoF : this.NO_MontoF.length > 0 ? this.NO_MontoF : -1,
      folio : this.NO_Folio.length > 0 ? this.NO_Folio : null
    };
    
    this.spinnerService.show()
        this.service.post("NO",obj).subscribe((data:any) => {
          this.spinnerService.hide();
          this.GridData = data;
          //this.actual = "gridREP";
        },
          error => {
            this.spinnerService.hide();
            this.toastr.Error("Error al generar reporte Nómina");
          });
 }


 consultaREP(){

   if((this.REP_MontoF.length > 0 && this.REP_MontoI.length == 0) || (this.REP_MontoI.length > 0 && this.REP_MontoF.length == 0) || ((isNaN(this.REP_MontoF) || isNaN(this.REP_MontoI)) || !(this.REP_MontoF >= 0) || !(this.REP_MontoI >= 0))){
    this.toastr.Error("Ingrese un valor numérico al monto inicial y final.");
     return;
   }

   if(this.REP_RFC_Receptor.length > 0 && this.REP_RFC_Receptor.length != 12 && this.REP_RFC_Receptor.length != 13){
    this.toastr.Error("Ingrese un RFC Receptor válido.");
     return;
   }

    var date1 = new Date(this.REP_FechaI);
    var date2 = new Date(this.REP_FechaF);
    var time = new Date(this.REP_FechaF).getTime() - new Date(this.REP_FechaI).getTime();
    var diffDays = Math.ceil(time / (1000 * 60 * 60 * 24));
    if(diffDays > 30){
      this.toastr.Error("El período de consulta no puede ser mayor a 30 días.");
      return;
    }


    var obj = {
      rfcEmisor : this.ReportRFC.rfc,
      rfcReceptor : this.REP_RFC_Receptor.length > 0 ? this.REP_RFC_Receptor : null,
      fechainicial : this.REP_FechaI,
      fechafinal : this.REP_FechaF,
      montoI : this.REP_MontoI.length > 0 ? this.REP_MontoI : -1,
      montoF : this.REP_MontoF.length > 0 ? this.REP_MontoF : -1,
      folio : this.REP_Folio.length > 0 ? this.REP_Folio : null,
      monedaP : this.REP_Moneda.length > 0 ? this.REP_Moneda : null,
      tipo: this.REP_Tipo.length > 0 ? this.REP_Tipo : null
    };
    
    this.spinnerService.show()
        this.service.post("REP",obj).subscribe((data:any) => {
          this.spinnerService.hide();
          this.GridData = data;
          //this.actual = "gridREP";
        },
          error => {
            this.spinnerService.hide();
            this.toastr.Error("Error al generar reporte ReciboElectrónicoDePago");
          });
 }






 onVoted(agreed: any) {
  this.actual = "main";
}

consulta(){
  this.actual2 = "";
  if(this.actual == "REPp"){
    this.consultaREP()
  }else if(this.actual == "IE"){
    this.consultaIE()
  }else if(this.actual == "NO"){
    this.consultaNO()
  }else if(this.actual == "IEC"){
    this.consultaIEC()
  }
}

}
