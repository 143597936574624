import { Router } from '@angular/router';
import { ResourceService } from 'src/app/shared/resource.service';
import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(private api: ResourceService, private router: Router) {

  }

  IsAlive() {
    var AuthData = localStorage.getItem('auth');
    let user = JSON.parse(AuthData);

    this.api.checktoken('sesion/checkstatus',user)
      .subscribe(data => {

       
        if (AuthData != undefined && AuthData != null)
        {
          let auth = JSON.parse(AuthData);
          $("#spUserName").text(auth.email);
        }
      },
        error => {
          this.handleError(error);
        })

  }


  private handleError(error: HttpErrorResponse) {
    if (error.status == 401) {
      localStorage.removeItem('auth');
      this.router.navigate(['login'])
    }
    else if (error.status == 0) {
      localStorage.removeItem('auth');
      this.router.navigate(['login'])
    }
  };

}
