import { PipeTransform, Pipe } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Pipe({ name: 'keys' })
export class KeysPipe implements PipeTransform {
  transform(value, args: string[]): any {
    let keys = [];
    for (let key in value) {
      if (key != "Columna0" && key != "Activo" && key != "Commentary" && key != "PercentageApproval")
        keys.push(key);
    }
    return keys;
  }
}



