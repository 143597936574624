import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ResourceService } from 'src/app/shared/resource.service';


@Injectable({
    providedIn: 'root'
})
export class SeguridadService {
    public FromShareService: any = {};

    constructor(private api: ResourceService, private router: Router) { }

    //--------() Api gateway)--------------------//




    Get(data: any) {
        //return this.api.get('payments/payments', data);
        return this.api.get('payments/payments', data);
    }

    ObtenerClienteEDI(data: any) {
        //return this.api.get('payments/payments', data);
        return this.api.get('payments/payments', data);
    }

    ObtenerAppSettingsLista(data) {
        //return this.api.get('payments/security/applicationSettings', data)
        return this.api.get('payments/security/applicationSettings', data)
    }

    GuardarCambiosAppSettings(data) {
        //return this.api.post('payments/security/applicationSettings', data)
        return this.api.post('payments/security/applicationSettings', data)
    }

    AgregarAppSettingsByProfile(data) {
        //return this.api.post('payments/security/addApplicationSettingsByProfile', data)
        return this.api.post('payments/security/addApplicationSettingsByProfile', data)
    }

    ObtenerAppSettingsByProfileLista(data) {
        //return this.api.get('payments/security/applicationSettingsByProfile', data)
        return this.api.get('payments/security/applicationSettingsByProfile', data)
    }

    BorrarAppSettingsByProfile(data) {
        //return this.api.post('payments/security/deleteAppicationSettingsByProfile', data)
        return this.api.post('payments/security/deleteAppicationSettingsByProfile', data)
    }

    GuardarCambiosAppSettingsByProfile(data) {
        //return this.api.post('payments/security/applicationSettingsByProfile', data)
        return this.api.post('payments/security/applicationSettingsByProfile', data)
    }



    ObtenerEdigUserLista(data) {
        return this.api.get('payments/security/edigUser', data)
       //return this.api.get('payments/security/edigUser', data)
    }

    GuardarNuevoUsuario(data) {
        return this.api.post('payments/security/edigUser', data)
        //return this.api.post('payments/security/edigUser', data)
    }

    GuardarCambiosEdigUser(data) {
        return this.api.put('payments/security/edigUser', data);
        //return this.api.put('payments/security/edigUser', data);
    }

    DeleteEdigUser(data) {
        return this.api.post('payments/security/edigUserDelete', data)
        //return this.api.post('payments/security/edigUserDelete', data)
    }


    ObtenerGroupsLista(data) {
        return this.api.get('payments/security/groupsUser', data)
        //return this.api.get('payments/security/groupsUser', data)
    }

    GuardarNuevoGrupo(data) {
        return this.api.post('payments/security/groupsUser', data)
        //return this.api.post('payments/security/groupsUser', data)
    }

    GuardarCambiosGrupo(data) {
        //return this.api.put('payments/security/groupsUser', data)
        return this.api.put('payments/security/groupsUser', data)
    }

    DeleteGrupo(data) {
        //return this.api.post('payments/security/groupsUserDelete', data)
        return this.api.post('payments/security/groupsUserDelete', data)
    }


    ObtenerRelacionGruposLista(data) {
        return this.api.get('payments/security/edigUserGroupRelation', data)
        //return this.api.get('payments/security/edigUserGroupRelation', data)
    }

    ObtenerComboUsuarios(data) {
        return this.api.get('payments/security/getComboUsers', data)
        //return this.api.get('payments/security/getComboUsers', data)
    }

    ObtenerComboGrupos(data) {
        return this.api.get('payments/security/getComboGroups', data)
        //return this.api.get('payments/security/getComboGroups', data)
    }

    ObtenerComboConfig(data) {
        return this.api.get('payments/security/getComboConfig', data)
        //return this.api.get('payments/security/getComboConfig', data)
    }


    GuardarNewRelacion(data) {
        return this.api.post('payments/security/edigUserGroupRelation', data)
        //return this.api.post('payments/security/edigUserGroupRelation', data)
    }

    GuardarCambiosRelacion(data) {
        return this.api.put('payments/security/edigUserGroupRelation', data)
        //return this.api.put('payments/security/edigUserGroupRelation', data)
    }

    DeleteRelacion(data) {
        return this.api.post('payments/security/edigUserGroupRelationDelete', data)
        //return this.api.post('payments/security/edigUserGroupRelationDelete', data)
    }


}
