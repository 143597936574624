import { Component, OnInit } from '@angular/core';
import { LayoutService } from "src/app/services/bi/layout/layout.service";
import { Ng4LoadingSpinnerService } from "ng4-loading-spinner";

@Component({
  selector: 'app-seguridad',
  templateUrl: './seguridad.component.html',
  styleUrls: ['./seguridad.component.scss',
    "../../../../../assets/icon/icofont/css/icofont.scss"]
})
export class SeguridadComponent implements OnInit {
  listaSegmentos: any = [];
  listaTabSegmentos: any = []
  opcion: any = "Usuarios";
  listaGrupos: any = []
  listaUsuarios: any = [];
  listaAtributos: any = [];
  listaAtributosSeg: any = [];


  selectedUser: any = 0;
  selectedGroup: any = 0;
  campoChecked: any = false;

  body: any = {};
  campos: any = [];
  camposFalse: any = []
  checado: any = false

  objectIdTab: any = 0;
  tabSeleccionado: any = true;

  nombreSegmento: any = "";
  nombreTabBRead: any = ""

  constructor(private lService: LayoutService,
    private spinnerService: Ng4LoadingSpinnerService) {
    this.cargaInicial();
    this.cargaCombos();
  }

  ngOnInit() {
  }

  detalle(row) {
    console.log(row)
  }

  armarBody(e) {
    var campo = e.target.id
    var value = e.target.checked
    this.checado = e.target.checked
    // console.log(e.target.checked);
    // console.log(e.target.id);

    this.camposFalse.forEach(element => {
      if (element.campo == campo) {
        element.value = value
      }

    });
    console.log("aplicafactura");
    console.log(this.listaAtributosSeg[0][0]['AplicaPago']);

  }


  guardarCambios() {
    this.spinnerService.show()
    var bodySave = {
      UserId: this.selectedUser,
      GroupId: this.selectedGroup,
      Campos: this.camposFalse
    }
    console.log(bodySave);
    this.lService.GuardarUsuarioAtributosRelacion(bodySave).subscribe(data => {

      this.obtenerCampos('algo', this.objectIdTab)
      console.log(bodySave)

      var body = {
        ObjectId: this.listaAtributosSeg[0][0]['ObjectId'],
        AplicaFactura: this.listaAtributosSeg[0][0]['AplicaFactura'],
        AplicaDevolucion: this.listaAtributosSeg[0][0]['AplicaDevolucion'],
        AplicaNotaCredito: this.listaAtributosSeg[0][0]['AplicaNotaCredito'],
        AplicaNotaCargo: this.listaAtributosSeg[0][0]['AplicaNotaCargo'],
        AplicaPago: this.listaAtributosSeg[0][0]['AplicaPago']
      }
      this.lService.GuardarTabSegmentos(body).subscribe(data => {
        this.spinnerService.hide()
        this.obtenerCampos('algo', this.objectIdTab)
      },
        error => { console.log(error) })
      //GuardarTabSegmentos
    },
      error => { console.log(error) })


  }

  opcionChecked(opcion) {
    this.opcion = opcion
    if (this.opcion == "Usuarios") this.selectedGroup = 0;
    else this.selectedUser = 0

  }

  nombreTab(segmento) {
    if (this.nombreSegmento == segmento) {
      this.nombreSegmento = ""
    } else {
      this.nombreSegmento = segmento

    }
    this.nombreTabBRead = "";
    this.listaAtributos = []

  }

  reload() {
    this.obtenerCampos('false', this.objectIdTab)
  }

  obtenerCampos(tab, objectId) {
    this.objectIdTab = objectId;
    this.nombreTabBRead = tab
    this.lService.ObtenerTabSegmentos(this.objectIdTab).subscribe(data => {
      this.listaAtributosSeg = data;
      console.log(data);
    this.tabSeleccionado = false
    if (this.selectedUser == 0 && this.selectedGroup == 0 && this.selectedUser == null && this.selectedGroup == null) {
      this.camposFalse = []
      this.checado = false
      var body = {
        objectId: objectId
      }
      this.spinnerService.show()
      this.lService.ObtenerCamposTab(body).subscribe(data => {
        console.log("campostab");
        console.log(data);
        this.spinnerService.hide()
        this.listaAtributos = data;
        this.listaAtributos.forEach(element => {
          this.camposFalse.push(
            {
              campo: element.objectId,
              value: false
            }
          )

        });


      },
        error => { console.log(error) })
    }
    else {
      this.camposFalse = []
      this.checado = false
      var bodyF = {
        objectId: objectId,
        UserId: this.selectedUser,
        GroupId: this.selectedGroup
      }
      var bool = false;
      this.spinnerService.show()
      this.lService.ObtenerCamposTabWithFilter(bodyF).subscribe(data => {
        console.log("ObtenerCamposTabWithFilter");
        console.log(data)
        this.spinnerService.hide()
        this.listaAtributos = data;

        this.listaAtributos.forEach(element => {
          if (element.estatus == 1) bool = true;
          else bool = false
          this.camposFalse.push(
            {
              campo: element.objectId,
              value: bool
            }
          )

        });


      },
        error => { console.log(error) })
    }
    },
    error => { console.log(error) })
    
  }

  cargaCombos() {
    this.lService.ObtenerComboGrupos({}).subscribe(data => {
      this.listaGrupos = data;

    }, error => { console.log(error) })

    this.lService.ObtenerComboUsuarios({}).subscribe(data => {
      this.listaUsuarios = data;
    },
      error => { console.log(error) })
  }


  cargaInicial() {

    this.spinnerService.show();
    this.lService.ObtenerCatalogosSegmentos({}).subscribe(data => {
      this.spinnerService.hide();
      this.listaSegmentos = data[0];
      this.listaTabSegmentos = data[1];
      console.log(data);
      // console.log(data)
    },
      error => {
        console.log(error)
      })
  }


  sisive() {
    console.log("hola")
  }

}
