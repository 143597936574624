import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { ConsultaService } from 'src/app/services/bi/comprobantes/consulta.service';
import { RfcsServiceService } from 'src/app/services/bi/comprobantes/rfcs-service.service';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
//import { EstadosPipe } from './../../../../../pipes/estados/estados.pipe';
@Component({
  selector: 'app-consulta',
  templateUrl: './consulta.component.html',
  styleUrls: ['./consulta.component.scss']
})
export class ConsultaComponent implements OnInit {
  @Input() private sols: any;
  @Output() voted = new EventEmitter<boolean>();
  @ViewChild('download') private downloadZipLink: ElementRef;
  //private actual : string = "rfc";
  public actual: string = "solicitudes";


  public rfcs: any;
  private currentRFC: any = "";
  public solcs: any;

  public paqus: any;

  public error: any;

  public comprobs: any;
  public paqcomprobs: any;
  public selectedpaq:any;
  public comprobscont:any;
  public solcomprobs: any;
  public ruta:any;
  public genericPage:any = 9;

  // pager object
  pager: any = {};

  // paged items
  pagedItems: any[];

  constructor(private service: ConsultaService, private rfcservice: RfcsServiceService, private spinnerService: Ng4LoadingSpinnerService) {
    this.getRFCS();

  }

  ngOnInit() {
    // initialize to page 1
    this.setPage(1);
    var contpend = 0;
    var exito = 0;
    var conterr = 0;
    var sow = 0;
    this.sols.listax.forEach(e => {
      if(e.estadoSolicitud == 0 || e.estadoSolicitud == 1 || e.estadoSolicitud == 2){
        contpend++
      }else if(e.estadoSolicitud == 5){
        conterr++
      }else if(e.estadoSolicitud == 3){
        exito++
      }else{
        sow++
      }
    });
  }


  getRFCS() {
    this.rfcservice.get().subscribe(data => {
      this.rfcs = data;
    },
      error => {
        console.log(error);
      });
  }



  detallesSol(sol) {
    this.solcomprobs = sol;

    if (sol.estadoSolicitud == 3) {
      this.service.getPaquetes(sol.idSolicitud).subscribe(data => {
        this.paqus = data;
        this.actual = "paquetes"
      },
        error => {
          console.log(error);
        });


    } else {
      this.error = sol;
      this.actual = "errores";
    }



  }

  /////////////////////////////////////////////////////////
  detallesRfcs(id) {
    this.currentRFC = id;
    this.service.get(id).subscribe(data => {
      this.solcs = data;
      this.actual = "solicitudes"
      
    },
      error => {
        console.log(error);
      });
  }

  detallesPaquete(paq) {
    this.selectedpaq = paq;
    this.spinnerService.show();
    var mes
    var dia
    if(this.solcomprobs.fechaInicial.split("-")[2].charAt(0) == "0"){
      dia = this.solcomprobs.fechaInicial.split("-")[2].charAt(1)
    }
    else{
      dia = this.solcomprobs.fechaInicial.split("-")[2]
    }
    if(this.solcomprobs.fechaInicial.split("-")[1].charAt(0) == "0"){
      mes = this.solcomprobs.fechaInicial.split("-")[1].charAt(1)
    }
    else{
      mes = this.solcomprobs.fechaInicial.split("-")[1]
    }
    var x = this.solcomprobs.rfcEmisor + " " + this.solcomprobs.fechaInicial.split("-")[0] + " " + mes;
    x = x + " " + dia
    this.ruta = x;
    this.paqcomprobs = paq.idPaquete;
    this.service.getDetPaquete(paq.objectId).subscribe((data:any) => {
      this.spinnerService.hide();
      this.comprobs = data.listax;
      this.comprobscont = data.conta;
      this.actual = "comprobantes"
      this.setPage(1);
    },
      error => {
        this.spinnerService.hide();
        console.log(error);
      });
  }



  volver() {


    if (this.actual == "paquetes") {
      this.actual = "solicitudes"
    } else if (this.actual == "solicitudes") {
      this.voted.emit(false);
    } else if (this.actual == "errores") {
      this.actual = "solicitudes"
    } else if (this.actual = "comprobantes") {
      this.actual = "paquetes"
    }

  }

  

  public async enviarXML(id): Promise<void> {
    id = id.trim();

    this.spinnerService.show();
    const data = await this.service.getXML2(this.ruta, this.solcomprobs.idSolicitud, this.paqcomprobs, id);
    
    const blob = new Blob([data], { type: 'application/xml' });  //excel  application/vnd.openxmlformats-officedocument.spreadsheetml.sheet
    const url = window.URL.createObjectURL(blob);
    this.spinnerService.hide();
    window.open(url);


  }


  setPage(page: number) {
    if(this.actual == 'solicitudes'){
      if(page> 0 && page <= this.sols.conta && this.genericPage != page){
        this.spinnerService.show()
        this.service.get(this.sols.listax[0].rfcEmisor).subscribe((data:any) => {
          this.spinnerService.hide()
          this.sols.listax = data.listax;
        },
          error => {
            this.spinnerService.hide()
            console.log(error);
          });
        
  
        this.genericPage = page;
        // get pager object from service
        this.pager = this.service.getPager(this.sols.conta * 10, page);
  
        // get current page of items
        //this.pagedItems = this.allItems.slice(this.pager.startIndex, this.pager.endIndex + 1);
      }
    }
    
    if(this.actual == 'comprobantes'){
      if(page> 0 && page <= this.comprobscont && this.genericPage != page){
        this.spinnerService.show()
        this.service.getDetPaquete(this.selectedpaq.objectId).subscribe((data:any) => {
          this.spinnerService.hide();
          this.comprobs = data.listax;
        },
          error => {
            this.spinnerService.hide();
            console.log(error);
          });
        
  
        this.genericPage = page;
        // get pager object from service
        this.pager = this.service.getPager(this.comprobscont * 10, page);
  
        // get current page of items
        //this.pagedItems = this.allItems.slice(this.pager.startIndex, this.pager.endIndex + 1);
      }
      
    }
    
    
  }
}



