
import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { NotificacionService } from 'src/app/services/bi/operacion/notificaciones.service';
import { AuthService } from 'src/app/services/self/seguridad/auth.service';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { Router, ActivatedRoute } from '@angular/router';
import { CommontoastrService } from 'src/app/shared/toastr/commontoastr.service';
import { PedidoServiceService } from 'src/app/services/bi/comprobantes/pedido-service.service';
import { ConsultapedidoService } from 'src/app/services/bi/comprobantes/consultapedido.service';
@Component({
  selector: 'app-principal',
  templateUrl: './principal.component.html',
  styleUrls: ['./principal.component.scss']
})
export class PrincipalComponent implements OnInit {
  Auth: any = {};
  @Output() voted = new EventEmitter<string>();
  @Input() PO: any;
  @Input() Medidas: any;
  @Input() resumen: any;
  @Input() actual: any;
  @Input() ListaDetalles: any;
  @Input() DataRecursos: any;
  Data = {type:"default", SelectedECPM:'', RGList:[], PPList:[], CHCList:[], nListaCPM:[], ListaCPMS:[], BranchParty:"", BranchPartyEAN:"", PONumber:"", CPMCode:""}
  edit = 0;
  GridData : any = []
  Medida : any = ""
  Medida1 : any = ""
  Medida2 : any = ""
  Nombre : any = ""
  EAN13 : any = ""
  //EAN13 : any = ""
  Quantity : any = ""
  Price : any = "13.9"
  PONumber : any = "2400888380"
  Total : any = ""
  flag : any = ""

  //
  fCode = ""
  fNombre = ""
  fEAN13 = ""
  fDUN14 = ""
  fDUN14T = ""

  //
  ListaRecursos = []
  ListaIEU = []
  ListaITM = []
  ListaIMC = []
  xListaIEU = []
  xListaITM = []
  xListaIMC = []
  selectedIEU = "0"
  selectedITM = "0"
  selectedIMC = "0"
  Cantidad = ""
  selectedObjectId = 0
  PODtlObjectId = 0
  iva = {}
  ieps = {}
  sumaTotal = 0
  resumenCostos = {}

  cTab = 1
  cSplit = 0
  SplitDivisions = []
  SplitResources = []

  constructor(private toastr: CommontoastrService , private router: Router, private spinnerService: Ng4LoadingSpinnerService, 
    private AuthService: AuthService, private NotificacionService: NotificacionService,
    private pservice: PedidoServiceService, private pservice2: ConsultapedidoService, private activatedRoute: ActivatedRoute) { }

    ngOnInit(){

      var data = localStorage.getItem('auth');
      if (data != undefined && data != null) {
        this.Auth = JSON.parse(data);
      }
      if(this.actual != "detalles"){
        console.log(this.DataRecursos);
        this.resumenCostos = this.DataRecursos[4][0]
        this.ListaIEU = this.DataRecursos[1]
        this.ListaITM = this.DataRecursos[2]
        this.ListaIMC = this.DataRecursos[3]
        this.xListaIEU = this.DataRecursos[1]
        this.xListaITM = this.DataRecursos[2]
        this.xListaIMC = this.DataRecursos[3]
        this.ListaRecursos = this.DataRecursos[0]
        if(this.ListaRecursos.length == 300){
          this.toastr.Info("Límite de recursos por consulta: 300")
        }
      }
      var types = []
        
       
        for (let x = 0; x < this.ListaDetalles.length; x++) {
          if(types.indexOf(this.ListaDetalles[x].phObjectId) == -1){
            types.push(this.ListaDetalles[x].phObjectId)
          }
          
        }
        this.SplitDivisions = []
        types.forEach(e => {
          
          var x = []
          this.ListaDetalles.forEach(e2 => {
            if(e2.phObjectId == e){
              x.push(e2)
            }
          });
          this.SplitDivisions.push(x)
        });
      console.log(this.PO);
    }

    Registrar(){
      if(this.EAN13.length < 13){
        this.toastr.Error("Ingrese un EAN13 válido.");
        return;
      }
      if(this.Quantity <= 0){
        this.toastr.Error("Ingrese una cantidad mayor a cero.");
        return;
      }
      
      var obj = {
        EAN13:this.EAN13,
        POHdrObjectId:this.PO.objectId,
        ItemUnitOfMeasureObjectId:this.Medida,
        Quantity:this.Quantity,
        Price:this.Price
      }

      this.spinnerService.show();
      this.pservice.CrearDetalle(obj).subscribe(data => {

        this.spinnerService.hide();
        /*
          this.actual = ""

          this.voted.emit("Test")
        */
      },
        error => {
          this.spinnerService.hide();
          this.toastr.Error("Error al insertar");
        });

      
    }

    Calcular(){

      if(this.EAN13.length < 8){
        this.toastr.Error("Ingrese un EAN13 válido.");
        return;
      }
      if(this.Quantity <= 0){
        this.toastr.Error("Ingrese una cantidad mayor a cero.");
        return;
      }
      
      var obj = {
        EAN13:this.EAN13,
        CPMObjectId:parseInt(this.PO.customerPartyMasterObjectId),
        POHDrObjectId:parseInt(this.PO.objectId),
        PODate:this.PO.poDate,
        PONumber:parseInt(this.PO.poNumber),
        POQuantity:this.Quantity,
        ObjectId:this.edit,
        UserObjectId:this.Auth.objectId
      }

      this.spinnerService.show();
      this.pservice.ObtenerPrecios(obj).subscribe(data => {

        this.flag = "s"
        this.GridData = data
        this.spinnerService.hide();
        if(this.GridData.length == 0){
          this.toastr.Error("EAN13 Inválido");
          return
        }
        
        if(data[0].error){
          this.toastr.Error(data[0].error)
        } else{
          this.Total = data[0].netTotalAmount;
          this.Nombre = data[0].imName
          this.Medida1 = data[0].um
          this.Medida2 = data[0].umeaN13
        }
        /*
          this.actual = ""

          this.voted.emit("Test")
        */
      },
        error => {
          this.Total = ""
          this.spinnerService.hide();
          this.toastr.Error("Error al insertar");
        });

      
    }

    Confirmar(){
      var obj = {
        ObjectId:this.PODtlObjectId,
        ObjectId2:this.edit,
        type:'Confirm',
        String1:this.Auth.objectId
      }
      console.log(obj);

      this.spinnerService.show();
      this.pservice2.PortalQueries(obj).subscribe(data => {
        this.Total = ""
        this.Nombre = ""
        this.Medida1 = ""
        this.Medida2 = ""
        this.flag = ""
        this.selectedObjectId = 0
        this.fNombre = ""
        this.fEAN13 = ""
        this.fCode = ""
        this.fDUN14 = ""
        this.fDUN14T = ""
        this.Cantidad = ""

        this.spinnerService.hide();
        if(this.edit > 0){
          this.toastr.Success("Cantidad de recurso modificada con éxito")
          this.edit = 0;
           this.ListaDetalles = data[0]
           this.spinnerService.hide();
           this.actual = "detalles"
           this.ClearData()
        }else{
          this.toastr.Success("Recurso agregado con éxito")
        }

      },
        error => {
          this.handleError(error);
        });
    }

    editar(e){
      console.log(e);
      var obj = {
        ObjectId:e.itemMasterObjectId,
        ObjectId2:e.objectId,
        type:'Edit'
      }
      console.log(obj);
      
      this.spinnerService.show();
      this.pservice2.PortalQueries(obj).subscribe(data => {
        console.log(data)
        this.GridData = data[0]
        this.edit = e.objectId;
        this.selectedObjectId = e.itemMasterObjectId
        /*
        this.EAN13 = e.eaN13;
        this.Quantity = e.customerPOQuantity;
        this.Nombre = e.longDescription;
        this.Total = e.netTotalAmount;
        this.Medida1 = e.um;
        */
       this.fNombre = data[0][0].description
       this.fEAN13 = data[0][0].eaN13
       this.fDUN14 = data[0][0].duN14
       this.fDUN14T = data[0][0].duN14T
       this.fCode = data[0][0].code
       this.Total = data[0][0].netTotalAmount;
       this.Cantidad = data[0][0].cantidad;
        this.actual = "principal"
        this.spinnerService.hide();
      },
        error => {
          this.handleError(error);
        });

      
      

    }
    eliminar(e){
      var obj = {
        ObjectId:this.PO.objectId,
        ObjectId2:e.objectId,
        type:'Remove',
        String1:this.Auth.objectId
      }
      console.log(obj);
      
      this.spinnerService.show();
      this.pservice2.PortalQueries(obj).subscribe(data => {
        console.log(data);
        this.ListaDetalles = data[0];
        this.spinnerService.hide();
        this.toastr.Success("Recurso removido con éxito")
      },
        error => {
          this.handleError(error);
        });

      
      

    }

    combo(e,o){

      if(o == "IEU"){
        if(e == "0"){
          this.xListaITM = this.ListaITM
          this.xListaIMC = this.ListaIMC
          this.selectedIMC = "0"
          this.selectedITM = "0"
          return
        }

        //Limpiar ITM
        this.xListaITM = []
        this.ListaITM.forEach(el => {
          if(el.itemEnterpriseUnitObjectID == e){
            this.xListaITM.push(el)
          }
        });

        //Limpiar IMC
        this.xListaIMC = []
        this.xListaITM.forEach(ele => {
          this.ListaIMC.forEach(el => {
            if(el.itemTradeMarkObjectID == ele.objectId){
              this.xListaIMC.push(el)
            }
          });
        });
      }else if(o == "ITM"){
        
        if(e == "0" && this.selectedIEU == "0"){
          this.xListaIMC = this.ListaIMC
          this.selectedIMC = "0"
          return
        }

        
        if(e == "0"){
          //Limpiar IMC
          this.xListaIMC = []
          this.xListaITM.forEach(ele => {
            this.ListaIMC.forEach(el => {
              if(el.itemTradeMarkObjectID == ele.objectId){
                this.xListaIMC.push(el)
              }
            });
          });
          this.selectedIMC = "0"
          return;
        }
        
        this.xListaIMC = []
        this.ListaIMC.forEach(el => {
          if(el.itemTradeMarkObjectID == e){
            this.xListaIMC.push(el)
          }
        });

       



      }


    }
    

    agregar2(){
      var obj = {
        POHdrObjectId:this.PO.objectId,
        Code:'',
        Nombre:'',
        EAN13:'',
        DUN14:'',
        DUN14T:''
      }
      this.spinnerService.show();
      this.pservice2.PPConsultaRecursosFiltros(obj).subscribe(data => {
        console.log(data);
        this.DataRecursos = data;
        this.resumenCostos = this.DataRecursos[4][0]
        console.log(this.resumenCostos);
        this.DataRecursos["POHdrObjectId"] = this.PO.objectId
        this.ListaIEU = this.DataRecursos[1]
        this.ListaITM = this.DataRecursos[2]
        this.ListaIMC = this.DataRecursos[3]
        this.xListaIEU = this.DataRecursos[1]
        this.xListaITM = this.DataRecursos[2]
        this.xListaIMC = this.DataRecursos[3]
        
        this.ListaRecursos = this.DataRecursos[0]
        if(this.ListaRecursos.length == 300){
          this.toastr.Info("Límite de recursos por consulta: 300")
        }
        this.spinnerService.hide();
        this.actual = 'principal'
      },
        error => {
          this.handleError(error);
        });
      
    }

    editarCliente(){

      this.spinnerService.show();
      var obj = {
        ECPMObjectId:this.PO.ediCustomerPartyMasterObjectId,
        RGObjectId:0,
        PPObjectId:0,
        CCObjectId:0,
        Codigo:'',
        Tienda:'',
        TiendaST3:'',
        Proveedor:'',
        type:'GetCPMByEDIShipToReferenceType',
        CPMObjectId:this.PO.customerPartyMasterObjectId
      }
  
      this.pservice2.PP_NuevoPedido_Queries(obj).subscribe(data => {
        this.Data.SelectedECPM = this.PO.ediCustomerPartyMasterObjectId;
        this.Data.RGList = data[0]
        this.Data.PPList = data[1]
        this.Data.CHCList = data[2]
        this.Data.CPMCode = data[4][0]["code"]
        if(data[3][0]["tienda ST3"] == null){
          this.Data.nListaCPM = data[3]
          this.Data.nListaCPM.forEach(e => {
            var x = e.branchPartyName.trim()
            if(x.length > 0){
                e.code = e.code.trim();
                e.description = e.description.trim();
                e.branchPartyName = e.branchPartyName.trim();
            }
          });

        }else{
          this.Data.ListaCPMS = data[3]
          this.Data.ListaCPMS.forEach(e => {
            var x = e.branchPartyName.trim()
            if(x.length > 0){
                e.description = e.branchPartyName.trim();
            }
          });
        }
        
        this.Data.BranchParty= this.PO.branchPartyNumber;
        this.Data.BranchPartyEAN = this.PO.branchPartyEAN;
        this.Data.PONumber = this.PO.poNumber
        this.spinnerService.hide()
        this.Data.type = 'editPO'
        this.actual = 'editPO'
      
      },
        error => {
          this.handleError(error);
        });
    }


    selectRecurso(e){
      console.log(e);
      this.selectedObjectId = e.objectID
      this.fNombre = e.description
      this.fEAN13 = e.eaN13
      this.fCode = e.code
      this.fDUN14 = e.duN14
      this.fDUN14T = e.duN14T
    
    }

    Filtrar(){
      if(!(this.DataRecursos.POHdrObjectId > 0)){
        this.DataRecursos.POHdrObjectId = this.PO.objectId
      }
      var xselectedIEU = "0"
      for (let i = 0; i < this.xListaIEU.length; i++) {
        if(this.xListaIEU[i].objectId == parseInt(this.selectedIEU)){
          xselectedIEU = this.selectedIEU
        }  
      }
      var xselectedITM = "0"
      for (let i = 0; i < this.xListaITM.length; i++) {
        if(this.xListaITM[i].objectId == parseInt(this.selectedITM)){
          xselectedITM = this.selectedITM
        }  
      }
      var xselectedIMC = "0"
      for (let i = 0; i < this.xListaIMC.length; i++) {
        if(this.xListaIMC[i].objectId == parseInt(this.selectedIMC)){
          xselectedIMC = this.selectedIMC
        }  
      }

      var obj = {
        POHdrObjectId:this.DataRecursos.POHdrObjectId,
        Code:this.fCode,
        ItemEnterpriseUnitObjectId:this.selectedIEU,
        ItemMarketClassificationObjectId:this.selectedIMC,
        ItemTradeMarkObjectId:this.selectedITM,
        Nombre:this.fNombre,
        EAN13:this.fEAN13,
        DUN14:this.fDUN14,
        DUN14T:this.fDUN14T
      }
      console.log(obj)
      this.spinnerService.show();
      this.pservice2.PPConsultaRecursosFiltros(obj).subscribe(data => {
        var x  = data;
        console.log(x);
        //this.ListaIEU = x[1]
        //this.ListaITM = x[2]
        //this.ListaIMC = x[3]
        this.ListaRecursos = x[0]
        if(this.ListaRecursos.length == 300){
          this.toastr.Info("Límite de recursos por consulta: 300")
        }
        this.spinnerService.hide();
      },
        error => {
          this.handleError(error);
        });
    }

    volver(){
      
      this.activatedRoute.params.subscribe(params => {
        
        if(params["id"] && this.actual == "detalles"){
          this.router.navigate(['default/pedidos/pedidosconsulta/'])
        }else{
          
          if(this.actual == "detalles"){

            this.ClearData()
            this.actual = ""
            this.voted.emit("v")
    
         }
         this.spinnerService.show();
         var obj = {
           ObjectId:this.PO.objectId,
           type:'GetDetails'
         }
         console.log(obj)
         this.pservice2.PortalQueries(obj).subscribe(data => {
           console.log("ffff");
           this.edit = 0;
           this.ListaDetalles = data[0]
           this.spinnerService.hide();
           this.actual = "detalles"
           this.ClearData()
         },
           error => {
             this.handleError(error);
           });
          
        
        }
      });
    }
     

    Calcularr(){
      console.log(this.Cantidad);
      if(this.Cantidad == "" || parseInt(this.Cantidad) == 0){
        this.toastr.Error("Ingrese la cantidad del recurso.")
        return
      }
      var obj = {
        POHdrObjectId:this.PO.objectId,
        ItemMasterObjectId:this.selectedObjectId,
        Cantidad:parseInt(this.Cantidad),
        UserObjectId:this.Auth.objectId
      }
      console.log(obj);
      
      this.spinnerService.show();
      this.pservice2.PPNUEVORECURSO(obj).subscribe(data => {
        console.log(data);
        this.GridData = data[0]
        this.Total = data[1][0].netTotalAmount
        this.PODtlObjectId = data[1][0].objectId
        var x = data[2]
        this.sumaTotal = this.Total
        x.forEach(e => {
          if(e.taxCode.toString().charAt(0) == "G"){
            this.iva = e
            this.sumaTotal += e.taxAmount
          }else if(e.taxCode.toString().charAt(0) == "I"){
            this.ieps = e
            this.sumaTotal += e.taxAmount
          }
        });
        this.spinnerService.hide();
      },
        error => {
          this.handleError(error);
        });
        
    }

    splits(){
      
      this.spinnerService.show();

      var obj = {
        ObjectId:this.PO.objectId,
        type:'Splits'
      }


      this.pservice2.PortalQueries(obj).subscribe(data => {
      
        //this.ListaDetalles = data[0]
        var types = []
        this.ListaDetalles = data[0];
        console.log(this.ListaDetalles);
        
       
        for (let x = 0; x < this.ListaDetalles.length; x++) {
          if(types.indexOf(this.ListaDetalles[x].phObjectId) == -1){
            types.push(this.ListaDetalles[x].phObjectId)
          }
          
        }
        this.SplitDivisions = []
        types.forEach(e => {
          
          var x = []
          this.ListaDetalles.forEach(e2 => {
            if(e2.phObjectId == e){
              x.push(e2)
            }
          });
          this.SplitDivisions.push(x)
        });
        /*
        this.DataRecursos = data[0];
        console.log(this.DataRecursos);
        
       
        for (let x = 0; x < this.DataRecursos.length; x++) {
          if(types.indexOf(this.DataRecursos[x].phObjectId) == -1){
            types.push(this.DataRecursos[x].phObjectId)
          }
          
        }

        types.forEach(e => {
          
          var x = []
          this.DataRecursos.forEach(e2 => {
            if(e2.phObjectId == e){
              x.push(e2)
            }
          });
          this.SplitDivisions.push(x)
        });
        */
        console.log(types);  
        console.log(this.SplitDivisions);  
        
        this.spinnerService.hide();

      },
        error => {
          this.handleError(error);
        });
    }

    cRecurso(e, i){
      console.log(e)

      let types = []
        for (let x = 0; x < this.ListaDetalles.length; x++) {
          if(types.indexOf(this.ListaDetalles[x].phObjectId) == -1 && this.ListaDetalles[x].paymentDays == e.paymentDays){
            types.push(this.ListaDetalles[x].phObjectId)
          }
          
        }
        this.SplitDivisions = []
        types.forEach(e => {
          
          var x = []
          this.ListaDetalles.forEach(e2 => {
            if(e2.phObjectId == e){
              x.push(e2)
            }
          });
          this.SplitDivisions.push(x)
        });
      if(i < 3){
        console.log("ctabb");
        this.cTab = i+1
      }
      
    }

    Cancel(){
      this.selectedObjectId = 0
      this.Cantidad = ""
      this.Total = ""
    }

    numberOnly(event, campo:string = ""): boolean {
      const charCode = (event.which) ? event.which : event.keyCode;
      /*
      if(charCode == 46 && campo == "cant"){
        if(this.Quantity.indexOf(".") >= 0){
          return false;
        }
        return true
      }
      */
      if(charCode == 46 && campo == "precio"){
        if(this.Price.indexOf(".") >= 0){
          return false;
        }
        if(this.Price.length > 0){
          return true
        }else{
          this.Price = "0"
          return true
        }
        
      }


      if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        return false;
      }
      return true;
  
    }

    
    selectTab(num){
      this.cTab = num
      if(num ==1){
        var types = []
        
       
        for (let x = 0; x < this.ListaDetalles.length; x++) {
          if(types.indexOf(this.ListaDetalles[x].phObjectId) == -1){
            types.push(this.ListaDetalles[x].phObjectId)
          }
          
        }
        this.SplitDivisions = []
        types.forEach(e => {
          
          var x = []
          this.ListaDetalles.forEach(e2 => {
            if(e2.phObjectId == e){
              x.push(e2)
            }
          });
          this.SplitDivisions.push(x)
        });
      }
    }

    ClearData(){
      this.GridData = []
        this.EAN13 = ""
        this.Quantity = ""
        this.Total = ""
        this.Nombre = ""
        this.Medida = ""
        this.Medida1 = ""
        this.edit = 0;
        this.flag = ""
        this.ListaRecursos = []
        this.ListaIEU = []
        this.ListaITM = []
        this.ListaIMC = []
        this.xListaIEU = []
        this.xListaITM = []
        this.xListaIMC = []
        this.selectedIEU = "0"
        this.selectedITM = "0"
        this.selectedIMC = "0"
        this.Cantidad = ""
        this.selectedObjectId = 0
        this.PODtlObjectId = 0
        this.fCode = ""
        this.fNombre = ""
        this.fEAN13 = ""
        this.fDUN14 = ""
        this.fDUN14T = ""
    }
 

    private handleError(error: any) {

      this.spinnerService.hide();
      if (error.status == 401) {
        localStorage.removeItem('auth');
        this.router.navigate(['login'])
      }
      this.toastr.Error("Error al cargar la información");
  
    };

}
