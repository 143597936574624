import { Component, OnInit } from '@angular/core'; 
import { ProcesarArchivosService } from 'src/app/services/bi/operacion/procesar-archivos.service';
import { HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';

@Component({
    selector: 'app-procesar-archivos',
    templateUrl: './procesar-archivos.component.html',
    styleUrls: [
        './procesar-archivos.component.scss'
    ],
    providers:[
        ProcesarArchivosService
    ]
}) 
export class ProcesarArchivosComponent implements OnInit {  
    timeouts: any;
    TableList: any = [];

    constructor(
        private service: ProcesarArchivosService, 
        private router: Router
    ) {}

    redirectCargarArchivos() {
        this.router.navigate(['operacion/procesar-archivos/cargar']);
    }

    ngOnInit() {
        this.initTableData();
    }

    initTableData() {
        this.service.getFiles().subscribe(
            (data) => this.TableList = data,
            (error) => this.handleError(error)
        );
    }
    
    procesarArchivo(row){
        this.service.postFiles(row).subscribe(
            (data) => console.log(data),
            (error) => this.handleError(error)
        );
    }

    private handleError(error: HttpErrorResponse) {

        if (error.error instanceof ErrorEvent) {
            // A client-side or network error occurred. Handle it accordingly.
            console.log('An error occurred:', error.error.message);
        } else {
            // The backend returned an unsuccessful response code.
            // The response body may contain clues as to what went wrong,
            console.log(
            `Backend returned code ${error.status}, ` +
            `body was: ${error.error}`);
        }
    }



    onPage(event) {
        clearTimeout(this.timeouts);
        this.timeouts = setTimeout(() => {
        }, 100);
      }


}
