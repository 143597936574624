import { ResourceService } from 'src/app/shared/resource.service';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

export interface BadgeItem {
    type: string;
    value: string;
}

export interface ChildrenItems {
    state: string;
    target?: boolean;
    name: string;
    type?: string;
    children?: ChildrenItems[];
}

export interface MainMenuItems {
    state: string;
    short_label?: string;
    main_state?: string;
    target?: boolean;
    name: string;
    type: string;
    icon: string;
    badge?: BadgeItem[];
    children?: ChildrenItems[];
}

export interface Menu {
label: string;
main: MainMenuItems[];
}

const MENUITEMS = [{"label":"mis opciones","main":[{"icon":"ti-layout-grid2-alt","name":"Notificaciones","short_label":"","state":"default","type":"sub","children":[{"name":"Generales","state":"notificaciones","type":"sub","children":[{"state":"procesar-archivos","name":"Archivos"},{"state":"consulta","name":"Consulta"}]}]},{"icon":"fa fa-cloud-download","name":"Sat -Cfdi","short_label":"","state":"default","type":"sub","children":[{"name":"Descarga","state":"cfdi","type":"sub","children":[{"state":"companias","name":"Compañías"}]}]}]}];

/*
const MENUITEMS = [{
        label: 'mis Opciones',
        main: [
            {
                state: 'default',
                short_label: 'O',
                name: 'Notificaciones',
                type: 'sub',
                icon: 'ti-layout-grid2-alt',
                children: [
                    {
                        state: 'consulta',
                        name: 'Consulta'
                    }

                ]
            }, 
            {
                state: 'estado',
                short_label: 'M',
                name: 'Menu Levels',
                type: 'sub',
                icon: 'ti-direction-alt',
                children: [
                   {
                    state: 'default',
                    name: 'LEVEL ONE',
                    type: 'sub',
                    children: [
                      {
                        state: 'consulta',
                        name: 'Menu Level 2.2.1',
                        target: false
                      },
                      {
                        state: 'consulta2',
                        name: 'Menu Level 2.2.2',
                        target: true
                      }
                    ]
                  }
                ]
              },
            {
                state: 'operacion',
                short_label: 'F',
                name: 'Procesar Archivos',
                type: 'sub',
                icon: 'fa fa-cloud-upload',
                children: [
                    {
                        state: 'procesar-archivos',
                        name: 'Procesar'
                    } 
                ]
            }, 
            {
                state: 'comprobantes',
                short_label: 'C',
                name: 'Integración Cfdi-Sat',
                type: 'sub',
                icon: 'fa fa-copy',
                children: [
                    {
                        state: 'companias',
                        name: 'Compañías'
                    }
                ]
            }
        ]
    }
];
*/

@Injectable()
export class MenuItems {


    constructor(private api: ResourceService, private router: Router) {
    }


    getAll(): Menu[] {
        return MENUITEMS; 
    }

    // getAllByIdUser(Id:any): Menu[] {
    //     return MENUITEMS;
    // } 

    GetMenu() {
        return this.api.AccessMenu('sesion',{});
         
      }
    
    

}
