import { Injectable } from '@angular/core'
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router'
import { LoginService  } from '../../services/self/seguridad/login.service'

@Injectable()
export class AuthGuardService implements CanActivate {

    constructor(
        private auth: LoginService, 
        private router: Router
    ) {}

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) { 

        const logged = this.auth.isLoggedIn()

        if (route.routeConfig.path === 'login' && logged) {
            this.router.navigate(['home']);
            return false;
        }
        else if (route.routeConfig.path === 'login' && !logged){
            return true;
        }
        if(logged){
            return true;
        }

        this.router.navigate(['login'])

        return false;
    }

}
