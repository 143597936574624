import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import 'rxjs/add/operator/map';
@Injectable({
  providedIn: 'root'
})
export class ConsultaService {

  private httpOptions2 = {
    headers: new HttpHeaders({
      "Content-Type": "application/json"
    })
  };

  constructor(private http: HttpClient) { }



  public get(id) {
    return this.http.get(`${environment.apiUrl}/transactions/consulta/solicitudes/${id}/3`)
      .map(res => res);
  }

  public getPaquetes(id) {
    return this.http.get(`${environment.apiUrl}/transactions/consulta/${id}`)
      .map(res => res);
  }

  public getDetPaquete(id) {
    return this.http.get(`${environment.apiUrl}/transactions/consulta/paquete/${id}/3`)
      .map(res => res);
  }

  public async getXML2(ruta, sol, idpaq, idxml): Promise<Blob> {
    try {

      const file = await this.http.get<Blob>(`${environment.apiUrl}/transactions/xmlfile/${ruta}/${sol}/${idpaq}/${idxml}`, { responseType: 'blob' as 'json' }).toPromise();
      return file;
    }
    catch{
      (error => console.log(error))

    }


  }

  public async getXML3(idComp): Promise<Blob> {
    try {

      const file = await this.http.get<Blob>(`${environment.apiUrl}/transactions/xmlfile/${idComp}`, { responseType: 'blob' as 'json' }).toPromise();
      return file;
    }
    catch{
      (error => console.log(error))

    }


  }
  public async getXML(uuid): Promise<any> {
    try {
      var obj = {
        uuid:uuid
      }

      const file = await this.http.post(`${environment.apiUrl}/transactions/xmlfile`, obj, this.httpOptions2).toPromise();
      return file;
    }
    catch{
      (error => console.log(error))

    }


  }

  public ObtenerListaComprobantes(uuid) {
    var obj = {
      uuid:uuid
    }
    return this.http.post(`${environment.apiUrl}/transactions/xmlfile/lista`, obj, this.httpOptions2);
  }

  public async getXMLByObjId(objid): Promise<any> {

    try {
      const file = await this.http.get(`${environment.apiUrl}/transactions/xmlfile/getXML/${objid}`, { responseType: 'blob' as 'json' }).toPromise();
      return file;
    }
    catch{
      (error => console.log(error))

    }


  }
  public Process(objid) {
    try {
      return this.http.post(`http://172.29.3.159:80/api/daemontransaction/Bot/run`, objid, this.httpOptions2);

    }
    catch{
      (error => console.log(error))

    }


  }

  public CheckProcess() {
    try {
      return this.http.get(`http://172.29.3.159:80/api/daemontransaction/Bot/CheckProcess`, this.httpOptions2);

    }
    catch{
      (error => console.log(error))

    }


  }


/*
public get(id, pag) {
  return this.http.get(`${environment.apiUrl}/consulta/solicitudes/${id}/${pag}`)
}

public getPaquetes(id) {
  return this.http.get(`${environment.apiUrl}/consulta/${id}`)
    .map(res => res);
}

public getDetPaquete(id, pag) {
  return this.http.get(`${environment.apiUrl}/consulta/paquete/${id}/${pag}`)
}

public async getXML2(ruta, sol, idpaq, idxml): Promise<Blob> {
  try {
    const file = await this.http.get<Blob>(`${environment.apiUrl}/xmlfile/${ruta}/${sol}/${idpaq}/${idxml}`, { responseType: 'blob' as 'json' }).toPromise();
    return file;
  }
  catch{
    (error => console.log("ERROR en getXML2"))

  }

}
*/
public getPager(totalItems: number, currentPage: number = 1, pageSize: number = 10) {
  // calculate total pages
  let totalPages = Math.ceil(totalItems / pageSize);

  // ensure current page isn't out of range
  if (currentPage < 1) {
      currentPage = 1;
  } else if (currentPage > totalPages) {
      currentPage = totalPages;
  }

  let startPage: number, endPage: number;
  if (totalPages <= 10) {
      // less than 10 total pages so show all
      startPage = 1;
      endPage = totalPages;
  } else {
      // more than 10 total pages so calculate start and end pages
      if (currentPage <= 6) {
          startPage = 1;
          endPage = 10;
      } else if (currentPage + 4 >= totalPages) {
          startPage = totalPages - 9;
          endPage = totalPages;
      } else {
          startPage = currentPage - 5;
          endPage = currentPage + 4;
      }
  }

  // calculate start and end item indexes
  let startIndex = (currentPage - 1) * pageSize;
  let endIndex = Math.min(startIndex + pageSize - 1, totalItems - 1);

  // create an array of pages to ng-repeat in the pager control
  let pages = Array.from(Array((endPage + 1) - startPage).keys()).map(i => startPage + i);

  // return object with all pager properties required by the view
  return {
      totalItems: totalItems,
      currentPage: currentPage,
      pageSize: pageSize,
      totalPages: totalPages,
      startPage: startPage,
      endPage: endPage,
      startIndex: startIndex,
      endIndex: endIndex,
      pages: pages
  };
}

}
