import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { ConsultaService } from 'src/app/services/bi/comprobantes/consulta.service';
import { Router } from '@angular/router';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { AuthService } from 'angular-6-social-login';
import { ScreenActionsService } from 'src/app/services/self/seguridad/actions.service';
import { CommontoastrService } from 'src/app/shared/toastr/commontoastr.service';

@Component({
  selector: 'app-recuperarxml',
  templateUrl: './recuperarxml.component.html',
  styleUrls: ['./recuperarxml.component.scss']
})
export class RecuperarxmlComponent implements OnInit {
  
  @Output() padre = new EventEmitter();
  @Input() UUID : any;
  //uuid = "F8739BA2-0B3E-4839-A371-926C6338722D"
  uuid = ""
  ListaComprobantes : any = []


  constructor(private consultaservice:ConsultaService,private router: Router,
    private toastr: CommontoastrService, private spinnerService: Ng4LoadingSpinnerService
    ,private ScreenActionsService: ScreenActionsService, private AuthService: AuthService,) { }

  ngOnInit() {
    console.log("uuid");
    console.log(this.UUID);
    if (this.UUID != ""){
      this.uuid = this.UUID
      this.ObtenerListaComprobantes()
    }
  }

  ObtenerListaComprobantes(){
    this.spinnerService.show();
    this.consultaservice.ObtenerListaComprobantes(this.uuid).subscribe(data => {
      
      this.ListaComprobantes = data
      for (let i = 0; i < this.ListaComprobantes.length; i++) {
        this.ListaComprobantes[i].fecha = this.ListaComprobantes[i].fecha.split("T");
        this.ListaComprobantes[i].fecha = this.ListaComprobantes[i].fecha[0]
      }
      console.log(data)
      this.spinnerService.hide();
    },
      error => {
        this.handleError(error);
      });
  }


  public async BuscarComprobante():Promise<void> {

  
    this.spinnerService.show();
    const data = await this.consultaservice.getXML3(this.uuid);
    console.log(data)
    /*
    data.forEach(e => {
      const blob = new Blob([e], { type: 'application/xml' });  //excel  application/vnd.openxmlformats-officedocument.spreadsheetml.sheet
      const url = window.URL.createObjectURL(blob);
      this.spinnerService.hide();
      window.open(url);

    });
          */


  
}

private handleError(error: any) {
  console.log(error)
  this.spinnerService.hide();
  if (error.status == 401) {
    localStorage.removeItem('auth');
    this.router.navigate(['login'])
  }
  this.toastr.Error("Error al cargar la información");

};

  public async recuperar(obj):Promise<void> {
    this.spinnerService.show();
    const data = await this.consultaservice.getXMLByObjId(obj.objectId);
    this.spinnerService.hide();
    const blob = new Blob([data], { type: 'application/xml' });  //excel  application/vnd.openxmlformats-officedocument.spreadsheetml.sheet
    const url = window.URL.createObjectURL(blob);
    window.open(url);
  }

  onPage($event){
    console.log(event);
  }
}
