import { Component, OnInit, Input, EventEmitter, Output, ViewEncapsulation } from '@angular/core';
import { ConsultaService } from 'src/app/services/bi/comprobantes/consulta.service';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';

@Component({
  selector: 'app-gridreportes',
  templateUrl: './gridreportes.component.html',
  styleUrls: ['./gridreportes.component.scss', '../../../../../../assets/icon/icofont/css/icofont.scss'],
  encapsulation: ViewEncapsulation.None
})
export class GridreportesComponent implements OnInit {
  @Input() public GridData: any ="";
  @Input() public actual: any ="";
  @Output() voted = new EventEmitter<boolean>();
  constructor(private service: ConsultaService, private spinnerService: Ng4LoadingSpinnerService) { 
  }

  ngOnInit() {
  }

  volver(){
    this.voted.emit(false);
  }

  public async getXML(id): Promise<void> {
    id = id.objectId;

    this.spinnerService.show();
    const data = await this.service.getXML3(id);
    
    const blob = new Blob([data], { type: 'application/xml' });  //excel  application/vnd.openxmlformats-officedocument.spreadsheetml.sheet
    const url = window.URL.createObjectURL(blob);
    this.spinnerService.hide();
    window.open(url);


  }
/*

assets/icon/icofont/css/icofont.scss'
*/

}
