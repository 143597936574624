import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { CommonModule } from '@angular/common';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations'; 
 
import {NgxDatatableModule} from '@swimlane/ngx-datatable';
import {ToastyModule} from 'ng2-toasty'; 

import { FormsModule } from '@angular/forms'; 
import { ReactiveFormsModule } from '@angular/forms';
import { DataTablesModule } from 'angular-datatables';

import { NgSelectModule } from '@ng-select/ng-select';
import { Ng4LoadingSpinnerModule } from 'ng4-loading-spinner'; 

import { EstadosPipe } from 'src/app/pipes/estados/estados.pipe';
import { AuthGuardService } from 'src/app/shared/auth-guard/auth-guard.service';
import { PrincipalComponent } from './principal/principal.component';
import { NuevopedidoComponent } from './nuevopedido/nuevopedido.component';
import { ConsultaComponent } from './consulta/consulta.component';


@NgModule({
  imports: [
    BrowserModule, 
    BrowserAnimationsModule,
    CommonModule,
    NgSelectModule,
    FormsModule,
    ReactiveFormsModule,
    DataTablesModule,
    NgxDatatableModule,
    ToastyModule.forRoot(),
    Ng4LoadingSpinnerModule.forRoot()
  ],
  declarations: [
    PrincipalComponent,
    NuevopedidoComponent,
    ConsultaComponent
  ],
  exports: [ 
    PrincipalComponent
  ],
  providers: [
    AuthGuardService
  ]
})
export class PedidosModule { }
