import { Component, OnInit, NgModule } from '@angular/core';
import {StatusComponent} from "./status/status.component";
import { LoginComponent } from '../seguridad/login/login.component';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms'; 
import { ReactiveFormsModule } from '@angular/forms';

@NgModule({
    imports:      [ CommonModule
      ,FormsModule
      ,ReactiveFormsModule ],
    declarations: [ StatusComponent, LoginComponent ],
    exports:      [ StatusComponent, LoginComponent ]
   })

export class CatalogosModule  {

    constructor() { }
  }