import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { AdminComponent } from "./layout/admin/admin.component";
import { LoginComponent } from "./components/self/seguridad/login/login.component";
import { AuthGuardService } from "./shared/auth-guard/auth-guard.service";
//Componentes Modulo Operacion
import { NotificacionesComponent } from "./components/bi/operacion/notificaciones/notificaciones.component";
import { ProcesarArchivosComponent } from "./components/bi/operacion/procesar-archivos/procesar-archivos.component";
import { CargarArchivosComponent } from "./components/bi/operacion/cargar-archivos/cargar-archivos.component";
import { ReglasAprobadasComponent } from "./components/bi/operacion/reglas-aprobadas/reglas-aprobadas.component"

//Componentes Modulo Comprobantes
import { RfcsComponent } from "./components/bi/comprobantes/companias/rfcs/rfcs.component";
import { PrincipalComponent } from "./components/bi/comprobantes/principal/principal.component";
import { PrincipalComponent as PC } from "./components/bi/pedidos/principal/principal.component";
import { ConsultaComponent } from "./components/bi/comprobantes/companias/consulta/consulta.component";
import { ConsultaComponent as CC } from "./components/bi/pedidos/consulta/consulta.component";
//Componentes Modulo Conciliaciones
import { CompaniasConciliacionesComponent } from "./components/bi/conciliaciones/companias/companias.component";
import { NuevopedidoComponent } from "./components/bi/pedidos/nuevopedido/nuevopedido.component";
import { RecuperarxmlComponent } from "./components/bi/comprobantes/companias/recuperarxml/recuperarxml.component";

//Componentes PAGOS
import { PrincipalComponent as PGP } from "./components/bi/pagos/principal/principal.component";
import { PodComponent } from "./components/bi/pagos/pod/pod/pod.component";
import { TimbradoComponent } from "./components/bi/timbrado/timbrado/timbrado.component";
import { CcprincipalComponent } from './components/bi/pagos/ccprincipal/ccprincipal.component'
import { DocumentossaldosComponent } from "./components/bi/pagos/documentossaldos/documentossaldos.component";

import { ApplicationsettingsComponent } from '../app/components/bi/seguridad/applicationsettings/applicationsettings.component'
import { ApplicationsettingsbyprofileComponent } from '../app/components/bi/seguridad/applicationsettingsbyprofile/applicationsettingsbyprofile.component'
import { EdiggroupsecurityComponent } from '../app/components/bi/seguridad/ediggroupsecurity/ediggroupsecurity.component'
import { EdiguserComponent } from '../app/components/bi/seguridad/ediguser/ediguser.component'
import { EdigusergrouprelationComponent } from '../app/components/bi/seguridad/edigusergrouprelation/edigusergrouprelation.component'

import { SeguridadComponent } from '../app/components/bi/timbrado/seguridad/seguridad.component'

const routes: Routes = [
  {
    path: "",
    component: AdminComponent,
    children: [
      {
        path: "",
        redirectTo: "default",
        pathMatch: "full",
      },
      {
        path: "default",

        loadChildren: "./theme/simple-page/simple-page.module#SimplePageModule",
        canActivate: [AuthGuardService],
      },
      {
        path: "default/notificaciones/consulta",
        component: NotificacionesComponent,
      },
      {
        path: "default/notificaciones/reglasaprobadas",
        component: ReglasAprobadasComponent,
      },
      {
        path: "default/cfdi/rfcs",
        component: RfcsComponent,
      },
      {
        path: "default/cfdi/companias",
        component: PrincipalComponent,
      },
      {
        path: "default/pedidos/principal",
        component: NuevopedidoComponent,
      },
      {
        path: "default/pedidos/pedidosconsulta/:id",
        component: CC,
      },
      {
        path: "default/pedidos/pedidosconsulta",
        component: CC,
      },
      {
        path: "default/cfdi/documentos",
        component: RecuperarxmlComponent,
      },
      {
        path: "default/cfdi/consulta",
        component: ConsultaComponent,
      },
      {
        path: "default/notificaciones/procesar-archivos",
        component: ProcesarArchivosComponent,
      },
      {
        path: "operacion/procesar-archivos/cargar",
        component: CargarArchivosComponent,
      },
      {
        path: "default/conciliaciones/companiasconciliacion",
        component: CompaniasConciliacionesComponent,
      },
      {
        path: "default/pagos/principal",
        component: PGP,
      },
      {
        path: "default/pagos/conciliacioncartera",
        component: CcprincipalComponent,
      },
      {
        path: "default/pagos/pod",
        component: PodComponent,
      },
      {
        path: "default/layout/principal",
        component: TimbradoComponent
      },
      {
        path: "default/pagos/cartera",
        component: DocumentossaldosComponent
      },
      { path: "default/layout/principal", component: TimbradoComponent },
      { path: "default/layout/seguridadedicion", component: SeguridadComponent },
      {
        path: "default/seguridad/applicationsettings",
        component: ApplicationsettingsComponent
      },
      {
        path: "default/seguridad/applicationsettingsbyprofile",
        component: ApplicationsettingsbyprofileComponent
      },
      { path: "default/layout/principal", component: TimbradoComponent },
      { path: "default/layout/seguridadedicion", component: SeguridadComponent },
      {
        path: "default/seguridad/applicationsettings",
        component: ApplicationsettingsComponent
      },
      {
        path: "default/seguridad/applicationsettingsbyprofile",
        component: ApplicationsettingsbyprofileComponent
      },
      {
        path: "default/seguridad/ediguser",
        component: EdiguserComponent
      },
      {
        path: "default/seguridad/ediggroupsecurity",
        component: EdiggroupsecurityComponent
      },
      {
        path: "default/seguridad/edigusergrouprelation",
        component: EdigusergrouprelationComponent
      }
    ],
  },
  {
    path: "login",
    component: LoginComponent,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule { }
