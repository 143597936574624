import { Component, OnInit } from '@angular/core';
import { SeguridadService } from "src/app/services/bi/seguridad/seguridad.service";
import { Ng4LoadingSpinnerService } from "ng4-loading-spinner";
import { DatePipe } from "@angular/common";
import { CommontoastrService } from "src/app/shared/toastr/commontoastr.service";

@Component({
  selector: 'app-edigusergrouprelation',
  templateUrl: './edigusergrouprelation.component.html',
  styleUrls: ['./edigusergrouprelation.component.scss',
    "../../../../../assets/icon/icofont/css/icofont.scss"],
  providers: [DatePipe]
})
export class EdigusergrouprelationComponent implements OnInit {
  listaEdigGroups: any = [];
  listaBusquedaGrid: any = [];
  buscar: any ="";
  editar: any = 0;
  agregar: any = 0;
  itemSeleccionado: boolean = true;
  rowSeleccionado: any = {}
  estatus: any = 1;


  listaEmail: any = [];
  listaGrupos: any = [];

  emailId: any = 0
  usuario: any = ""
  grupoId: any = 0

  emailIdEdit: any = 0
  usuarioEdit: any = ""
  grupoIdEdit: any = 0
  estatusEdit: any = 1;
  constructor(private seguridadService: SeguridadService,
    private spinnerService: Ng4LoadingSpinnerService,
    private datePipe: DatePipe,private toastr:CommontoastrService) {
    this.cargaInicial();
  }

  guardarCambiosGrupo() {
    var body = this.rowSeleccionado;
    this.itemSeleccionado = true;
    this.seguridadService.GuardarCambiosGrupo(body).subscribe(data => {
      this.spinnerService.hide();
      this.editar = 0;
      this.cargaInicial();
      console.log(data)
    },
      error => {
        console.log(error)
        this.spinnerService.hide();
      })
  }

  detalle(row) {
    this.itemSeleccionado = false;
    this.rowSeleccionado = row;
    this.emailIdEdit = this.rowSeleccionado['edigUserObjectId']
    this.grupoIdEdit = this.rowSeleccionado['edigGroupObjectId']
    this.estatusEdit = this.rowSeleccionado['statusObjectId']
    this.usuarioEdit = this.rowSeleccionado['edigUserObject'].description
    console.log(row)
  }

  ngOnInit() {
  }

  cargaInicial() {

    this.spinnerService.show();
    this.seguridadService.ObtenerRelacionGruposLista({}).subscribe(data => {
      this.spinnerService.hide();
      this.listaEdigGroups = data;
      this.listaBusquedaGrid = data;
      // console.log(data)
      this.cargaCombos();
    },
      error => {
        console.log(error)
      })

  }

  cargaCombos() {
    this.seguridadService.ObtenerComboUsuarios({}).subscribe(data => {
      this.listaEmail = data;
    },
      error => { console.log(error) });

    this.seguridadService.ObtenerComboGrupos({}).subscribe(data => {
      this.listaGrupos = data;
    }, error => { console.log(error) })

  }
  changeEmailEdit() {
    this.listaEmail.forEach(element => {
      if (element['objectId'] == this.emailIdEdit) {
        // console.log(this.element[''])
        this.usuarioEdit = element['description']
      }
      if (this.emailIdEdit == 0) this.usuarioEdit = ""
    });
  }
  changeEmail() {
    this.listaEmail.forEach(element => {
      if (element['objectId'] == this.emailId) {
        // console.log(this.element[''])
        this.usuario = element['description']
      }
      if (this.emailId == 0) this.usuario = ""
    });
  }

  delete() {
    this.itemSeleccionado = true;
    var body = this.rowSeleccionado

    // console.log(body)
    this.spinnerService.show()
    this.seguridadService.DeleteRelacion(body).subscribe(data => {
      this.spinnerService.hide()
      this.cargaInicial();
      // console.log(data)
      this.toastr.Success("Registro Eliminado con Exito")
    },
      error => {
        console.log(error)
        this.spinnerService.hide()
        this.toastr.Error("Ha ocurrido un error")
      })
    // console.log(body)
  }

  reaload() {
    this.cargaInicial();
    this.toastr.Success("Lista actualizada")
  }

  guardarNewRelation() {
    this.itemSeleccionado = true;
    var body = {
      createdByUserObjectId: 1,
      createdDate: this.datePipe.transform(Date.now(), 'yyyy-MM-dd'),
      edigGroupObjectId: this.grupoId,
      edigUserObjectId: this.emailId,
      modifiedByUserObjectId: 1,
      modifiedDate: this.datePipe.transform(Date.now(), 'yyyy-MM-dd'),
      statusObjectId: this.estatus
    };
    this.spinnerService.show();
    this.seguridadService.GuardarNewRelacion(body).subscribe(data => {
      this.spinnerService.hide()
      this.agregar = 0;
      this.cargaInicial()
      this.grupoId = 0
      this.emailId = 0
      this.estatus = 1
      this.usuario = ""
      // console.log(data)
      this.toastr.Success("Registro Agregado con Exito")
    },
      error => { 
        console.log(error) 
        this.toastr.Error("Ha ocurrido un error")
      })


  }
  cancelar(){
    this.editar = 0
    this.grupoId = 0
    this.emailId = 0
    this.agregar = 0;
    this.estatus = 1
    this.usuario = ""
    this.itemSeleccionado = true
  }

  guardarCambiosGroupRelation() {
    this.itemSeleccionado = true;
    var body = {
      objectId: this.rowSeleccionado['objectId'],
      createdByUserObjectId: 1,
      createdDate: this.datePipe.transform(Date.now(), 'yyyy-MM-dd'),
      edigGroupObjectId: parseInt(this.grupoIdEdit),
      edigUserObjectId: this.emailIdEdit,
      modifiedByUserObjectId: 1,
      modifiedDate: this.datePipe.transform(Date.now(), 'yyyy-MM-dd'),
      statusObjectId: this.estatusEdit
    };
    console.log(body)
    this.spinnerService.show();
    this.seguridadService.GuardarCambiosRelacion(body).subscribe(data => {
      this.spinnerService.hide()
      this.editar = 0;
      this.cargaInicial()
      this.toastr.Success("Cambios Guardados con Exito")
      // console.log(data)
    },
      error => { 
        console.log(error) 
        this.toastr.Error("Ha ocurrido un Error")
      })
  }
  Buscar(){
    
    this.listaBusquedaGrid = []
    for (let i = 0; i < this.listaEdigGroups.length; i++) {
      
      for (let x = 0; x < Object.keys(this.listaEdigGroups[0]).length; x++) {
        var propiedades = Object.keys(this.listaEdigGroups[i])
        var propiedad = propiedades[x]
        var prop = Object.keys(this.listaEdigGroups[i][propiedad])
        var valor = String(this.listaEdigGroups[i][propiedad].email).toLocaleUpperCase()
        if(valor.indexOf(this.buscar.toLocaleUpperCase()) != -1){
          this.listaBusquedaGrid.push(this.listaEdigGroups[i])
          break
        }
        valor = String(this.listaEdigGroups[i][propiedad].description).toLocaleUpperCase()
        if(valor.indexOf(this.buscar.toLocaleUpperCase()) != -1){
          this.listaBusquedaGrid.push(this.listaEdigGroups[i])
          break
        }
        valor = String(this.listaEdigGroups[i][propiedad].name).toLocaleUpperCase()
        if(valor.indexOf(this.buscar.toLocaleUpperCase()) != -1){
          this.listaBusquedaGrid.push(this.listaEdigGroups[i])
          break
        }
      }
    }

  }

}
