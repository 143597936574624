import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ResourceService } from 'src/app/shared/resource.service';


@Injectable({
  providedIn: 'root'
})
export class NotificacionService {

  constructor(private api: ResourceService, private router: Router) {}

  //--------Api a local host (sin api gateway)--------------------//

  /*
  GetById(data:any) {
    return this.api.getById('Notification', data);
  }

  GetNotificationType(data:any) {
    return this.api.getById('Notification/NotificationType', data);
  }

  Post(data) {
    return this.api.post('Notification/FilterNotifications', data);
  }

  // PostPassData(data, data1, data2) {
  //   return this.api.post('Notification/FilterNotifications', data);
  // }


  GetActionByIdNotification(data:any) {
    return this.api.post('Notification/GetAction', data);
  }

  ChangeStatusNotification(data:any) {
    return this.api.post('Notification/WFMoveNotification', data);
  }

  GetTrackByIdNotification(data:any) {
    return this.api.getById('Notification/WFGetNotificationTrack', data);
  }

  GetDetailEmail(data:any) {
    return this.api.post('Notification/WFGetDetailNotification', data);
  }

  GetClients(data:any) {
    return this.api.getById('Notification/GetECPM', data);
  }
*/

//--------------------------------------------------------------------//
 

  GetById(data:any) {
    return this.api.getById('notificationservices/Notification', data);
     
  }
  GetNotificationType(data:any) {
    return this.api.getById('notificationservices/Notification/NotificationType', data);
     
  }

  Post(data) {
    return this.api.post('notificationservices/Notification/FilterNotifications', data);
  }

  GetActionByIdNotification(data:any) {
    return this.api.post('notificationservices/Notification/GetAction', data);
     
  }

    ChangeStatusNotification(data:any) {
    return this.api.post('notificationservices/Notification/WFMoveNotification', data);
     
  }

   GetTrackByIdNotification(data:any) {
    return this.api.getById('notificationservices/Notification/WFGetNotificationTrack', data);
  }
  GetReglasAprobadas(data:any) {
    return this.api.post('notificationservices/Notification/ReglasAprobadas', data);
    //return this.api.post('Notification/ReglasAprobadas', data);
  }

  GetNotificationsConfiguration(data: any){
    return this.api.get('notificationservices/Notification/NotificationsConfiguration', data);
    //return this.api.get('Notification/NotificationsConfiguration', data);
  }

  GetCatNotificationActions(data: any){
    return this.api.get('notificationservices/Notification/CatNotificationActions', data);
    //return this.api.get('Notification/CatNotificationActions', data);
  }
  
  GetDetailEmail(data:any) {
    return this.api.post('notificationservices/Notification/WFGetDetailNotification', data);
  }


   GetClients(data:any) {
    return this.api.getById('notificationservices/Notification/GetECPM', data);
  }



  
}
