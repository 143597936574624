import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { PagosService } from 'src/app/services/bi/pagos/pagos.service';
import { AuthService } from 'src/app/services/self/seguridad/auth.service';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { Router } from '@angular/router';
import { CommontoastrService } from 'src/app/shared/toastr/commontoastr.service';
import { HttpClient } from '@angular/common/http';


@Component({
  selector: 'app-detalle',
  templateUrl: './detalle.component.html',
  styleUrls: ['./detalle.component.scss', '../../../../../assets/icon/icofont/css/icofont.scss']
})
export class DetalleComponent implements OnInit {
  
  @Output() padre = new EventEmitter();
  @Input() selectedPago : any;
  @Input() filtrosP : any;
  @Input() titulo : any;
  //General
  visibleCards: any = [true,true,false,true]
  Auth: any = {};
  actual : any = ""
  hdrDetail : any = []
  listaPagos : any = []

  listaGridPrincipal : any = []
  listaBusquedaGridPrincipal : any = []
  buscar: any = ""
  selectedDS: any = {}
  //Card 0


  //Card 2
  
  filtros = {
    listaECPM :  [],
    selectedECPM :  0,
    listaCPM :  [],
    selectedCPM :  0,
    selectedClientType :  1,
    PDate1 :  "",
    PDate2 :  "",
    importemin :  "",
    importemax :  "",
    montoActual :  0,
    listaRG :  [],
    selectedRG :  0,
    listaDT :  [],
    selectedDT :  0,
    listaDST :  [],
    selectedDST :  0,
    listaDSO :  [],
    selectedDSO :  0,
    listaIS :  [],
    selectedIS :  0,
    listaPP :  [],
    xlistaPP :  [],
    selectedPP :  0,
  }

  filtrosAplicados = {
    listaECPM :  [],
    selectedECPM :  0,
    listaCPM :  [],
    selectedCPM :  0,
    selectedClientType :  1,
    PDate1 :  "",
    PDate2 :  "",
    importemin :  "",
    importemax :  "",
    montoActual :  0,
    listaRG :  [],
    selectedRG :  0,
    listaDT :  [],
    selectedDT :  0,
    listaDST :  [],
    selectedDST :  0,
    listaDSO :  [],
    selectedDSO :  0,
    listaIS :  [],
    selectedIS :  0,
    listaPP :  [],
    xlistaPP :  [],
    selectedPP :  0,
  }
  

 
  constructor(private http: HttpClient, private toastr:CommontoastrService, private pservice:PagosService, private spinnerService: Ng4LoadingSpinnerService) { 

    var data = localStorage.getItem('auth');
    if (data != undefined && data != null) {
      this.Auth = JSON.parse(data);
    }
  }

  ngOnInit() {
    if(this.titulo.length > 0){
      this.titulo += " - Detalle"
    }
    if(this.filtrosP != []){
      this.filtros.selectedECPM = this.filtrosP.selectedECPM
      this.filtros.listaPP = this.filtrosP.listaPP
      this.filtros.xlistaPP = this.filtrosP.xlistaPP
      this.filtros.listaRG = this.filtrosP.listaRG
      this.filtros.selectedPP = this.filtrosP.selectedPP
      this.filtros.selectedRG = this.filtrosP.selectedRG
      this.filtros.selectedDST = this.filtrosP.selectedDST
      //this.filtros.selectedDT = this.filtrosP.selectedDT
    }
    
    this.listaPagos = []
    this.listaPagos.push(this.selectedPago)
    console.log("Grid");
    console.log(this.listaPagos);
    //this.cargaDatos()
    this.cargaInicial()
  
  }

  cargaInicial(){
    this.spinnerService.show()
    this.pservice.ObtenerCombos({}).subscribe(data => {
      this.filtros.listaECPM = data['ecpm']
      this.filtros.listaDT = data['dt']
      this.filtros.listaDST = data['dSt']
      this.filtros.listaDSO = data['dSo']
      this.filtros.listaIS = data['is']
      if(this.selectedDS == ""){
        this.spinnerService.hide()
      }else{
        this.filtrar()
      }

    },
      error => {
        this.spinnerService.hide()
        console.log("Error");
        console.log(error);
        //this.handleError(error);
      });
  }

  filtrar(){
    var id = 0;
    if(this.selectedPago['Folio de Documento Financiero'] == undefined){
      id = this.selectedPago.Id
    }else{
      id = this.selectedPago['Folio de Documento Financiero']
    }
    if(this.filtros.selectedECPM == NaN || this.filtros.selectedECPM == null)
      this.filtros.selectedECPM = 0

      if(this.filtros.selectedECPM == NaN || this.filtros.selectedECPM == null)
      this.filtros.selectedECPM = 0
      this.filtrosAplicados.selectedECPM = this.filtros.selectedECPM;
    

    this.filtrosAplicados.listaECPM.push(this.filtros.listaECPM[this.filtros.listaECPM.findIndex(x => x.objectId == this.filtros.selectedECPM)])
    this.filtrosAplicados.listaPP = this.filtros.listaPP
    this.filtrosAplicados.xlistaPP = this.filtros.xlistaPP
    this.filtrosAplicados.listaRG = this.filtros.listaRG
    this.filtrosAplicados.selectedCPM = this.filtros.selectedCPM;
    this.filtrosAplicados.selectedRG = this.filtros.selectedRG;
     // @ts-ignore
    this.filtrosAplicados.selectedPP = parseInt(this.filtros.selectedPP);
    this.filtrosAplicados.selectedDT = this.filtros.selectedDT;
    this.filtrosAplicados.selectedDST = this.filtros.selectedDST;
    this.filtrosAplicados.selectedDSO = this.filtros.selectedDSO;
    this.filtrosAplicados.PDate1 = this.filtros.PDate1==""?'2000-01-01':this.filtros.PDate1
    this.filtrosAplicados.PDate2 = this.filtros.PDate2==""?'2022-01-01':this.filtros.PDate2
    // @ts-ignore
    this.filtrosAplicados.Monto1 = this.filtros.Monto1==""?0:parseFloat(this.filtros.Monto1)
    // @ts-ignore
    this.filtrosAplicados.Monto2 = this.filtros.Monto2==""?0:parseFloat(this.filtros.Monto2)
    // falta IS : this.filtros.SelectedIS,
    var body = {
      EDI:this.filtros.selectedECPM,
      CPM:this.filtros.selectedCPM,
      RG:this.filtros.selectedRG,
      PP:this.filtros.selectedPP,
      DT:this.filtros.selectedDT,
      parent:id,
      DSt:this.filtros.selectedDST,
      DSo:this.filtros.selectedDSO,
      PDate1:this.filtros.PDate1==""?'2000-01-01':this.filtros.PDate1,
      PDate2:this.filtros.PDate2==""?'2022-01-01':this.filtros.PDate1,
      importemin:this.filtros.importemin==""?0:parseFloat(this.filtros.importemin),
      importemax:this.filtros.importemax==""?90000000:parseFloat(this.filtros.importemax),
      ProcessObjectId:this.filtros.selectedIS
    }
    console.log(body);
    this.spinnerService.show()
    
    this.pservice.ObtenerListaPaymentDTL(body).subscribe(data => {
      console.log(data);
      this.listaGridPrincipal = data[0]
      this.listaBusquedaGridPrincipal = data[0]
      this.recargarGrid()
    },
      error => {
        this.spinnerService.hide()
        console.log("Error");
        console.log(error);
        //this.handleError(error);
      });
  }

  /////////////////---------------Metodos--------------/////////////////////
  //General
  toggleCard(e){
    this.visibleCards[e] = !this.visibleCards[e]
  }
  showpadre(e:any){
    this.selectedPago['Importe pendiente a Saldar'] = this.selectedPago['Importe pendiente a Saldar'] - e
    this.actual = ""
    //this.cargaInicial()
    this.filtrar()
    if(e.length > 0){
      this.toastr.Success("Proceso terminado correctamente")
    }
  }

  //Card 0
  relacionar(){
    this.actual = 'relacionar'
    /*
    console.log(this.selectedPago);
    var body = {
      CPM:0,
      RFC: this.selectedPago.RFC,
      Factura:"",
      PDate1:'2000-01-01',
      PDate2:'2022-01-01'
    }
    console.log(body);
    this.spinnerService.show()
    this.pservice.ObtenerRelacionesPaymentDTL(body).subscribe(data => {
      console.log(data[0]);
      //this.listaGridPrincipal = data[0]
      this.actual = 'relacionar'
      this.spinnerService.hide()
    },
      error => {
        this.spinnerService.hide()
        console.log("Error");
        //this.handleError(error);
      });
      */
  }
  volver(){
    this.padre.emit([]);
  }

  //Card 1

  //Card 2
  /*cargaDatos(){
    this.spinnerService.show()
    console.log("este->");
    console.log(this.selectedPago);
    var id = 0;
    if(this.selectedPago['Folio de Documento Financiero'] == undefined){
      id = this.selectedPago.Id
    }else{
      id = this.selectedPago['Folio de Documento Financiero']
    }
    var body = {
      parent:id,
      EDI:this.filtros.selectedECPM,
      CPM:this.filtros.selectedCPM,
      RG:this.filtros.selectedRG,
      PP:this.filtros.selectedPP,
      DT:this.filtros.selectedDT,
      DSt:this.filtros.selectedDST,
      DSo:this.filtros.selectedDSO,
      PDate1:this.filtros.PDate1==""?'2000-01-01':this.filtros.PDate1,
      PDate2:this.filtros.PDate2==""?'2022-01-01':this.filtros.PDate1,
      importemin:this.filtros.importemin==""?0:parseFloat(this.filtros.importemin),
      importemax:this.filtros.importemax==""?90000000:parseFloat(this.filtros.importemax),
      ProcessObjectId:this.filtros.selectedIS
    }
    console.log(body);
      this.pservice.ObtenerListaPaymentDTL(body).subscribe(data => {
      console.log(data);
      this.listaPagos = []
      this.listaPagos.push(this.selectedPago)
      this.listaGridPrincipal = data[0]
      if(data[0].length == 0){
        this.toastr.Info("No hay datos en la consulta")
      }
      this.filtros.listaDT = data[1]
      this.filtros.listaDSO = data[2]
      this.filtros.listaDST = data[3]
      this.filtros.listaIS = data[4]
      this.spinnerService.hide()

    },
      error => {
        this.spinnerService.hide()
        console.log("Error");
        this.toastr.Error("Error al cargar la informacion")
        //this.handleError(error);
      });
    
  }*/

  selectECPM(){
    this.spinnerService.show()
    
    this.pservice.ObtenerCombosRGyPP(this.filtros.selectedECPM).subscribe(data => {
      this.spinnerService.hide()
      this.filtros.listaPP = data['pp']
      this.filtros.xlistaPP = this.filtros.listaPP
      this.filtros.listaRG = data['rg']
      
    },
    error => {
      this.spinnerService.hide()
      console.log("Error");
      console.log(error);
      //this.handleError(error);
    });
  }

  changeRG(){
    if(this.filtros.selectedRG == 0){
      this.filtros.xlistaPP = this.filtros.listaPP
      this.filtros.selectedPP = 0;
      return
    }
    this.filtros.xlistaPP = []
    this.filtros.listaPP.forEach(e => {
      if(e.referenceGroupObjectId == this.filtros.selectedRG)
      this.filtros.xlistaPP.push(e)
    });

    if(this.filtros.xlistaPP[this.filtros.xlistaPP.findIndex(x => x.objectId == this.filtros.selectedPP)] == undefined){
      this.filtros.selectedPP = 0;
    }


  }

  changePP(){
    if(this.filtros.selectedPP == 0)
      return
    var PP = {}
    for (let i = 0; i < this.filtros.listaPP.length; i++) {
      if(this.filtros.listaPP[i].objectId == this.filtros.selectedPP)
        PP = this.filtros.listaPP[i]  
    }

    this.filtros.selectedRG = PP['referenceGroupObjectId']
    this.changeRG()
  }
  

  //Card 3
  eliminar(e:any){
    var obj = {
      UserObjectId:this.Auth.objectId,
      PaymentDTLObjectId:e.Id
    }
    var pos = this.listaGridPrincipal.map(function(ee) { return ee.Id; }).indexOf(e.Id);

    this.spinnerService.show()
    this.pservice.EliminarPaymentDTL(obj).subscribe(data => {
      console.log(data);
      this.recargarGrid()
      this.toastr.Success("Registro eliminado con éxito")
      var resultado = Object.keys(data[1][0])
      if (resultado[0] == "exito"){
        this.toastr.Success(data[1][0][resultado[0]])
      }else{
        this.toastr.Error(data[1][0][resultado[0]])
      }
      
      //this.cargaInicial()
      this.filtrar()
    },
      error => {
        this.spinnerService.hide()
        console.log("Error");
        console.log(error);
        //this.handleError(error);
      });

      
  }

  recargarGrid(){
    var body = {
      ECPMObjectId:this.filtros.selectedECPM,
      CPMObjectId:this.filtros.selectedCPM,
      RGObjectId:this.filtros.selectedRG,
      PPObjectId:this.filtros.selectedPP,
      DTObjectId:this.filtros.selectedDT,
      DSTObjectId:this.filtros.selectedDST,
      DateStart:this.filtros.PDate1==""?'2000-01-01':this.filtros.PDate1,
      DateEnd:this.filtros.PDate2==""?'2022-01-01':this.filtros.PDate2,
      AmountBalanceStart:0,
      AmountBalanceEnd:999999,
      Documento:this.selectedPago['Folio del Documento']
    }

    this.spinnerService.show()
    console.log(body);
    this.pservice.ConsultaDocumentoSaldadoPrincipal(body).subscribe(data => {
      console.log("reload grid");
      console.log(data);
      this.listaPagos = data[0]
      this.spinnerService.hide()
      
    },
      error => {
        this.spinnerService.hide()
        console.log("Error");
        console.log(error);
        //this.handleError(error);
      });

  }

  getccartera(e){
    this.hdrDetail = []
    this.hdrDetail.push(e)
    this.selectedDS = e['Folio del Documento']
    this.actual = "ccartera"
  }

  getcomplemento(e){
    var obj = {
      UserObjectId:this.Auth.objectId,
      ObjectId:e.Id
    }

    this.spinnerService.show()
    console.log(obj);
    this.pservice.ObtenerComplementoPaymentDTL(obj).subscribe(data => {
      
      this.spinnerService.hide()
      var resultado = Object.keys(data[0][0])
      console.log(data);
      console.log(resultado[0]);
      if (resultado[0] == "exito"){
        this.toastr.Success(data[0][0][resultado[0]])
      }else{
        this.toastr.Error(data[0][0][resultado[0]])
      }
      this.filtrar()
    },
      error => {
        this.spinnerService.hide()
        console.log("Error");
        console.log(error);
        //this.handleError(error);
      });
  }

  Buscar(){
    
    this.listaBusquedaGridPrincipal = []
    for (let i = 0; i < this.listaGridPrincipal.length; i++) {
      
      for (let x = 0; x < Object.keys(this.listaGridPrincipal[0]).length; x++) {
        var propiedades = Object.keys(this.listaGridPrincipal[i])
        var propiedad = propiedades[x]
        var valor = String(this.listaGridPrincipal[i][propiedad]).toLocaleUpperCase()
       
        if(valor.indexOf(this.buscar.toLocaleUpperCase()) != -1){
          this.listaBusquedaGridPrincipal.push(this.listaGridPrincipal[i])
          break
        }
          
      }
    }

  }
  
  
}



