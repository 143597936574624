import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ResourceService } from 'src/app/shared/resource.service';


@Injectable({
  providedIn: 'root'
})
export class PagosService {
  public FromShareService: any= {};

  constructor(private api: ResourceService, private router: Router) {}

  //--------() Api gateway)--------------------//
  Get(data:any) {
    //return this.api.get('payments/paymentservices/CompaniesConciliation', data);
    return this.api.get('payments/payments', data);
  }

  ObtenerCombos(data:any) {
    //return this.api.get('paymentservices/CompaniesConciliation', data);
    return this.api.get('payments/payments', data);
  }

  ObtenerCombosRGyPP(data) {
    //return this.api.get('paymentservices/CompaniesConciliation', data);
    return this.api.getById('payments/payments', data);
  }
  DSMostrarPrincipal(data) {
    //return this.api.get('paymentservices/CompaniesConciliation', data);
    return this.api.post('payments/DocumentoSaldado/principal', data);
  }

  ObtenerClienteCPM(data:any) {
    //return this.api.get('paymentservices/CompaniesConciliation', data);
    return this.api.post('payments/payments', data);
  }


  ObtenerListaPaymentHDR(data:any) {
    //return this.api.get('paymentservices/CompaniesConciliation', data);
    return this.api.post('payments/payments/PaymentHDR', data);
  }

  ObtenerListaDocumentosPrincipal(data:any) {
    //return this.api.get('paymentservices/CompaniesConciliation', data);
    return this.api.post('payments/ConciliacionCartera', data);
  }

  ConsultaDocumentoSaldadoPrincipal(data:any) {
    //return this.api.get('paymentservices/CompaniesConciliation', data);
    return this.api.post('payments/DocumentoSaldado', data);
  }

  ObtenerListaPaymentDTL(data:any) {
    //return this.api.get('paymentservices/CompaniesConciliation', data);
    return this.api.post('payments/payments/PaymentDTL/GetList',data);
  }

  ObtenerComplementoPaymentDTL(data:any) {
    //return this.api.post('PaymentDTL/GetComplemento',data);
    return this.api.post('payments/payments/PaymentDTL/GetComplemento',data);
  }

  ObtenerRelacionesPaymentDTL(data:any) {
    //return this.api.get('paymentservices/CompaniesConciliation', data);
    return this.api.post('payments/payments/PaymentDTLRel', data);
  }
  ObtenerRelacionesPaymentDTLInsertRel(data:any) {
    //return this.api.get('paymentservices/CompaniesConciliation', data);
    return this.api.post('payments/payments/PaymentDTLInsertRel', data);
  }
  EliminarPaymentDTL(data:any) {
    //return this.api.get('paymentservices/CompaniesConciliation', data);
    return this.api.post('payments/payments/PaymentDTL', data);
  }

}
